<div class="row">
  <h4 class="font-helvetica" i18n="@@Cards">Cards</h4>
</div>

<div class="row">
  <div class="col-4 col-sm-8">
    <app-card-details-claim></app-card-details-claim>
  </div>
  <div class="col-4 col-sm-8">
    <div class="card">
      <div class="card-header">
        <span class="font-helvetica subtitle_1" i18n="@@Placeholder">Placeholder</span>
        <button class="btn-direction">
          <i class="ic ic-arrow-down"></i>
        </button>
      </div>
      <div class="card-body" style="height: 200px; background-color: var(--color-disabled)"></div>
    </div>
  </div>
  <div class="col-4 col-sm-8">
    <div class="card" style="width: 452px; height: 211px">
      <div class="card-body flex flex-direction-row">
        <div id="card_section_1" class="card-section flex justify-content-center">
          <img class="img img-circle" width="110" height="110" style="align-self: center" src="https://cdn.pixabay.com/photo/2021/04/05/12/38/man-6153295_1280.jpg" alt="" />
        </div>
        <div id="card_section_1" class="card-section">
          <h4 id="title_section_3" class="font-helvetica" i18n="@@Placeholder">Placeholder</h4>
          <ul id="list_1" class="list">
            <li class="list-item">
              <span class="ic-sm ic-mail-box"></span>
              <span class="text_body_1 font-helvetica" i18n="@@email&#64;email.com.br">email&#64;email.com.br</span>
            </li>
            <li class="list-item">
              <span class="ic-sm ic-telephone"></span>
              <span class="body_1 font-helvetica" i18n="@@+003456145343">+003456145343</span>
            </li>
            <li class="list-item">
              <span class="ic-sm ic-map-point"></span>
              <span class="body_1 font-helvetica" i18n="@@RuaJoseAlvaresMaciel,273">Rua Jose Alvares Maciel,273</span>
            </li>
            <li class="list-item">
              <span class="ic-sm ic-content"></span>
              <span class="body_1 font-helvetica" i18n="@@04/08/1990">04/08/1990</span>
            </li>
          </ul>
        </div>
        <div id="card_section_2" class="card-section flex align-itens-start">
          <span class="ic-md ic-edit"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2 col-sm-8">
    <div class="card">
      <div class="card-header">
        <h5 class="font-helvetica" i18n="@@ClaimStatus-BHP">Claim Status - BHP</h5>
      </div>
      <div class="card-body">
        <ul class="list">
          <li class="list-item">
            <span class="ic-sm ic-bell-yellow-rd"></span>
            <div style="display: inline-grid">
              <span class="overline font-helvetica" i18n="@@Documentmissing:Drivers'license">Document missing:Drivers' license</span>
              <div style="display: flex; align-items: center">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
          <li class="list-item">
            <span class="ic-sm ic-bell-yellow-rd"></span>
            <div style="display: inline-grid">
              <span class="overline font-helvetica" i18n="@@Documentmissing:Drivers'license">Document missing:Drivers' license</span>
              <div style="display: flex; align-items: center">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
          <li class="list-item">
            <span class="ic-sm ic-bell-yellow-rd"></span>
            <div style="display: inline-grid">
              <span class="overline font-helvetica" i18n="@@Documentmissing:Drivers'license">Document missing:Drivers' license</span>
              <div style="display: flex; align-items: center">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
          <li class="list-item">
            <span class="ic-sm ic-alert-red-rd"></span>
            <div style="display: inline-grid">
              <span class="overline font-helvetica" i18n="@@Documentmissing:Drivers'license">Document missing:Drivers' license</span>
              <div style="display: flex">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div class="card">
      <div class="card-header">
        <div>
          <h5 class="font-helvetica" i18n="@@LatestNews">Latest News</h5>
          <h6 i18n="@@BHP">BHP</h6>
        </div>
      </div>
      <div class="card-body">
        <div class="card-section">
          <ul class="list">
            <li class="list-item">
              <p class="half-border-bottom" i18n="@@BraskemseeshighercostsfromAlagoassaltminedamage">
                Braskem sees higher costs from Alagoas salt mine damage
              </p>
              <i class="ic-x-sm ic-arrow-right"></i>
            </li>
            <li class="list-item">
              <p class="half-border-bottom" i18n="@@BayerEssureSettlementaVICTORYFORTHOUSANDS">
                Bayer Essure Settlement a VICTORY FOR THOUSANDS
              </p>
              <i class="ic-x-sm ic-arrow-right"></i>
            </li>
            <li class="list-item">
              <p class="half-border-bottom" i18n="@@AsUberpayscompensation,driversclaimfoulplay">
                As Uber pays compensation, drivers claim foul play
              </p>
              <i class="ic-x-sm ic-arrow-right"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-footer">
        <span class="sub_1" i18n="@@Seeallnews">See all news</span>
        <i class="ic-sm ic-arrow-right"></i>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div class="card">
      <div class="card-header">
        <h5 class="font-helvetica" i18n="@@MyClaims-BHP">My Claims - BHP</h5>
      </div>
      <div class="card-body">
        <div class="card-section">
          <ul class="list">
            <li class="list-item">
              <span class="ic-sm ic-bell-rd"></span>
              <div style="display: inline-grid">
                <span class="overline font-helvetica" i18n="@@Lastupdated:">Last updated:</span>
                <span class="sub_1" i18n="@@12/12/2022">12/12/2022</span>
              </div>
            </li>
            <li class="list-item">
              <span class="ic-sm ic-alert"></span>
              <div style="display: inline-grid">
                <span class="overline font-helvetica" i18n="@@Status:">Status:</span>
                <span class="sub_1" i18n="@@Ongoing">Ongoing</span>
              </div>
            </li>
            <li class="list-item">
              <span class="ic-sm ic-login-user"></span>
              <div style="display: inline-grid">
                <span class="overline font-helvetica" i18n="@@Co-counsel:">Co-counsel:</span>
                <span class="sub_1" i18n="@@AlessandraLoures">Alessandra Loures</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-footer">
        <span class="sub_1" i18n="@@Proceedtoclaim">Proceed to claim</span>
        <i class="ic-sm ic-arrow-right"></i>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div class="card">
      <div class="card-header">
        <p id="title_section_2" class="sub-1" i18n="@@ClaimInformation">Claim Information</p>
      </div>
      <div class="card-body">
        <div class="card-section">
          <div class="title-list">
            <p class="btn" i18n="@@CLAIMCRITERIA">CLAIM CRITERIA</p>
          </div>
          <ul class="list">
            <li class="list-item">
              <i class="ic-x-sm ic-arrow-right"></i>
              <span class="sub_2" i18n="@@PEOPLEWHOLIVEINTHECITYOFMACEIÓ">PEOPLE WHO LIVE IN THE CITY OF MACEIÓ</span>
            </li>
            <li class="list-item">
              <i class="ic-x-sm ic-arrow-right"></i>
              <span class="sub_2" i18n="@@PEOPLEWHOAREOVER18">PEOPLE WHO ARE OVER 18</span>
            </li>
            <li class="list-item">
              <i class="ic-x-sm ic-arrow-right"></i>
              <span class="sub_2" i18n="@@PROPERTYOWNERSINTHEPINHEIROREGION">PROPERTY OWNERS IN THE PINHEIRO REGION</span>
            </li>
          </ul>
        </div>
        <div class="card-section">
          <div class="title-list">
            <p class="btn" i18n="@@DOCUMENTCRITERIA">DOCUMENT CRITERIA</p>
          </div>
          <ul class="list">
            <li class="list-item">
              <i class="ic-x-sm ic-arrow-right"></i>
              <span class="sub_2" i18n="@@Drivers'license">Drivers' license</span>
            </li>
            <li class="list-item">
              <i class="ic-x-sm ic-arrow-right"></i>
              <span class="sub_2" i18n="@@RG">RG</span>
            </li>
            <li class="list-item">
              <i class="ic-x-sm ic-arrow-right"></i>
              <span class="sub_2" i18n="@@PROOFOFRESIDENCE">PROOF OF RESIDENCE</span>
            </li>
          </ul>
        </div>
        <div class="card-section">
          <div class="title-list">
            <p class="btn" i18n="@@HOWMUCHCANYOUEARN:">HOW MUCH CAN YOU EARN:</p>
          </div>
          <h5 id="title_section_2" i18n="@@UPTO£84,000">UP TO £84,000</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2 col-md-4 col-sm-8">
    <div id="card_1" class="card card-no-border">
      <div class="card-body">
        <div class="card-section" style="position: relative">
          <img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" class="img-rectangle-sm" alt="" />
        </div>
        <div class="card-section">
          <!-- <h5 id="title_section_1" class="font-helvetica">Placeholder</h5>
          <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
          <p class="sub-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at
            congue felis. Fusce fermentum, massa a elementum.
          </p> -->
        </div>
      </div>
      <div class="card-footer">
        <button class="btn-md">placeholder</button>
      </div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div id="card_1" class="card card-no-border">
      <div class="card-body">
        <div class="card-section" style="position: relative">
          <img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" class="img-rectangle-sm" alt="" />
          <div class="card-notification-center">
            <i class="ic-sm ic-bell-notification-blue-rd"></i>
            <span id="title_image_1" i18n="@@3newnotifications">3 new notifications</span>
          </div>
        </div>
        <div class="card-section">
          <!-- <h5 id="title_section_1" class="font-helvetica">Placeholder</h5>
          <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
          <p class="sub-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at
            congue felis. Fusce fermentum, massa a elementum.
          </p> -->
        </div>
      </div>
      <div class="card-footer">
        <button class="btn-md">placeholder</button>
      </div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div id="card_1" class="card card-no-border">
      <div class="card-body">
        <div class="card-section" style="position: relative">
          <img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" class="img-rectangle-sm" alt="" />
          <div class="card-notification">
            <i class="ic-sm ic-bell-notification-blue-rd"></i>
            <span id="title_image_1" i18n="@@3newnotifications">3 new notifications</span>
          </div>
        </div>
        <div class="card-section">
          <!-- <h5 id="title_section_1" class="font-helvetica">Placeholder</h5>
          <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
          <p class="sub-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at
            congue felis. Fusce fermentum, massa a elementum.
          </p> -->
        </div>
      </div>
      <div class="card-footer">
        <button class="btn-md">placeholder</button>
      </div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div id="card_1" class="card">
      <div class="card-body">
        <div class="card-section">
          <img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" id="image_card_1" class="img-rectangle-sm" alt="" />
        </div>
        <div class="card-header">
          <div>
            <h5 i18n="@@BHP">BHP</h5>
            <h6 i18n="@@MyClaims">My Claims</h6>
          </div>
        </div>
        <div class="card-section">
          <ul class="list">
            <li class="list-item">
              <i class="ic-sm ic-confirm-rd"></i>
              <p class="overline font-helvetica">
                Adherence to the case in:
                <strong id="p_1" i18n="@@10/10/2020">10/10/2020</strong>
              </p>
            </li>
            <li class="list-item">
              <i class="ic-sm ic-bell-rd"></i>
              <p class="overline font-helvetica">
                Last updated:
                <strong id="p_1" i18n="@@10/10/2020">10/10/2020</strong>
              </p>
            </li>
            <li class="list-item">
              <i class="ic-sm ic-bell-rd"></i>
              <p class="overline font-helvetica">
                Status:
                <strong id="p_1" i18n="@@Ongoing">Ongoing</strong>
              </p>
            </li>
            <li class="list-item">
              <i class="ic-sm ic-bell-rd"></i>
              <p class="overline font-helvetica">
                Co-counsel:
                <strong id="p_1" i18n="@@AlessandraLoures">Alessandra Loures</strong>
              </p>
            </li>
          </ul>
        </div>
        <div class="card-footer">
          <span class="sub_1" i18n="@@ProceedtoClaim">Proceed to Claim</span>
          <i class="ic-sm ic-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>
