<ng-container>
  <mat-form-field id="date" appearance="fill">
    <mat-label>{{ placeholder }}</mat-label>
    <input [formControl]="field" matInput="" [matDatepicker]="picker" />
    <mat-datepicker-toggle matSuffix="" [for]="picker">
      <mat-icon matDatepickerToggleIcon="" i18n="@@newspaper"
        >newspaper</mat-icon
      >
    </mat-datepicker-toggle>
    <mat-datepicker #picker=""></mat-datepicker>
  </mat-form-field>
</ng-container>
