<app-toolbar-search
  [isSidenavOpen]="isToShow"
  [notifications$]="notifications$"
  (toggleSideNavEvent)="toggleSidenav()"
></app-toolbar-search>
<mat-sidenav-container>
  <mat-sidenav #sidenav="" mode="side" [opened]="isToShow">
    <app-button-toggle-menu
      [isSideNavOpen]="isToShow"
      (click)="toggleSidenav()"
    ></app-button-toggle-menu>
    <a
      [routerLink]="['/client/home']"
      routerLinkActive="active"
      (click)="closeSideNav()"
    >
      <img src="assets/icons/home2.svg" class="padded" alt="Dashboard" />
      <span class="animatable-menu-span" >{{'Home' | translate}}</span>
    </a>
    <a
      [routerLink]="['/client/profile']"
      routerLinkActive="active"
      (click)="closeSideNav()"
    >
      <img src="assets/icons/user.svg" alt="Profile" />
      <span class="animatable-menu-span" >{{'Profile' | translate}}</span>
    </a>
    <a
      [routerLink]="['/client/cases']"
      [queryParams]="{ open: false }"
      routerLinkActive="active"
      (click)="closeSideNav()"
    >
      <img src="assets/icons/cases.svg" alt="Cases" />
      <span class="animatable-menu-span" >{{'Cases' | translate}}</span>
    </a>

    <a
      [routerLink]="['/client/cases']"
      routerLinkActive="active"
      [queryParams]="{ open: true }"
      (click)="closeSideNav()"
    >
      <img src="assets/icons/my-claims.svg" alt="Claims" />
      <span class="animatable-menu-span" >{{'MyClaims' | translate}}
</span>
    </a>
    <a
      [routerLink]="['/client/news']"
      routerLinkActive="active"
      (click)="closeSideNav()"
    >
      <img src="assets/icons/news.svg" alt="news" />
      <span class="animatable-menu-span" >{{'News' | translate}}</span>
    </a>

    <a
      [routerLink]="['/client/faq']"
      routerLinkActive="active"
      (click)="closeSideNav()"
    >
      <img src="assets/icons/faq.svg" alt="FAQ" />
      <span class="animatable-menu-span" >{{'FAQ' | translate}}</span>
    </a>

    <button id="exit" class="btn-logout" (click)="logout()">
      <img src="assets/icons/logout.svg" alt="Logout" />
      <span class="animatable-menu-span">{{'Logoff' | translate}}</span>
    </button>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
