import { Injectable } from '@angular/core';
import { CreateAgentDTO, User, UserWithPii } from '../models/user.model';

import { DatabaseService } from './base/database.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { getBrowserFingerprint } from './fingerprint';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pii } from '../models/pii.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends DatabaseService<User> {
  UserAccessList: { [resource: string]: Role[] } = {
    'page/firm': ['ADMINISTRATOR'],
    'page/firm/home': ['ADMINISTRATOR'],
    'page/firm/agents': ['ADMINISTRATOR'],
    'page/firm/agents/:id': ['ADMINISTRATOR'],
    'page/firm/agent-dashboard': ['ADMINISTRATOR', 'AGENT'],
    'page/firm/clients': ['ADMINISTRATOR', ],
    'page/firm/clients/:id': ['ADMINISTRATOR', 'AGENT'],
    'page/firm/clients/:id/profile': ['ADMINISTRATOR',],
    'page/firm/clients/:id/kyc': ['ADMINISTRATOR',],
    'page/firm/thread/:id': ['ADMINISTRATOR'],
    'page/firm/cases': ['ADMINISTRATOR'],
    'page/firm/messages': ['ADMINISTRATOR',],
    'page/firm/news': ['ADMINISTRATOR'],
    'page/firm/news/:news': ['ADMINISTRATOR'],
    'page/firm/faqs': ['ADMINISTRATOR'],
    'page/firm/faqs/new': ['ADMINISTRATOR'],
    'page/firm/faqs/:id': ['ADMINISTRATOR'],
    'page/firm/case/:id': ['ADMINISTRATOR'],
    'page/firm/case/:id/:tab': ['ADMINISTRATOR'],
    'page/firm/case/:id/qb/:qbid': ['ADMINISTRATOR'],
    'page/firm/claim/:id': ['ADMINISTRATOR'],
    'page/firm/cases/new': ['ADMINISTRATOR'],
    'page/firm/profile': ['ADMINISTRATOR',  'CO-COUNSEL'],
    'page/firm/cocounsel/claims': ['ADMINISTRATOR', 'CO-COUNSEL'],
    'page/firm/metabase': ['ADMINISTRATOR'],
  };
  constructor(private router: Router, private http: HttpClient) {
    super('cp_user');
  }

  private cachedUser: User | null = null;

  async getWithPII(id: string) {
    const u = await this.supabase
      .from('cp_user')
      .select('*,pii:cp_pii(*)')
      .eq('id', id);

    const u2 = await this.supabase
      .from('cp_user')
      .select('id, display_name')
      .limit(20);

    console.log('JM user', u);
    console.log('JM user single', u2);
    const ret: any = { ...u.data![0] };

    if (ret.pii && ret.pii[0]) {
      ret.pii = ret.pii[0];
    }

    return ret as unknown as UserWithPii;
  }

  async getUserListByDocumentValue(document_value: string) {
    const u = await this.supabase
      .from('cp_pii')
      .select('*,cp_user:cp_user(*)')
      .ilike('id_document_value', `%${document_value}%`)
      .limit(5);

    if (u.error) {
      return null;
    }

    const list = u.data!.map((e) => {
      const ret: any = { pii: e, ...e.cp_user };
      delete ret.pii.cp_user;

      return ret as unknown as UserWithPii;
    });

    return list as unknown as UserWithPii[];
  }
  async getUserByDocumentValue(document_value: string) {
    const u = await this.supabase
      .from('cp_pii')
      .select('*,cp_user:cp_user(*)')
      .textSearch('search_vector2', document_value);
    if (u.error) {
      return null;
    }
    const ret: any = { pii: u.data![0], ...u.data![0].cp_user };
    delete ret.pii.cp_user;

    return ret as unknown as UserWithPii;
  }

  async searchUsers(searchstring: string): Promise<UserWithPii[] | null> {
    console.log("[user.service.ts] SearchUsers",searchstring);
    const u = await this.supabase
      .from('cp_pii')
      .select('*,cp_user:cp_user(*)')
      .textSearch('search_vector2', searchstring);
    if (u.error) {
      return null;
    }

    const usrs = u.data.map((d) => {
      const ret: any = { pii: d, ...d.cp_user };
      delete ret.cp_user;
      delete ret.pii.cp_user;
      return ret as unknown as UserWithPii;
    });

    return usrs;
  }

  async verifyEmail(email: string, token: string) {
    return this.supabase.auth.verifyOtp({ email, token, type: 'invite' });
  }

  async verifyEmailWithTokenHash(token_hash: string) {
    return this.supabase.auth.verifyOtp({ token_hash, type: 'invite' });
  }

  async signInWithMagicLink(token_hash: string) {
    localStorage.removeItem('userRole');

    const ret = await this.supabase.auth.verifyOtp({
      token_hash,
      type: 'magiclink',
    });

    if (ret.data.session) {
      this.supabase.auth.setSession(ret.data.session);
    } else {
      console.error('Invalid OTP');
    }

    return ret;
  }

  async forceSBSignup(email: string, password: string) {
    localStorage.removeItem('userRole');

    const { data, error } = await this.supabase.auth.signUp({
      email,
      password,
    });

    if (error) throw new Error(error.message);
  }

  async signUp(email: string, password: string, profile: User) {
    localStorage.removeItem('userRole');

    const req = {
      case_id: environment.case_id,
      display_name: profile,
      email: email,
      password: password,
      phone: null,
      affected: true,
      agreetoterms: true,
    };

    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.post(
        environment.n8n_url + '/webhook/cp/eligibility.create',
        { detail: { data: req } },
        {
          headers: {
            apikey: token ?? '',
            Authorization: 'Bearer ' + (token ?? ''),
          },
        }
      )
    )) as any;

    this.router.navigateByUrl('/auth/login');
    return;
  }

  async upsertAgent(createAgent: CreateAgentDTO) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.put(environment.lambdaUrl + '/upsert-agent', createAgent, {
        headers: {
          Authorization: 'Bearer ' + (token ?? ''),
        },
      })
    )) as any;

    return ret;
  }

  async signIn(email: string, password: string) {
    localStorage.removeItem('userRole');
    const signin = await this.supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (signin.data.user) {
      await this.supabase.auth.setSession({
        access_token: signin.data.session?.access_token,
        refresh_token: signin.data.session?.refresh_token,
      });
    }

    const cur = await this.getCurrentUser();
    console.log('[USER SERVICE] [SIGN IN] CUR', cur);
    setTimeout(async () => {
      const cur = await this.getCurrentUser();
      console.log('[USER SERVICE] [SIGN IN] 200 CUR', cur);
    }, 200);

    return signin;
  }

  async signInAgent(email: string, password: string, supervisorAgent: string) {
    localStorage.removeItem('userRole');

    // TODO: implement lambda here
    const signin: any = {};

    if (signin.data.user) {
      await this.supabase.auth.setSession({
        access_token: signin.data.session?.access_token,
        refresh_token: signin.data.session?.refresh_token,
      });
    }

    const cur = await this.getCurrentUser();
    console.log('[USER SERVICE] [SIGN IN] CUR', cur);
    setTimeout(async () => {
      const cur = await this.getCurrentUser();
      console.log('[USER SERVICE] [SIGN IN] 200 CUR', cur);
    }, 200);

    return signin;
  }

  async signOut() {
    const { error } = await this.supabase.auth.signOut();

    if (error) throw new Error(error.message);

    console.log('Signing out');
    this.cachedUser = null;

    this.router.navigate(['/auth/login']);
  }

  async resetPassword(email: string) {
    return await this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${environment.projectUrl}auth/reset?`,
    });
  }

  async getSessionWithPasswordResetToken(token: string) {
    const a = await this.supabase.auth.verifyOtp({
      type: 'recovery',
      token_hash: token,
    });

    if (a.error) {
      throw a.error;
    }
    return a.data;
  }

  async updatePassword(password: string) {
    return await this.supabase.auth.updateUser({ password });
  }

  async updatePii(pii: Partial<Pii>) {
    delete pii.search_vector;
    delete (pii as any).search_vector2;
    const { data, error } = await this.supabase
      .from('cp_pii')
      .update(pii as any)
      .eq('id', pii.id as string)
      .select();

    if (error) throw new Error(error.message);

    return data;
  }

  async sendMagicLinkEmail(email: string) {
    localStorage.removeItem('userRole');

    return await this.supabase.auth.signInWithOtp({ email });
  }

  async getCurrentUser(): Promise<User | null> {
    if (this.cachedUser) {
      return this.cachedUser;
    }

    const au = (await this.supabase.auth.getSession()).data.session?.user;
    console.log('[USER SERVICE] [GET CURRENT USER]', au);
    if (!au || !au.id) return null;

    const { data, error } = await this.supabase
      .from('cp_user')
      .select('*')
      .eq('auth_id', au.id);

    if (error || !data || data.length === 0) {
      return null;
    }

    this.cachedUser = data[0] as unknown as User;
    return this.cachedUser;
  }



  async getCurrentUserRole(): Promise<any | null> {
    const au = await this.supabase.auth.getUser();
    // console.log('AU', au);
    if (!au || !au.data.user || !au.data.user.id) return null;

    const userquery = (
      await this.supabase
        .from('cp_user')
        .select('id, primary_role')
        .eq('auth_id', au.data.user?.id)
    ).data;

    if (!userquery || userquery.length == 0) return null;

    return userquery[0] as unknown as User;
  }

  getFingerprint() {
    return getBrowserFingerprint();
  }

  async tryToLogin(token: string, rt: string) {
    localStorage.removeItem('userRole');

    console.log('Trying to login', token, rt);
    await this.supabase.auth.setSession({
      access_token: token,
      refresh_token: rt,
    });

    await this.supabase.auth.refreshSession({
      refresh_token: rt,
    });

    const usr = await this.supabase.auth.getUser();
    console.log('USR', usr);
    return !!usr.data.user;
  }

  // MFA Methods (Multi-Factor Authentication) Start

  /**
   *
   * @returns A list of available MFA (Multi-Factor Authentication) factors with yours stats
   */
  listMFAEnrolls() {
    return this.supabase.auth.mfa.listFactors();
  }

  /**
   *
   * @returns A configurable factor for MFA (Multi-Factor Authentication), with ID and QR Code
   */
  async initMFAEnroll() {
    console.log('Init MFA Enroll');
    const au = await this.supabase.auth.getUser();
    console.log('AU', au);
    const { data, error } = await this.supabase.auth.mfa.enroll({
      factorType: 'totp',
      issuer: `Client-Portal`,
    });

    if (error) throw new Error(error.message);
    if (!data) throw new Error('No data returned');

    console.log('MFA Enroll Data: ', data);
    return {
      id: data.id,
      qrcode: data.totp.qr_code,
      secret: data.totp.secret,
    };
  }

  /**
   *
   * @param factorId The ID of the factor inited with initMFAEnroll
   * @returns The ID of challenge to use on verify() supabase method
   */
  async challengeMFAEnroll(factorId: string) {
    const { data, error } = await this.supabase.auth.mfa.challenge({
      factorId,
    });

    if (error) throw new Error(error.message);
    if (!data) throw new Error('No data returned');

    return data.id;
  }

  /**
   *
   * @param factorId factorId to be configured
   * @param challengeId challenge of the factorId configuration
   * @param token totp token to be verified
   * @returns true if the token is valid
   */
  async verifyMFAEnroll(factorId: string, challengeId: string, token: string) {
    const { data, error } = await this.supabase.auth.mfa.verify({
      factorId,
      challengeId,
      code: token,
    });

    console.log('Verify MFA Enroll Data: ', data);

    if (error) throw new Error(error.message);

    return true;
  }

  /**
   *
   * @param factorId factorId to be removed
   * @returns true if the factor was removed
   */
  async removeFactorMFAEnroll(factorId: string) {
    const { data, error } = await this.supabase.auth.mfa.unenroll({
      factorId,
    });

    if (error) throw new Error(error.message);

    return true;
  }

  /**
   *
   * @param factor factor to be verified
   * @param token totp token to be verified
   * @returns true if the token is valid
   */
  async verifyMFACode(factor: string, token: string) {
    try {
      const { data, error } = await this.supabase.auth.mfa.challengeAndVerify({
        factorId: factor,
        code: token,
      });

      if (error) throw error;
      if (!data) throw new Error('No data returned');
      if (data) {
        this.supabase.auth.setSession({
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        });
      }

      console.log('Verify Password Reset Token Data: ', data);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  // MFA Methods (Multi-Factor Authentication) End

  // Reset Password Methods Start

  /**
   *
   * @param email Email to send the reset password link
   * @returns A message if the email was sent
   */
  async sendResetPasswordEmail(email: string) {
    const redirecturl = `${environment.projectUrl}auth/reset?email=${email}`;
    console.log('Redirect URL: ', redirecturl);
    return await this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: redirecturl,
    });
  }

  /**
   *
   * @param email Email to send the reset password link
   * @param token Token to verify the email
   * @returns A message if the email was sent
   */
  async verifyPasswordResetToken(email: string, token: string) {
    const { data, error } = await this.supabase.auth.verifyOtp({
      email,
      type: 'email',
      token,
    });

    if (error) throw new Error(error.message);
    if (!data) throw new Error('No data returned');

    console.log('Verify Password Reset Token Data: ', data);
    return data;
  }

  /**
   *
   * @param email Email to send the reset password link
   * @param code Code challenge to verify the email
   * @returns A message if the email was sent
   */
  async verifyPasswordResetCodeChallenger(email: string, code: string) {
    const { data, error } = await this.supabase.auth.verifyOtp({
      email,
      type: 'recovery',
      token: code,
    });

    if (error) throw new Error(error.message);
    if (!data) throw new Error('No data returned');

    console.log('Verify Password Reset Code Challenger Data: ', data);
    return data;
  }
  // Reset Password Methods End

  async updateProfileAndPII(user: User) {}

  async logInWithOTP(email: string, token: string) {
    return await this.supabase.auth.verifyOtp({
      email,
      token,
      type: 'magiclink',
    });
  }

  async hasAccess(resource: string) {
    const role = (await this.getCurrentUser())?.primary_role;

    if (!role) return false;

    if (!this.UserAccessList[resource]) {
      console.error('Resource not registered', resource);
      return false;
    }

    return this.UserAccessList[resource].findIndex((r) => r == role) !== -1;
  }

  async authAgent(
    email: string,
    password: string,
    supervisorEmail: string,
    supervisorPw: string
  ) {
    console.log('[User service] - Auth agent');
    const a = (await firstValueFrom(
      this.http.post(environment.lambdaUrl + '/auth/agent', {
        user_agent: email,
        password_agent: password,
        user_supervisor: supervisorEmail,
        password_supervisor: supervisorPw,
      })
    )) as AgentAuthLambdaRet;

    console.log('[User service] - Auth agent ret', a);

    const r = await this.tryToLogin(a.data.access_token, a.data.refresh_token);

    return r;
  }

  async createUserWithoutClaim(user: Partial<UserWithPii>) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;
    const ret = await firstValueFrom(
      this.http.post(
        environment.lambdaUrl + '/create_user_without_claim',
        {
          display_name: user.display_name,
          email: user.primary_email,
          document_type: user.pii?.id_document_type,
          document_value: user.pii?.id_document_value,
          phone: user.pii?.contacts.phone,
          date_of_birth: user.pii?.date_of_birth,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );

    return ret as {message:string, data:string}
  }
}

// export interface UserAndPII {
//   id uuid not null default gen_random_uuid (),
//   created_at timestamp with time zone null default now(),
//   display_name text null,
//   primary_email text null,
//   language text null,
//   avatar_url text null,
//   best_time_to_call time with time zone null,
//   primary_auth_type text null,
//   primary_auth_value text null,
//   primary_role text null,
//   attributes jsonb null,
//   auth_id uuid null,

//     created_at timestamp with time zone null default now(),
//     date_of_birth date null,
//     legal_name text null,
//     id_document_type text null,
//     id_document_value text null,
//     attributes jsonb null,
//     primary_address jsonb null,
//     contacts jsonb null,
//     documents jsonb null,
//     user_id uuid null,
//     id uuid not null default gen_random_uuid (),

// }

interface AgentAuthLambdaRet {
  data: {
    access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNzQwMTc5NjE4LCJpYXQiOjE3NDAxNzYwMTgsInN1YiI6IjMyZTgyMDZjLTExMmYtNGQ4Zi1hODdkLTUzNTBlYTM0ZDM2ZCIsImVtYWlsIjoibGVhbmRyby5hZ2VudG8xNEBzb21vcy51cyIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnt9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6InBhc3N3b3JkIiwidGltZXN0YW1wIjoxNzQwMTc2MDE4fV0sInNlc3Npb25faWQiOiI0MzYxZTMxMS01NmU2LTRjZTktYjhlYy1jOGMzMzZkYzI2ZTIifQ.xvDc_PztLi3hsttLwHKqkJwZmLFlDKGEef8df93qu8k';
    refresh_token: 'RjHQOIYIT874hxZx54dFHw';
    user: {
      id: '32e8206c-112f-4d8f-a87d-5350ea34d36d';
      aud: 'authenticated';
      role: 'authenticated';
      email: 'leandro.agento14@somos.us';
      email_confirmed_at: '2025-01-28T18:05:43.957264Z';
      phone: '';
      confirmed_at: '2025-01-28T18:05:43.957264Z';
      last_sign_in_at: '2025-02-21T22:13:38.01102329Z';
      app_metadata: { provider: 'email'; providers: ['email'] };
      user_metadata: {};
      identities: [
        {
          id: '32e8206c-112f-4d8f-a87d-5350ea34d36d';
          user_id: '32e8206c-112f-4d8f-a87d-5350ea34d36d';
          identity_data: {
            email: 'leandro.agento14@somos.us';
            sub: '32e8206c-112f-4d8f-a87d-5350ea34d36d';
          };
          provider: 'email';
          last_sign_in_at: '2025-01-28T18:05:43.950609Z';
          created_at: '2025-01-28T18:05:43.950667Z';
          updated_at: '2025-01-28T18:05:43.950667Z';
        }
      ];
      created_at: '2025-01-28T18:05:43.945327Z';
      updated_at: '2025-02-21T22:13:38.014508Z';
    };
  };
}

interface UserAccess {
  role: Role;
}

export type Role = 'CO-COUNSEL' | 'AGENT' | 'ADMINISTRATOR' | 'CLIENT';
