<app-toolbar-search></app-toolbar-search>
<h4 i18n="@@BarLarge">Bar Large</h4>
<div class="row">
  <div class="col-8">
    <div class="bar-notification bar-notification-blue">
      <span class="sub-1"
        >TRADUÇÃO LEGAL. Precisão e agilidade falando a mesma língua.</span
      >
      <button class="btn-outline">PEÇA UM ORÇAMENTO</button>
    </div>
    <div class="bar-notification bar-notification-blue">
      <span class="sub-1"
        >Você está visualizando está pagina como perfil do cliente. Clique para
        retornar à sua conta:</span
      >
      <button class="btn-outline">MEUS CLIENTES</button>
    </div>
    <div class="bar-notification bar-notification-orange">
      <span class="sub-1"
        >TRADUÇÃO LEGAL. Precisão e agilidade falando a mesma língua.</span
      >
      <button class="btn-outline btn-outline-orange">MEUS CLIENTES</button>
    </div>
    <div class="bar-notification bar-notification-orange">
      <span class="sub-1"
        >LEGAL TRANSLATIONS. Accuracy and agility speaking the same
        language.</span
      >
      <button class="btn-outline btn-outline-orange">Ask for prices</button>
    </div>
  </div>
</div>
<h4 i18n="@@Cards">Cards</h4>
<div class="row flex justify-content-center">
  <div class="col-2 col-sm-8">
    <div id="card_1" class="card card-no-border">
      <div class="card-body">
        <div class="card-section" style="position: relative">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg"
            class="img-rectangle-sm"
            alt=""
          />
          <div class="card-notification-center-warning">
            <i class="ic-sm ic-alert-white-rd"></i>
            <span id="title_image_1" i18n="@@COMPLETEYOURREGISTRATION"
              >COMPLETE YOUR REGISTRATION</span
            >
          </div>
        </div>
        <div class="card-section">
          <!-- <h5 id="title_section_1" class="font-helvetica">Placeholder</h5>
          <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
          <p class="sub-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at
            congue felis. Fusce fermentum, massa a elementum.
          </p> -->
        </div>
      </div>
      <div class="card-footer">
        <button class="btn-md">placeholder</button>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div id="card_1" class="card card-no-border">
      <div class="card-body">
        <div class="card-section" style="position: relative">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg"
            class="img-rectangle-sm"
            alt=""
          />
          <div class="card-notification-center-priority-1">
            <i class="ic-sm ic-cancel-white-rd"></i>
            <span id="title_image_1" i18n="@@FINISHEDCLAIM"
              >FINISHED CLAIM</span
            >
          </div>
        </div>
        <div class="card-section">
          <!-- <h5 id="title_section_1" class="font-helvetica">Placeholder</h5>
          <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
          <p class="sub-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at
            congue felis. Fusce fermentum, massa a elementum.
          </p> -->
        </div>
      </div>
      <div class="card-footer">
        <button class="btn-md">placeholder</button>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div id="card_1" class="card card-no-border">
      <div class="card-body">
        <div class="card-section" style="position: relative">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg"
            class="img-rectangle-sm"
            alt=""
          />
          <div class="card-notification">
            <i class="ic-sm ic-bell-notification-white-rd"></i>
            <span id="title_image_1" i18n="@@3newnotifications"
              >3 new notifications</span
            >
          </div>
        </div>
        <div class="card-section">
          <!-- <h5 id="title_section_1" class="font-helvetica">Placeholder</h5>
          <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
          <p class="sub-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at
            congue felis. Fusce fermentum, massa a elementum.
          </p> -->
        </div>
      </div>
      <div class="card-footer">
        <button class="btn-md">placeholder</button>
      </div>
    </div>
  </div>
</div>
<h4 i18n="@@MainTabs">MainTabs</h4>
<div class="row">
  <div class="col-8">
    <mat-tab-group>
      <mat-tab #pending="" label="Pending" i18n="@@Content1">
        Content 1
      </mat-tab>
      <mat-tab #requested="" label="Requested" i18n="@@Content2">
        Content 2
      </mat-tab>
      <mat-tab #granted="" label="Granted" i18n="@@Content2">
        Content 2
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<h4 i18n="@@CardNotification">Card Notification</h4>
<div class="row">
  <div class="col-4">
    <div class="card" style="position: relative">
      <div class="triangle"></div>
      <div class="card-body">
        <div class="card-section">
          <div class="flex justify-content-space-between">
            <h6 i18n="@@Notifications">Notifications</h6>
            <span
              (click)="changeColorAllSectionNotification()"
              class="sub-1"
              i18n="@@ReadAll"
              >Read All</span
            >
          </div>
        </div>
        <div
          id="card_section_notification"
          class="card-section notification-not-read"
        >
          <div class="flex">
            <i class="ic-sm ic-hammer"></i>
            <div class="flex flex-direction-column">
              <span
                >The <strong i18n="@@Braskem">Braskem</strong> claim had an
                update</span
              >
              <span i18n="@@2hoursago">2 hours ago</span>
            </div>
          </div>
        </div>
        <div class="card-section notification-readed">
          <div class="flex">
            <i class="ic-sm ic-hammer"></i>
            <div class="flex flex-direction-column">
              <span
                >The <strong i18n="@@Braskem">Braskem</strong> claim had an
                update</span
              >
              <span i18n="@@2hoursago">2 hours ago</span>
            </div>
          </div>
        </div>
        <div class="card-section notification-readed">
          <div class="flex">
            <i class="ic-sm ic-hammer"></i>
            <div class="flex flex-direction-column">
              <span
                >The <strong i18n="@@Braskem">Braskem</strong> claim had an
                update</span
              >
              <span i18n="@@2hoursago">2 hours ago</span>
            </div>
          </div>
        </div>
        <div class="card-section">
          <div class="flex align-itens-center">
            <span i18n="@@Seeall">See all</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div class="card">
      <div class="card-header">
        <h5 class="font-helvetica" i18n="@@ClaimStatus-BHP">
          Claim Status - BHP
        </h5>
        <i class="ic-sm ic-bell-blue-rd"></i>
      </div>
      <div class="card-body">
        <ul class="list">
          <li class="list-item">
            <span class="ic-sm ic-bell-yellow-rd"></span>
            <div style="display: inline-grid">
              <span
                class="overline font-helvetica"
                i18n="@@Documentmissing:Drivers'license"
                >Document missing:Drivers' license</span
              >
              <div style="display: flex; align-items: center">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
          <li class="list-item">
            <span class="ic-sm ic-bell-yellow-rd"></span>
            <div style="display: inline-grid">
              <span
                class="overline font-helvetica"
                i18n="@@Documentmissing:Drivers'license"
                >Document missing:Drivers' license</span
              >
              <div style="display: flex; align-items: center">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
          <li class="list-item">
            <span class="ic-sm ic-bell-yellow-rd"></span>
            <div style="display: inline-grid">
              <span
                class="overline font-helvetica"
                i18n="@@Documentmissing:Drivers'license"
                >Document missing:Drivers' license</span
              >
              <div style="display: flex; align-items: center">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
          <li class="list-item">
            <span class="ic-sm ic-alert-red-rd"></span>
            <div style="display: inline-grid">
              <span
                class="overline font-helvetica"
                i18n="@@Documentmissing:Drivers'license"
                >Document missing:Drivers' license</span
              >
              <div style="display: flex">
                <span class="sub_1" i18n="@@Upload">Upload</span>
                <i class="ic-x-sm ic-arrow-right"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
