<div class="legal-information-modal">
  <header>
    <app-title-large i18n="@@TermsOfUse">Terms Of Use</app-title-large>
    <mat-icon (click)="close()">close</mat-icon>
  </header>

  <div mat-dialog-content="">
    <article>
      <p i18n="@@Thesetermswerelastupdatedon:23July2024">
        These terms were last updated on: 23 July 2024
      </p>
      <p
        i18n="
          @@PLEASEREADALLOFTHESETERMSCAREFULLYBEFOREUSINGTHISWEBSITEORANYPARTOFTHEPORTALTHATYOUACCESSTOSUBMITINFORMATIONABOUTYOURCLAIMCONCERNINGONECOIN."
      >
        PLEASE READ ALL OF THESE TERMS CAREFULLY BEFORE USING THIS WEBSITE OR
        ANY PART OF THE PORTAL THAT YOU ACCESS TO SUBMIT INFORMATION ABOUT YOUR
        CLAIM CONCERNING ONECOIN.
      </p>
      <p
        i18n="
          @@YouruseofthiswebsiteandtheportalthatitlinkstoandthroughwhichyoumayprovideinformationaboutyourclaimregardingOneCoin(togethertheWebsite)isgovernedbythesetermsandanydisclaimernoticesonthiswebsite(collectivelytheTerms).ByusingtheWebsiteyouaccepttheTermsandagreetocomplywiththemfully."
      >
        Your use of this website and the portal that it links to and through
        which you may provide information about your claim regarding OneCoin
        (together the Website) is governed by these terms and any disclaimer
        notices on this website (collectively the Terms). By using the Website
        you accept the Terms and agree to comply with them fully.
      </p>
      <p i18n="@@IfyoudonotagreetotheTermsyoumustnotusetheWebsite.">
        If you do not agree to the Terms you must not use the Website.
      </p>

      <h2 i18n="@@1.Whoweareandhowtocontactus">
        1. Who we are and how to contact us
      </h2>
      <p
        i18n="
          @@WeareMishcondeReyaLLP(Mishcon)alimitedliabilitypartnershipincorporatedinEngland(numberOC399969)whoseregisteredofficeisatAfricaHouse70KingswayLondonWC2B6AHEngland.ItisauthorisedandregulatedbytheSolicitorsRegulationAuthorityunderSRAnumber624547."
      >
        We are Mishcon de Reya LLP (Mishcon) a limited liability partnership
        incorporated in England (number OC399969) whose registered office is at
        Africa House 70 Kingsway London WC2B 6AH England. It is authorised and
        regulated by the Solicitors Regulation Authority under SRA number
        624547.
      </p>
      <p
        i18n="
          @@Tocontactuswithanyquestionsorconcernspleaseemailusatonevictims@mishcon.com."
      >
        To contact us with any questions or concerns please email us at
        onevictims@mishcon.com.
      </p>

      <h2 i18n="@@2.Yourpersonalinformation">2. Your personal information</h2>
      <p>
        Your privacy and personal information are important to us. We will only
        use your personal information as set out in our
        <a
          href="javascript:void(0)"
          (click)="openPrivacy()"
          i18n="@@PrivacyNotice"
          >Privacy Notice</a
        >
        and Cookies Notice. These explain what personal information we collect
        from you how and why we collect store use and share such information
        your rights in relation to your personal information and how to contact
        us and supervisory authorities if you have a query or complaint about
        the use of your personal information.
      </p>

      <h2 i18n="@@3.Otherapplicableterms">3. Other applicable terms</h2>
      <p
        i18n="
          @@IfyoudecidetoproceedwithjoiningthegroupactionthatisbeinglaunchedonbehalfofOneCoininvestorswhosufferedlossesasaresultofwrongdoingassociatedwiththeirinvestment(theGroupAction)then(subjecttoyouhavingfirstsuccessfullycompletedourclientidentificationandothercomplianceprocedures)wewillsendtoyouthefollowingwhichyouwillberequiredtoacceptinordertoparticipateintheGroupAction:"
      >
        If you decide to proceed with joining the group action that is being
        launched on behalf of OneCoin investors who suffered losses as a result
        of wrongdoing associated with their investment (the Group Action) then
        (subject to you having first successfully completed our client
        identification and other compliance procedures) we will send to you the
        following which you will be required to accept in order to participate
        in the Group Action:
      </p>
      <ul>
        <li
          i18n="
            @@Ourretainerletterwhichwillbemadeavailabletoyouforreviewandsigningviatheonlineportal."
        >
          Our retainer letter which will be made available to you for review and
          signing via the online portal.
        </li>
        <li
          i18n="
            @@Ourtermsofbusinesswhichwillbemadeavailabletoyouforreviewviatheonlineportal."
        >
          Our terms of business which will be made available to you for review
          via the online portal.
        </li>
        <li
          i18n="
            @@TheSteeringCommitteeAgreementwhichwillbemadeavailabletoyouforreviewsothatyoucanconfirm(inordertosignuptotheGroupAction)thatyouagreetobeboundbyitstermsasthisbasisonwhichtheSteeringCommittee(asdefinedbelow)willrepresentyou."
        >
          The Steering Committee Agreement which will be made available to you
          for review so that you can confirm (in order to sign up to the Group
          Action) that you agree to be bound by its terms as this basis on which
          the Steering Committee (as defined below) will represent you.
        </li>
        <li
          i18n="
            @@TheLitigationFundingAgreementwhichwillbemadeavailabletoyouforreviewsothatyoucanconfirm(inordertosignuptotheGroupAction)thatyouagreetobeboundbyitsterms.ASummarytotheLitigationFundingAgreementwillalsobeprovided."
        >
          The Litigation Funding Agreement which will be made available to you
          for review so that you can confirm (in order to sign up to the Group
          Action) that you agree to be bound by its terms. A Summary to the
          Litigation Funding Agreement will also be provided.
        </li>
        <li
          i18n="
            @@OurClientGuidetoLitigationProcedurewhichwillbemadeavailabletoyouforreviewviathePortalandexplainsthecorestagesoflitigationandsetsoutyourobligationsandliabilitiestotheCourtandtotheotherside."
        >
          Our Client Guide to Litigation Procedure which will be made available
          to you for review via the Portal and explains the core stages of
          litigation and sets out your obligations and liabilities to the Court
          and to the other side.
        </li>
      </ul>
      <p
        i18n="
          @@Youmusttakeyourtimeandreadthesedocumentscarefullybeforesigninguptothem."
      >
        You must take your time and read these documents carefully before
        signing up to them.
      </p>

      <h2 i18n="@@4.WemaymakechangestotheseTerms">
        4. We may make changes to these Terms
      </h2>
      <p
        i18n="
          @@WemayupdateandchangetheseTermsfromtimetotimebyamendingthispage.Wewilltrytogiveyoureasonablenoticeofanymajorchanges.PleasealsonotethatforthedurationofthismatterwewillmainlybecommunicatingwithmembersoftheOneCoinClaim(theGroup)viatheonlineportalmentionedabove."
      >
        We may update and change these Terms from time to time by amending this
        page. We will try to give you reasonable notice of any major changes.
        Please also note that for the duration of this matter we will mainly be
        communicating with members of the OneCoin Claim (the Group) via the
        online portal mentioned above.
      </p>

      <h2 i18n="@@5.HowyoumayusetheWebsite">5. How you may use the Website</h2>
      <p
        i18n="
          @@WeorourlicensorsownallintellectualpropertyrightsintheWebsiteandinthematerialpublishedonit.Thoseworksareprotectedbycopyrightlawsandtreatiesaroundtheworld.Allsuchrightsarereserved."
      >
        We or our licensors own all intellectual property rights in the Website
        and in the material published on it. Those works are protected by
        copyright laws and treaties around the world. All such rights are
        reserved.
      </p>
      <p
        i18n="
          @@YouconfirmandpromisethatanyinformationyouprovidetousviathewebsitethePortalorotherwiseisrealandaccurateandnotfalseormisleading."
      >
        You confirm and promise that any information you provide to us via the
        website the Portal or otherwise is real and accurate and not false or
        misleading.
      </p>
      <p
        i18n="
          @@YoumayonlyaccessanddownloadthecontentsofthepagesonourwebsiteinaccordancewiththeTermsandforlawfulpurposes."
      >
        You may only access and download the contents of the pages on our
        website in accordance with the Terms and for lawful purposes.
      </p>
      <p
        i18n="
          @@Youmaynotusethewebsiteinanywaythatisunlawfulorfraudulentorbreachesanyapplicablelawsorregulation."
      >
        You may not use the website in any way that is unlawful or fraudulent or
        breaches any applicable laws or regulation.
      </p>
      <p
        i18n="
          @@Youmustnotusethiswebsitetotransmitorprocurethesendingofanyunsolicitedorunauthorisedadvertisingorpromotionalmaterialoranyotherformofsimilarsolicitation."
      >
        You must not use this website to transmit or procure the sending of any
        unsolicited or unauthorised advertising or promotional material or any
        other form of similar solicitation.
      </p>
      <p
        i18n="
          @@Youmustnotknowinglytransmitanydatasendoruploadanymaterialthatcontainsvirusesoranyotherharmfulprogramsorsimilarcomputercodedesignedtoadverselyaffecttheoperationofanycomputersoftwareorhardware."
      >
        You must not knowingly transmit any data send or upload any material
        that contains viruses or any other harmful programs or similar computer
        code designed to adversely affect the operation of any computer software
        or hardware.
      </p>
      <p
        i18n="
          @@AccesstotheWebsiteispermittedonatemporarybasisandforthesolepurposeofviewinginformation.Youmaynotpermanentlycopyorreproduceanypartofthecontentsofthepagesonourwebsiteswithoutourpriorwrittenpermission."
      >
        Access to the Website is permitted on a temporary basis and for the sole
        purpose of viewing information. You may not permanently copy or
        reproduce any part of the contents of the pages on our websites without
        our prior written permission.
      </p>
      <p
        i18n="
          @@Youmustnotuseanypartofthecontentofourwebsiteforcommercialpurposeswithoutobtainingalicencetodosofromusorourlicensors."
      >
        You must not use any part of the content of our website for commercial
        purposes without obtaining a licence to do so from us or our licensors.
      </p>
      <p
        i18n="
          @@Inadditionifyouuploadanycontentthroughourportal(whetherviadocumentsorotherdata)inconnectionwithaclaimagainstOneCoin(YourContent)youpromisethat(andmustensurethat)YourContentisaccurateinallrespectsthatitwillnot:"
      >
        In addition if you upload any content through our portal (whether via
        documents or other data) in connection with a claim against OneCoin
        (Your Content) you promise that (and must ensure that) Your Content is
        accurate in all respects that it will not:
      </p>
      <ul>
        <li i18n="@@bedefamatoryofanypersonorobsceneorinflammatory;">
          be defamatory of any person or obscene or inflammatory;
        </li>
        <li
          i18n="
            @@promotesexuallyexplicitmaterialorviolenceorincludechildsexualabusematerial;"
        >
          promote sexually explicit material or violence or include child sexual
          abuse material;
        </li>
        <li
          i18n="
            @@promotediscriminationbasedonraceethnicoriginpoliticalbeliefssexreligionnationalitydisabilitysexualorientationgenderorgenderidentitymaritalstatusorage;"
        >
          promote discrimination based on race ethnic origin political beliefs
          sex religion nationality disability sexual orientation gender or
          gender identity marital status or age;
        </li>
        <li
          i18n="
            @@infringeanyoneelse’srights(includingtheirprivacyandintellectualpropertyrights);"
        >
          infringe anyone else’s rights (including their privacy and
          intellectual property rights);
        </li>
        <li
          i18n="
            @@belikelytodeceiveanypersonorgivetheimpressionthatitemanatesfromMishconifthisisnotthecase;"
        >
          be likely to deceive any person or give the impression that it
          emanates from Mishcon if this is not the case;
        </li>
        <li
          i18n="
            @@breakanylegaldutythatyouowetosomeoneelseorbeincontemptofcourt;"
        >
          break any legal duty that you owe to someone else or be in contempt of
          court;
        </li>
        <li i18n="@@promoteanyillegalcontentoractivity;or">
          promote any illegal content or activity; or
        </li>
        <li
          i18n="
            @@impersonateanypersonormisrepresentyouridentityoraffiliationwithanyperson."
        >
          impersonate any person or misrepresent your identity or affiliation
          with any person.
        </li>
      </ul>
      <p
        i18n="
          @@BysubmittingYourContentYougivetousallnecessaryrightspermissionsandlicencestouseYourContentinordertoprovidetheservicescontemplatedbytheseTermsandtheWebsiteforaslongasweneedthemtodosoandyouwarrantthatyouareentitledtosharewithusYourContentforthosepurposes."
      >
        By submitting Your Content You give to us all necessary rights
        permissions and licences to use Your Content in order to provide the
        services contemplated by these Terms and the Website for as long as we
        need them to do so and you warrant that you are entitled to share with
        us Your Content for those purposes.
      </p>
      <p
        i18n="
          @@IntheeventofanybreachoftheseTermsyourrighttousetheWebsitewillceaseimmediately.WeexcludeliabilityforactionstakeninresponsetobreachesoftheTermsandwemaytakeanyactionwereasonablydeemtobeappropriate.WhereuseraccesstorestrictedpartsoftheWebsiteissubjecttomorespecifictermsandconditionsthoseadditionalspecifictermsandconditionswillsupplementtheseTerms."
      >
        In the event of any breach of these Terms your right to use the Website
        will cease immediately. We exclude liability for actions taken in
        response to breaches of the Terms and we may take any action we
        reasonably deem to be appropriate. Where user access to restricted parts
        of the Website is subject to more specific terms and conditions those
        additional specific terms and conditions will supplement these Terms.
      </p>

      <h2 i18n="@@6.Verificationofidentity">6. Verification of identity</h2>
      <p
        i18n="
          @@Thelawrequiresthatweobtainsatisfactoryevidenceoftheidentityofourclientsandsometimespeoplerelatedtothem."
      >
        The law requires that we obtain satisfactory evidence of the identity of
        our clients and sometimes people related to them.
      </p>
      <p
        i18n="
          @@Tosimplifytheprocesswehaveengagedathird-partyproviderThirdfortwhowillenableyoutoeasilyuploadyouridentitydocumentselectronicallyviatheonlineportal."
      >
        To simplify the process we have engaged a third-party provider Thirdfort
        who will enable you to easily upload your identity documents
        electronically via the online portal.
      </p>

      <h2 i18n="@@7.Disclaimer">7. Disclaimer</h2>
      <p
        i18n="
          @@TheinformationontheWebsiteisprovidedonan&quot;asis&quot;basisandforgeneralinformationalpurposesonly.AlthoughwemakereasonableeffortstoupdatetheinformationontheWebsitewemakenorepresentationswarrantiesorguaranteesexpressorimpliedthatthecontentonthisWebsiteisaccuratecompleteoruptodateandyoushouldalwaysseekspecificlegaladviceinrelationtoanydecisionorcourseofaction."
      >
        The information on the Website is provided on an "as is" basis and for
        general informational purposes only. Although we make reasonable efforts
        to update the information on the Website we make no representations
        warranties or guarantees express or implied that the content on this
        Website is accurate complete or up to date and you should always seek
        specific legal advice in relation to any decision or course of action.
      </p>
      <p
        i18n="
          @@NothingonthisWebsiteconstitutesorisintendedtoconstitutelegalorotherprofessionaladviceanagreementorcommitmentofanykind.Aclient-solicitorrelationshipwillonlybeestablishedbetweenyouandMishconifyouacceptandenterintothetermssetoutinourengagementletterandtermsofbusiness."
      >
        Nothing on this Website constitutes or is intended to constitute legal
        or other professional advice an agreement or commitment of any kind. A
        client-solicitor relationship will only be established between you and
        Mishcon if you accept and enter into the terms set out in our engagement
        letter and terms of business.
      </p>
      <p
        i18n="
          @@Ourinstructioninthismatterissubjecttoconfirmationofyouridentityreceiptofanti-moneylaunderingverificationdocumentscompletionofconflictcomplianceandother'knowyourcustomer'(KYC)checksandreceiptofsignedcopiesofthedocumentssetoutinclause2above.UntilsuchtimeyouwillnotbeconsideredamemberoftheGroupandwehavenoobligationtoactonyourbehalf."
      >
        Our instruction in this matter is subject to confirmation of your
        identity receipt of anti-money laundering verification documents
        completion of conflict compliance and other 'know your customer' (KYC)
        checks and receipt of signed copies of the documents set out in clause 2
        above. Until such time you will not be considered a member of the Group
        and we have no obligation to act on your behalf.
      </p>

      <h2 i18n="@@8.SteeringCommittee">8. Steering Committee</h2>
      <p
        i18n="
          @@YouarenotsigninguptoanylegalproceedingsorcommittingtojoiningtheGroupatthisstage.Iffollowingreceiptoffurtherinformationyoudecideyoudonotwanttojointheactionyoucanelectnottoproceedatthatpointbeforeacceptingthetermssetoutinourengagementletterandtermsofbusiness."
      >
        You are not signing up to any legal proceedings or committing to joining
        the Group at this stage. If following receipt of further information you
        decide you do not want to join the action you can elect not to proceed
        at that point before accepting the terms set out in our engagement
        letter and terms of business.
      </p>
      <p
        i18n="
          @@IfatalaterstageyoubecomeamemberoftheGroupintheclaimagainstDrRujaIgnatovaandothersarisingoutofthefraudulentcryptocurrencyschemeknownasOneCoin(theDispute)youunderstandandacceptthatwewillinthosecircumstancesbeactingonbehalfofyouandothermembersoftheGroup.EachmemberoftheGroupisaninvestorinOneCoinandwouldhaveinstructedustorepresenttheminthisDisputesubjecttosigninguptheadditionaldocumentsmentionedintheseTerms."
      >
        If at a later stage you become a member of the Group in the claim
        against Dr Ruja Ignatova and others arising out of the fraudulent
        cryptocurrency scheme known as OneCoin (the Dispute) you understand and
        accept that we will in those circumstances be acting on behalf of you
        and other members of the Group. Each member of the Group is an investor
        in OneCoin and would have instructed us to represent them in this
        Dispute subject to signing up the additional documents mentioned in
        these Terms.
      </p>
      <p
        i18n="
          @@WewillbetakinginstructionsinrelationtotheDisputefromasteeringcommitteewhichwillbemadeupofbetweenfourandsevenmembersoftheGroup(theSteeringCommittee).PleasenotethatthebasisonwhichweactforyouwillbeasacollectivememberoftheGroup.OurinstructionwillneverextendtoactingforyouseparatelyfromtheGroupandyouwillnotbeentitledtoprovideuswithinstructionsorreceiveadviceotherthanthroughtheSteeringCommittee."
      >
        We will be taking instructions in relation to the Dispute from a
        steering committee which will be made up of between four and seven
        members of the Group (the Steering Committee). Please note that the
        basis on which we act for you will be as a collective member of the
        Group. Our instruction will never extend to acting for you separately
        from the Group and you will not be entitled to provide us with
        instructions or receive advice other than through the Steering
        Committee.
      </p>
      <p
        i18n="
          @@YouwillsubsequentlyreceiveandmustsignuptotheSteeringCommitteeAgreementwhichwillbebindingbetweenyouandallothermembers.YoushouldreviewthetermsoftheSteeringCommitteeAgreementcarefullypriortosigningasonceyouhavesigneduptoititwillauthorisetheSteeringCommitteetoactonyourbehalfinrespectofallmatterssetouttherein."
      >
        You will subsequently receive and must sign up to the Steering Committee
        Agreement which will be binding between you and all other members. You
        should review the terms of the Steering Committee Agreement carefully
        prior to signing as once you have signed up to it it will authorise the
        Steering Committee to act on your behalf in respect of all matters set
        out therein.
      </p>
      <p
        i18n="
          @@PleasenotethatifyoudobecomeamemberoftheGroupinthisDisputewhileweendeavourtoprovidinghigh-qualitylegalserviceandclientcarewecannotguaranteeanyspecificoutcomeorsuccessoftheclaim."
      >
        Please note that if you do become a member of the Group in this Dispute
        while we endeavour to providing high-quality legal service and client
        care we cannot guarantee any specific outcome or success of the claim.
      </p>

      <h2 i18n="@@9.Governinglaw">9. Governing law</h2>
      <p
        i18n="
          @@ThematerialonthiswebsiteresidesonaserverintheUnitedKingdom.TheTermsandoperationofthiswebsite(includingtheaccessingofanymaterialonthewebsite)andanydisputeorclaimarisingoutoforinconnectionwiththemortheirsubjectmatterorformation(includingnon-contractualdisputesorclaims)andsuchoperationshallbegovernedbyandconstruedinaccordancewithEnglishlawandbesubjecttotheexclusivejurisdictionofthecourtsofLondonEngland."
      >
        The material on this website resides on a server in the United Kingdom.
        The Terms and operation of this website (including the accessing of any
        material on the website) and any dispute or claim arising out of or in
        connection with them or their subject matter or formation (including
        non-contractual disputes or claims) and such operation shall be governed
        by and construed in accordance with English law and be subject to the
        exclusive jurisdiction of the courts of London England.
      </p>

      <h2 i18n="@@10.Limitationsandexclusions">
        10. Limitations and exclusions
      </h2>
      <p
        i18n="
          @@Totheextentpermittedbylawwearenotliableforanylossordamagewhetherdirectindirectorconsequentialarisingincontracttortorotherwisearisingunderorinconnectionwiththeuseoforinabilitytousethewebsiteoranymaterialcontainedinituseoforrelianceonanycontentdisplayedonthewebsiteorfromanyactionordecisiontakenornottakenasaresultofusingthewebsite."
      >
        To the extent permitted by law we are not liable for any loss or damage
        whether direct indirect or consequential arising in contract tort or
        otherwise arising under or in connection with the use of or inability to
        use the website or any material contained in it use of or reliance on
        any content displayed on the website or from any action or decision
        taken or not taken as a result of using the website.
      </p>
      <p
        i18n="
          @@Notwithstandingtheabovewedonotexcludeinanywayourliabilitytoyouwhereitwouldbeunlawfultodoso.Thisincludesliabilityfordeathorpersonalinjurycausedbyournegligenceorthenegligenceofouremployeesagentsorsubcontractorsandforfraudorfraudulentmisrepresentation."
      >
        Notwithstanding the above we do not exclude in any way our liability to
        you where it would be unlawful to do so. This includes liability for
        death or personal injury caused by our negligence or the negligence of
        our employees agents or subcontractors and for fraud or fraudulent
        misrepresentation.
      </p>

      <h2 i18n="@@11.Notextordataminingorwebscraping">
        11. No text or data mining or web scraping
      </h2>
      <p
        i18n="
          @@Youmustnotconductfacilitateauthoriseorpermitanytextordataminingorwebscrapinginrelationtothiswebsiteoranyservicesprovidedviaorinrelationtothiswebsite.Thisincludesusing(orpermittingauthorisingorattemptingtheuseof):"
      >
        You must not conduct facilitate authorise or permit any text or data
        mining or web scraping in relation to this website or any services
        provided via or in relation to this website. This includes using (or
        permitting authorising or attempting the use of):
      </p>
      <ul>
        <li
          i18n="
            @@Any&quot;robot&quot;&quot;bot&quot;&quot;spider&quot;&quot;scraper&quot;orotherautomateddeviceprogramtoolalgorithmcodeprocessormethodologytoaccessobtaincopymonitororrepublishanyportionofoursiteoranydatacontentinformationorservicesaccessedviathesame."
        >
          Any "robot" "bot" "spider" "scraper" or other automated device program
          tool algorithm code process or methodology to access obtain copy
          monitor or republish any portion of our site or any data content
          information or services accessed via the same.
        </li>
        <li
          i18n="
            @@Anyautomatedanalyticaltechniqueaimedatanalysingtextanddataindigitalformtogenerateinformationwhichincludesbutisnotlimitedtopatternstrendsandcorrelations."
        >
          Any automated analytical technique aimed at analysing text and data in
          digital form to generate information which includes but is not limited
          to patterns trends and correlations.
        </li>
      </ul>
      <p
        i18n="
          @@TheprovisionsofthisparagraphshouldbetreatedasanexpressreservationofourrightsinthisregardincludingforthepurposesofArticle4(3)oftheDigitalCopyrightDirective((EU)2019/790)."
      >
        The provisions of this paragraph should be treated as an express
        reservation of our rights in this regard including for the purposes of
        Article 4(3) of the Digital Copyright Directive ((EU) 2019/790).
      </p>
      <p
        i18n="
          @@Thisclausewillnotapplyinsofaras(butonlytotheextentthat)weareunabletoexcludeorlimittextordataminingorwebscrapingactivitybycontractunderthelawswhichareapplicabletous."
      >
        This clause will not apply insofar as (but only to the extent that) we
        are unable to exclude or limit text or data mining or web scraping
        activity by contract under the laws which are applicable to us.
      </p>

      <h2 i18n="@@12.Rulesaboutlinkingtothiswebsite">
        12. Rules about linking to this website
      </h2>
      <p
        i18n="
          @@Wedonotconsentto&quot;deeplinks&quot;intooursite.Wereservetherighttorefuseapplicationstolinktooursitefrompeopleororganisationsthatweconsiderunsuitable.Wealsoreservetherighttowithdrawlinkingpermissionwithoutnotice."
      >
        We do not consent to "deep links" into our site. We reserve the right to
        refuse applications to link to our site from people or organisations
        that we consider unsuitable. We also reserve the right to withdraw
        linking permission without notice.
      </p>
      <p
        i18n="
          @@Forfurtherinformationortorequestpermissiontolinktooursitepleasecontactus."
      >
        For further information or to request permission to link to our site
        please contact us.
      </p>

      <h2 i18n="@@13.Wearenotresponsibleforvirusesorforwebsitesthatwelinkto">
        13. We are not responsible for viruses or for websites that we link to
      </h2>
      <p
        i18n="
          @@Wedonotguaranteethatourwebsitewillbesecureorfreefrombugsorviruses.Youareresponsibleforconfiguringyourinformationtechnologycomputerprogramsandplatformtoaccessourwebsite.Youshoulduseyourownvirusprotectionsoftware."
      >
        We do not guarantee that our website will be secure or free from bugs or
        viruses. You are responsible for configuring your information technology
        computer programs and platform to access our website. You should use
        your own virus protection software.
      </p>
      <p
        i18n="
          @@Wherethiswebsitecontainslinkstoorotherwiseframesothersitesandresourcesprovidedbythirdparties(suchasThirdFort)theselinksareprovidedforyourinformationonly.Suchlinksorframingshouldnotbeinterpretedasapprovalbyusofthoselinkedorframedwebsitesorinformationyoumayobtainfromthem.Wehavenocontroloverthecontentofthesethird-partysitesorresources."
      >
        Where this website contains links to or otherwise frames other sites and
        resources provided by third parties (such as ThirdFort) these links are
        provided for your information only. Such links or framing should not be
        interpreted as approval by us of those linked or framed websites or
        information you may obtain from them. We have no control over the
        content of these third-party sites or resources.
      </p>

      <h2 i18n="@@14.Sitecredits">14. Site credits</h2>
      <p
        i18n="
          @@Wearecommittedtomakingtheinformationandresourcesprovidedviaitswebsiteaccessibletothewidestpossibleaudience.Toenablethisweendeavourtofollowwidelyaccepted'bestpractice'principlesfordesignandusability."
      >
        We are committed to making the information and resources provided via
        its website accessible to the widest possible audience. To enable this
        we endeavour to follow widely accepted 'best practice' principles for
        design and usability.
      </p>
      <p
        i18n="
          @@Thiswebsitehasbeendesignedtobeasuseableandaccessibleaspossibleacrossawidevarietyofuser-agents.ThishasbeendonebyusingHTML5(HypertextMarkupLanguage)andCSS3(CascadingStyleSheets)."
      >
        This website has been designed to be as useable and accessible as
        possible across a wide variety of user-agents. This has been done by
        using HTML5 (Hypertext Markup Language) and CSS3 (Cascading Style
        Sheets).
      </p>
      <p
        i18n="
          @@FormoreinformationontheimportanceofWebStandardspleasevisittheWebStandardsProject."
      >
        For more information on the importance of Web Standards please visit the
        Web Standards Project.
      </p>
    </article>
  </div>
  <div mat-dialog-actions="">
    <button class="btn-close" (click)="close()">Close</button>
  </div>
</div>
