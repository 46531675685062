<form class="edit-profile">
  <div class="img-content">
    <div class="profile-avatar">
      <span class="profile-name" *ngIf="!profileImg">
        {{ profileName }}
      </span>
      <img
        *ngIf="profileImg"
        class="img-circle"
        [src]="profileImg"
        alt="Profile"
      />
    </div>
    <div class="icon-change" (click)="changeProfileImg()">
      <img src="assets/icons/camera.svg" alt="Edit image" />
    </div>
  </div>
  <div class="form-container" *ngIf="this.pii && this.pii.attributes">
    <app-title-large>{{ "BasicInformation" | translate }}</app-title-large>

    <section class="form-group basic-infos">
      <app-shared-form-input-text
        [(ngModel)]="pii.attributes.first_name"
        id="first_name"
        name="first_name"
        label="{{ 'EditProfileFirstName' | translate }}"
      ></app-shared-form-input-text>
      <app-shared-form-input-text
        [(ngModel)]="pii.attributes.middle_name"
        id="middle_name"
        name="middle_name"
        label="{{ 'EditProfileMiddleName' | translate }}"
      ></app-shared-form-input-text>
      <app-shared-form-input-text
        [(ngModel)]="pii.attributes.last_name"
        id="last_name"
        name="last_name"
        label="{{ 'EditProfileLastName' | translate }}"
      ></app-shared-form-input-text>
      <div class="form-field datepicker">
        <label for="birthdate">{{ "Dateofbirth" | translate }}</label>
        <input
          [(ngModel)]="pii.date_of_birth"
          id="birthdate"
          name="date"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix="" [for]="picker">
          <mat-icon matDatepickerToggleIcon="">calendar_month</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker=""></mat-datepicker>
      </div>
      <div class="form-field phone">
        <label for="number">{{ "Phone" | translate }}</label>
        <ngx-mat-intl-tel-input
          id="phoneinput"
          [preferredCountries]="preferredCountries"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          name="number"
          describedBy="phoneInput"
          [(ngModel)]="pii.contacts.phone"
          (ngModelChange)="changePhone($event)"
        ></ngx-mat-intl-tel-input>
        <mat-error *ngIf="!pii.contacts.phone">{{
          "InvalidNumber" | translate
        }}</mat-error>
      </div>

      <app-shared-form-input-text
        [(ngModel)]="pii.contacts.email"
        id="email"
        name="email"
        i18n-label="@@EditProfileEmail"
        label="Email"
        [readonly]="true"
      ></app-shared-form-input-text>

      <app-input-select
        [(ngModel)]="language"
        id="language"
        name="language"
        i18n-label="@@EditProfileEmail"
        label="{{'Language' | translate}}"
        (ngModelChange)="changeLang()"
        [options]="languageOptions"
      ></app-input-select>
    </section>

    <section>
      <app-title-large>{{ "Address" | translate }}</app-title-large>
      <div class="form-group address">
        <!-- If the address is in the address line 1 format -->
        <ng-container *ngIf="pii.primary_address.address_line_1">
          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.address_line_1"
            id="al1"
            name="al1"
            label="{{ 'EditProfileAddressLine1' | translate }}"
          ></app-shared-form-input-text>
          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.city"
            id="city"
            name="city"
            label="{{ 'EditProfileCity' | translate }}"
          ></app-shared-form-input-text>
          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.postcode"
            id="postcode"
            name="postcode"
            label="{{ 'EditProfilePostcode' | translate }}"
          ></app-shared-form-input-text>
          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.country"
            id="country"
            name="country"
            i18n-label="@@EditProfileCountry"
            label="Country"
          ></app-shared-form-input-text>
        </ng-container>

        <!-- If the address is NOT in the address line 1 format -->
        <ng-container *ngIf="!pii.primary_address.address_line_1">
          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.streetNumber"
            id="streetNumber"
            name="streetNumber"
            label="{{ 'EditProfileStreetNumber' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.streetName"
            id="streetName"
            name="streetName"
            label="{{ 'EditProfileStreetName' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.city"
            id="city"
            name="city"
            label="{{ 'EditProfileCity' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.neighborhood"
            id="neighborhood"
            name="neighborhood"
            label="{{ 'EditProfileNeighborhood' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.state"
            id="state"
            name="state"
            label="{{ 'EditProfileState' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
          *ngIf="!(pii.primary_address.country == 'Brazil') "
            [(ngModel)]="pii.primary_address.county"
            id="county"
            name="county"
            label="{{ 'EditProfileCounty' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.country"
            id="country"
            name="country"
            label="{{ 'EditProfileCountry' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.postalCode"
            id="postalCode"
            name="postalCode"
            label="{{ 'EditProfilePostalCode' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.buildingName"
            id="buildingName"
            name="buildingName"
            label="{{ 'EditProfileBuildingName' | translate }}"
          ></app-shared-form-input-text>

          <app-shared-form-input-text
            [(ngModel)]="pii.primary_address.unitNumber"
            id="unitNumber"
            name="unitNumber"
            label="{{ 'EditProfileUnitNumber' | translate }}"
          ></app-shared-form-input-text>
        </ng-container>
      </div>
    </section>

    <div class="submit-actions">
      <button type="button" class="act-btn cancel">
        {{ "Cancel" | translate }}
      </button>
      <button type="submit" class="act-btn save" (click)="handleSubmit()">
        {{ "Save" | translate }}
      </button>
    </div>
  </div>
</form>
