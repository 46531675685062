<div class="container">
  <h1 class="title" >{{'Messages' | translate}}</h1>
  <div class="content">
    <div class="default-contacts">
      <h3 class="subtitle" >
        {{'Chooseoneofthecontactmethodstosendamessage' | translate}}
      </h3>
      <div class="options" *ngIf="email || phone">
        <div class="option-item" *ngIf="email">
          <mat-icon>mail</mat-icon>
          <span class="option-type"
            >E-mail:
            <a href="mailto:{{ email }}" class="option-value">{{
              email
            }}</a></span
          >
        </div>
        <div class="option-item" *ngIf="phone">
          <mat-icon>phone</mat-icon>
          <span class="option-type"
            >Call:
            <!-- <img src="assets/img/eua-flag.svg" alt="" class="flag"  /> -->
            <span class="option-value"> {{ phone }}</span></span
          >
        </div>
      </div>
    </div>

    <div class="divider">
      <span class="divider-line"></span>
      <span class="divider-text">{{'or' | translate}}</span>
      <span class="divider-line"></span>
    </div>
    <div class="new-thread" *ngIf="thread && message">
      <h3 class="subtitle" >
        {{'Sendamessageviachat' | translate}}
      </h3>

      <app-shared-form-input-text
        [(ngModel)]="thread!.subject"
        [disabled]="loading"
        id="subject"
        name="subject"
        label="{{'NewMessageSubject' | translate}}"
        
        placeholder="{{'Eg.: What documents do I need' | translate}} "
      ></app-shared-form-input-text>
      <app-shared-form-input-text
        [(ngModel)]="message!.value"
        id="value"
        [disabled]="loading"
        name="value"

        label="{{'NewMessageNewMessage'| translate}}"
        placeholder="{{'Type your message' | translate}}"
      ></app-shared-form-input-text>

      <button class="submit-btn" (click)="saveThread()" [disabled]="loading">
        {{ (loading ? "Sending..." : "Send") | translate }}
      </button>
    </div>
  </div>
</div>
