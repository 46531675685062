import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RootRoutingModule } from './root-routing.module';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SignupComponent } from './pages/signup/signup.component';
import { MaterialModule } from 'src/app/shared/modules/material/material-module.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RootComponent } from './root.component';
import { FormV2ComponentsModule } from 'src/app/shared/forms-v2/form-v2.module';
import { RecoveryEmailComponent } from './pages/forgot-password/components/recovery-email/recovery-email.component';
import { RecoveryCodeComponent } from './pages/forgot-password/components/recovery-code/recovery-code.component';
import { RecoveryNewPasswordComponent } from './pages/forgot-password/components/recovery-new-password/recovery-new-password.component';
import { RecoverySuccessComponent } from './pages/forgot-password/components/recovery-success/recovery-success.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { OneTimeLinkComponent } from './pages/one-time-link/one-time-link.component';
import { TranslateModule } from '@ngx-translate/core';
import { AgentLoginComponent } from './pages/agent-login/agent-login.component';

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    SignupComponent,
    RootComponent,
    RecoveryEmailComponent,
    RecoveryCodeComponent,
    RecoveryNewPasswordComponent,
    RecoverySuccessComponent,
    VerifyComponent,
    OneTimeLinkComponent,
    AgentLoginComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RootRoutingModule,
    FormV2ComponentsModule,
    TranslateModule,
  ],
})
export class RootModule {}
