<div class="kyc-modal">
  <div class="header" *ngIf="steps !== 'ERROR' && steps !== 'SUCCESS'">
    <div class="start">
      <mat-icon>
        {{
          steps === "TYPE-DOC"
            ? "description"
            : steps === "UPLOAD"
            ? "cloud_upload"
            : steps === "PROCESSING"
            ? "preview"
            : steps === "END_PROCESSING"
            ? "check_circle"
            : steps === "ERROR"
            ? "error"
            : steps === "SUCCESS"
            ? "check_circle"
            : ""
        }}
      </mat-icon>
      <h3 class="title">
        {{
          (steps === "TYPE-DOC"
            ? "Select the type of document"
            : steps === "UPLOAD"
            ? "Upload your document - " + getDocumentTypeLabel(documentType)
            : steps === "PROCESSING"
            ? "Checking your document..."
            : steps === "END_PROCESSING"
            ? "Waiting for approval"
            : steps === "ERROR"
            ? "Error"
            : steps === "SUCCESS"
            ? "Success"
            : ""
          ) | translate
        }}
      </h3>
    </div>

    <div class="end">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
  </div>
  <ng-container *ngIf="steps === 'TYPE-DOC'">
    <div class="container typedoc">
      <input-text-select
        [(ngModel)]="documentType"
        id="doctype"
        name="doctype"
        label="{{ 'Document Type' | translate }}"
        [options]="options"
      />
      <p class="text">
        Seus documentos de identificação serão analisados por meio do Thirdfort,
        nosso confiável provedor terceirizado de Conheça Seu Cliente (KYC). O
        Thirdfort é especializado na análise e confirmação seguras de dados
        pessoais para garantir o cumprimento dos requisitos legais e proteger
        contra fraudes de identidade. Por favor, fique tranquilo, pois sua
        privacidade é primordial para nós, e todas as informações serão
        processadas em estrita conformidade com nosso Aviso de Privacidade e as
        leis de proteção de dados aplicáveis.
      </p>
    </div>
    <div class="footer">
      <button class="action-btn cancel" (click)="close()">
        {{ "Cancel" | translate }}
      </button>
      <button class="action-btn save" (click)="nextStep()">
        {{ "Next" | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'UPLOAD'">
    <div class="container upload">
      <ng-container *ngIf="documentType === DocumentType.PASSPORT">
        <app-input-upload-file
          class="single-upload"
          [fileSrc]="''"
          maxHeight="200px"
          [hasTitleDeleteImg]="false"
          [deleteButtonDisposition]="'vertical'"
          (file)="changeImage($event, 'front')"
        ></app-input-upload-file>
      </ng-container>
      <ng-container *ngIf="documentType !== DocumentType.PASSPORT">
        <div class="multiple-upload">
          <div class="side front">
            <h4 class="multiple-title" i18n="@@Frontimage">
              {{ "Front image" | translate }}
            </h4>
            <app-input-upload-file
              class="image-upload"
              [fileSrc]="''"
              maxHeight="180px"
              [hasTitleDeleteImg]="false"
              [deleteButtonDisposition]="'vertical'"
              (file)="changeImage($event, 'front')"
            ></app-input-upload-file>
          </div>
          <div class="side back">
            <h4 class="multiple-title" i18n="@@Backimage">
              {{ "Back image" | translate }}
            </h4>
            <app-input-upload-file
              class="image-upload"
              [fileSrc]="''"
              maxHeight="180px"
              [hasTitleDeleteImg]="false"
              [deleteButtonDisposition]="'vertical'"
              (file)="changeImage($event, 'back')"
            ></app-input-upload-file>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="footer">
      <button class="action-btn cancel" (click)="backStep()">
        {{ "Back" | translate }}
      </button>
      <button
        class="action-btn save"
        [disabled]="
          (documentType === DocumentType.PASSPORT && !frontImage) ||
          (documentType !== DocumentType.PASSPORT &&
            (!frontImage || !backImage))
        "
        (click)="nextStep()"
      >
        {{ "Next" | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'PROCESSING'">
    <div class="container processing">
      <mat-progress-bar
        mode="determinate"
        [value]="progress"
      ></mat-progress-bar>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'SUCCESS'">
    <div class="container status success">
      <mat-icon>check_circle</mat-icon>
      <h3 class="title" i18n="@@Yourdocumenthasbeensuccessfullyuploaded">
        {{'Your document has been successfully uploaded' | translate}}
      </h3>
    </div>
    <div class="footer central">
      <button class="action-btn cancel" (click)="close()">Finish</button>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'END_PROCESSING'">
    <div class="container status success">
      <mat-icon>check_circle</mat-icon>
      <h3 class="title" i18n="@@Yourdocumenthasbeensuccessfullyuploaded">
        Your document still being processed. We will notify you when it's done.
      </h3>
    </div>
    <div class="footer central">
      <button class="action-btn cancel" (click)="close()">Finish</button>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'ERROR'">
    <div class="container status error">
      <mat-icon>error</mat-icon>
      <h3 class="title" i18n="@@Anerroroccurredwhileuploadingyourdocument">
        An error occurred while uploading your document
      </h3>
    </div>
    <div class="footer central">
      <button class="action-btn cancel" (click)="close()">Leave</button>
      <button class="action-btn save" (click)="nextStep()">Try Again?</button>
    </div>
  </ng-container>
</div>
