<div class="container">
  <button mat-icon-button="" class="close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <div class="header">
    <img src="assets/icons/delete.svg" alt="Delete" />
    <h5 class="title" >{{'Delete' | translate}}</h5>
  </div>
  <p class="text" >
    {{'Areyousureyouwanttodeletethisfile' | translate}}
  </p>

  <div class="action-btns">
    <button class="act-btn btn-close" (click)="dialogRef.close('n')">
      {{'Cancel' | translate}}
    </button>
    <button class="act-btn btn-confirm" (click)="dialogRef.close('y')">
      {{'Delete' | translate}}
    </button>
  </div>
</div>
