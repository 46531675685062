<div class="block-rectangle">
  <div class="wrapper-img">
    <img [src]="case.configuration.images.high_res" alt="{{ case.summary }}" />
  </div>
  <div class="category">
    <span class="circle" *ngIf="case.case_type"></span>{{ case.case_type }}
  </div>
  <h4>{{ case.summary }}</h4>
  <!-- <h5>Claim up to <strong>{{ 10000 | currency : "GBP" }}</strong></h5> -->
  <p class="text-container">
    {{ case.description_clients }}
  </p>
  <a
    [routerLink]="['/client/cases/' + case.code]"
    routerLinkActive="true"
    class="claim-button"
    role="button"
  >
    {{'Claim Now'| translate}}
    <mat-icon>arrow_right</mat-icon>
  </a>
</div>
