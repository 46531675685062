<div class="cont">
  <div class="card">
    <ng-container *ngIf="thread"></ng-container>

    <h2>{{ thread?.subject }}</h2>
    <hr />
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of thread?.cp_message">
        <mat-expansion-panel-header>
          {{ item.author_display_name }}
          <span class="light">
            {{ item.created_at | date : "MMM, d y HH:mm" }}
          </span>
        </mat-expansion-panel-header>
        <p [innerHTML]="item.value"></p>
      </mat-expansion-panel>
    </mat-accordion>
    <hr />

    <quill-editor [(ngModel)]="reply" [modules]="modules"></quill-editor>

    <button
      [disabled]="sending"
      (click)="send()"
      class="reply"
      mat-raised-button=""
    >
      Reply to this thread
    </button>
  </div>
</div>
