<div class="tbl-cont">
  <div class="action-bar" [class.has-search]="!!settings.textFilterConfig">
    <!-- edit columns -->
    <div class="action-buttons-table">
      <ng-container *ngFor="let ag of currentSettings.actionSettings">
        <button mat-stroked-button="" [matMenuTriggerFor]="menu">
          <mat-icon>expand_more</mat-icon>
          {{ ag.displayName }}
        </button>
        <mat-menu #menu="matMenu">
          <button
            (click)="runAction(act)"
            *ngFor="let act of ag.actions; index as i"
            mat-menu-item=""
          >
            {{ act.displayName }}
          </button>
        </mat-menu>
      </ng-container>
      <button mat-stroked-button="" color="gray" [matMenuTriggerFor]="menu">
        <mat-icon>expand_more</mat-icon>
        <span i18n="@@Edit"> Edit </span>
      </button>
      <mat-menu #menu="matMenu">
        <!-- Main columns -->
        <ng-container *ngFor="let col of currentSettings.columns; index as i">
          <div *ngIf="!col.parent && col.name" mat-menu-item="">
            <mat-checkbox
              color="primary"
              (click)="toggleVisibility($event, i); $event.stopPropagation()"
              [checked]="col.visible"
              >{{ col.name }}</mat-checkbox
            >
          </div>
        </ng-container>

        <!-- Nested Columns -->
        <ng-container *ngFor="let mainItem of objectKeys(parentColumnSettings)">
          <button mat-menu-item="" [matMenuTriggerFor]="sub_menu">
            {{ mainItem }}
          </button>
          <mat-menu #sub_menu="matMenu">
            <div
              *ngFor="let subItem of parentColumnSettings[mainItem]"
              mat-menu-item=""
            >
              <mat-checkbox
                color="primary"
                (click)="
                  toggleVisibilityById($event, subItem.id);
                  $event.stopPropagation()
                "
                [checked]="subItem.visible"
                >{{ subItem.name }}</mat-checkbox
              >
            </div>
          </mat-menu>
        </ng-container>
      </mat-menu>
    </div>

    <div class="search-content" *ngIf="!!settings.textFilterConfig">
      <!-- <mat-form-field appearance="outline" id="search-form">
        <mat-icon matPrefix>search</mat-icon>
        <input
        matInput
        placeholder="Search"
        (change)="handleSearchChange($event)"
         />
      </mat-form-field> -->

      <div class="input-container">
        <mat-icon>search</mat-icon>
        <input
          type="text"
          placeholder="Search"
          [(ngModel)]="searchval"
          (keyup)="handleSearchChange($event)"
        />
      </div>
    </div>
  </div>

  <table
    mat-table=""
    *ngIf="dataSource"
    [dataSource]="dataSource"
    matSort=""
    recycleRows=""
    multiTemplateDataRows=""
  >
    <ng-container matColumnDef="select">
      <th class="b-table-header" mat-header-cell="" *matHeaderCellDef="">
        <!-- [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()" -->
        <mat-checkbox
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.type === 'all'"
          [indeterminate]="
            selection.type === 'all-some' || selection.type === 'none-some'
          "
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td class="b-table" mat-cell="" *matCellDef="let row">
        <!-- [checked]="selection.isSelected(row)" -->
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? toggleOneRow(row) : null"
          [checked]="isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container
      [matColumnDef]="col.id"
      *ngFor="let col of currentSettings.columns; index as i"
    >
      <!-- mat-sort-header
      sortActionDescription="Sort by {{ col.name }}" -->
      <th class="b-table-header" mat-header-cell="" *matHeaderCellDef="">
        <span class="hc">
          {{ col.name }}
          <button
            *ngIf="col.id !== 'acc' && col.type !== 'component'"
            mat-icon-button=""
            [matMenuTriggerFor]="filterMenu"
            (click)="openFilter(col)"
          >
            <mat-icon
              *ngIf="
                !(
                  (col.filters &&
                    col.filters[0] &&
                    col.filters[0].value !== null) ||
                  col.sort
                )
              "
              svgIcon="custom_filter_outline"
              class="filter-icon"
            ></mat-icon>
            <mat-icon
              *ngIf="
                (col.filters &&
                  col.filters[0] &&
                  col.filters[0].value !== null) ||
                col.sort
              "
              svgIcon="custom_filter_filled"
              class="filter-icon"
            ></mat-icon>
          </button>
        </span>
      </th>
      <td class="b-table" mat-cell="" *matCellDef="let element">
        <div [ngSwitch]="col.type">
          <div *ngSwitchCase="'accordeon'">
            <button
              (click)="accordeonButtonClick($event, element)"
              mat-icon-button=""
            >
              <mat-icon>expand_more</mat-icon>
            </button>
          </div>

          <div *ngSwitchCase="'json'">
            <ng-container *ngIf="col.root && element[col.root]">
              {{ element[col.root!][col.id] }}
            </ng-container>
          </div>

          <div *ngSwitchCase="'link'">
            <ng-container *ngIf="element && element[col.id]">
              <a
                *ngIf="!col.config?.newtab"
                class="tbl-link"
                routerLink="/firm/{{
                  col.entityName ? col.entityName : settings.entity
                }}/{{
                  col.entityPath ? element[col.entityPath] : element[col.id]
                }}"
                >{{ element[col.id].substring(0, 6) }}</a
              >
            </ng-container>

            <a
              *ngIf="col.config?.newtab"
              class="tbl-link"
              target="_blank"
              routerLink="/firm/{{
                col.entityName ? col.entityName : settings.entity
              }}/{{ element[col.id] }}"
              >{{ element[col.id].substring(0, 6) }}</a
            >
          </div>

          <div class="events" *ngSwitchCase="'events'">
            <ng-container *ngFor="let eve of col.config.emmiters">
              <a class="emmiters-eve" (click)="emit(eve, element)">
                <ng-container *ngIf="eve.icon">
                  <mat-icon>{{ eve.icon }}</mat-icon>
                </ng-container>
                <ng-container *ngIf="eve.display">
                  {{ eve.display }}
                </ng-container>
              </a>
              <span> | </span>
            </ng-container>
          </div>

          <div *ngSwitchCase="'date'">{{ element[col.id] | date }}</div>

          <div *ngSwitchCase="'url'">
            <a href="{{ element[col.id] }}">{{ col.linkText }}</a>
          </div>

          <div *ngSwitchCase="'component'">
            <ng-container
              *ngTemplateOutlet="
                cell || defaultCell;
                context: { $implicit: element, col }
              "
            ></ng-container>
          </div>

          <div *ngSwitchDefault="">{{ element[col.id] }}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        class="b-table"
        mat-cell=""
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
      >
        <ng-container *ngIf="element === expandedElement">
          <ng-template accordeonHost=""></ng-template>
        </ng-container>
      </td>
    </ng-container>

    <ng-template #defaultCell="" let-element="" let-col="">
      {{ element[col.id] }}
    </ng-template>

    <tr mat-header-row="" *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row=""
      class="table-row"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
    <tr
      mat-row=""
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>

  <div class="paginator-bar">
    <div class="counter">
      <span> {{ getSelectionLength() }} Selected </span>
    </div>
    <mat-paginator
      [length]="dataSource?.amount"
      [pageSizeOptions]="[5, 10, 20]"
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>

  <mat-menu #filterMenu="matMenu" panelClass="menu-filter">
    <ng-container *ngIf="filteringColumn">
      <app-filter
        [settings]="currentSettings"
        [column]="filteringColumn"
        (columnChanges)="columnChange($event)"
      ></app-filter>
    </ng-container>
  </mat-menu>
</div>
