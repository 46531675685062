<div class="card card-no-border">
  <h2 >{{'ClaimInformation' | translate}}</h2>
  <!-- <div class="card__criteria">
    <h3>Claim Criteria</h3>
    <ul>
      <li>Lorem ipsum, dolor sit amet consectetur adipisicing.</li>
      <li>Quod a velit quibusdam incidunt, deleniti laboriosam.</li>
      <li>Veniam, iusto et nesciunt earum voluptas ipsam.</li>
    </ul>
  </div>
  <div class="card__criteria">
    <h3>Document Criteria</h3>
    <ul>
      <li>Lorem ipsum, dolor sit amet consectetur adipisicing.</li>
      <li>Quod a velit quibusdam incidunt, deleniti laboriosam.</li>
      <li>Veniam, iusto et nesciunt earum voluptas ipsam.</li>
    </ul>
  </div> -->
  <button class="def-button" [disabled]="loading" (click)="createClaim()">
    <ng-container *ngIf="loading">
      <mat-spinner diameter="20"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!loading">{{'Claim Now'| translate}}</ng-container>
  </button>
</div>
