<div class="row">
  <h4 i18n="@@Input">Input</h4>
</div>
<div class="row">
  <div class="col-2 col-sm-8">
    <div class="input-container">
      <input type="text" value="Placeholder" />
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div class="input-container">
      <input type="number" />
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div class="input-container">
      <input type="date" />
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div class="select-container">
      <div class="select">
        <span i18n="@@Chooseyourlanguage">Choose your language</span>
        <i class="ic-x-sm ic-arrow-down"></i>
      </div>
      <div class="option-select">
        <ul>
          <li>
            <img src="../../../../assets/img/usa.png" alt="" />
            <span i18n="@@English">English</span>
          </li>
          <li>
            <img src="../../../../assets/img/brazil.png" alt="" />
            <span i18n="@@Portuguese">Portuguese</span>
          </li>
          <li>
            <img src="../../../../assets/img/spain.png" alt="" />
            <span i18n="@@Spanish">Spanish</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2 col-sm-8">
    <textarea class="textarea-container" name="" id="" cols="4" rows="10" i18n="@@Textarea">
Textarea</textarea>
  </div>
  <div class="col-2 col-sm-8">
    <label class="toogle">
      <input type="checkbox" checked="" />
      <span class="slider round"></span>
    </label>
    <label class="toogle">
      <input type="checkbox" />
      <span class="slider round"></span>
    </label>
  </div>
  <div class="col-2 col-sm-8">
    <label class="checkbox">
      <input type="checkbox" name="" id="" />
      <span class="indicator"> </span>
    </label>
  </div>
  <div class="col-2 col-sm-8">
    <input class="checkbox-radio" type="radio" name="group-1" /><br />
  </div>
  <div class="col-2 col-sm-8">
    <div class="step-group">
      <div class="step-item">
        <span class="step-indicator" i18n="@@1">1</span>
        <span class="sub-2" i18n="@@Category">Category</span>
      </div>
      <div class="stick"></div>
      <div class="step-item">
        <span class="step-indicator" i18n="@@2">2</span>
        <span class="sub-2" i18n="@@Type">Type</span>
      </div>
      <div class="stick"></div>
      <div class="step-item">
        <span class="step-indicator parked" i18n="@@3">3</span>
        <span class="sub-2" i18n="@@Upload">Upload</span>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <h4 i18n="@@InputAngularMaterial">Input Angular Material</h4>
</div>
<div class="row">
  <div class="col-2 col-sm-8">
    <div>
      <app-input-text [(ngModel)]="inputText" [placeholder]="'teste'"></app-input-text>
      <p>{{ inputText }}</p>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <div>
      <app-input-date [(ngModel)]="inputDate"></app-input-date>
      <p>{{ inputDate }}</p>
    </div>
  </div>

  <div class="col-2 col-sm-8">
    <div>
      <app-input-select [label]="'Selecione a sua lingua:'" [options]="[{ value: 'portuguese', text: 'portuguese' }]" [(ngModel)]="inputSelect"></app-input-select>
      <p>{{ inputSelect }}</p>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <app-input-telephone [(ngModel)]="inputTelephone"></app-input-telephone>
    <p>{{ inputTelephone }}</p>
  </div>
</div>
<div class="row">
  <div class="col-2 col-sm-8">
    <div>
      <app-input-checkbox></app-input-checkbox>
    </div>
  </div>
  <div class="col-2 col-sm-8">
    <app-input-radio [items]="[
        { label: 'teste', value: 'teste' },
        { label: 'teste', value: 'teste' }
      ]"></app-input-radio>
  </div>
  <div class="col-2 col-sm-8">
    <mat-slide-toggle i18n="@@teste">teste</mat-slide-toggle>
    <mat-slide-toggle i18n="@@teste">teste</mat-slide-toggle>
  </div>
  <div class="col-2 col-sm-8">
    <app-input-dropdown [title]="'teste'" [matMenuTriggerFor]="menu">
      <mat-menu #menu="matMenu">
        <button mat-menu-item="">Item 1</button>
        <button mat-menu-item="">Item 2</button>
      </mat-menu>
    </app-input-dropdown>
  </div>
</div>
<div class="row">
  <div class="col-2 col-sm-8">
    <app-input-textarea [(ngModel)]="inputTextArea" [label]="'teste'"></app-input-textarea>
    <p>{{ inputTextArea }}</p>
  </div>
</div>
<div class="row">
  <div class="col-8">
    <mat-stepper labelPosition="bottom" #stepper="">
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel="">Category</ng-template>
          <mat-form-field appearance="fill">
            <mat-label i18n="@@Name">Name</mat-label>
            <input matInput="" placeholder="Last name, First name" formControlName="firstCtrl" required="" />
          </mat-form-field>
          <div>
            <button mat-button="" matStepperNext="">{{"Next" | translate}}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel="">Type</ng-template>
          <mat-form-field appearance="fill">
            <mat-label i18n="@@Address">Address</mat-label>
            <input matInput="" formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required="" />
          </mat-form-field>
          <div>
            <button mat-button="" matStepperPrevious="">{{"Back" | translate}}</button>
            <button mat-button="" matStepperNext="">{{"Next" | translate}}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel="">Upload </ng-template>
          <mat-form-field appearance="fill">
            <mat-label i18n="@@Address">Address</mat-label>
            <input matInput="" formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required="" />
          </mat-form-field>
          <div>
            <button mat-button="" matStepperPrevious="">{{"Back" | translate}}</button>
            <button mat-button="" matStepperNext="">{{"Next" | translate}}</button>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </div>
</div>
