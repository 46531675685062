<mat-form-field class="phone-form" [formGroup]="phone">
  <ngx-mat-intl-tel-input
    [preferredCountries]="preferredCountries"
    [enablePlaceholder]="true"
    [enableSearch]="true"
    [disabled]="autofillMode && !!question.answer!.value && !autofillEditable"
    name="number"
    describedBy="phoneInput"
    formControlName="number"
  ></ngx-mat-intl-tel-input>
  <mat-error
    *ngIf="
      phone.controls.number.errors && phone.controls.number.errors['required']
    "
    i18n="@@Informaphone"
    >Inform a phone</mat-error
  >
  <mat-error
    style="color: black"
    *ngIf="
      phone.controls.number.errors &&
      phone.controls.number.errors['validatePhoneNumber']
    "
    i18n="@@Remembertodouble-checkyourphonenumber"
    >Remember to double-check your phone number</mat-error
  >
</mat-form-field>
