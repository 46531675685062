<div class="title">
  <h4 i18n="@@Typograph">Typograph</h4>
</div>
<section class="container">
  <div class="open-sans">
    <div class="row">
      <h4>Open Sans</h4>
    </div>
    <div class="row">
      <h1>H1</h1>
      <h2>H2</h2>
      <h3>H3</h3>
      <h4>H4</h4>
      <h5>H5</h5>
      <h6>H6</h6>
    </div>
    <div class="row">
      <span class="sub-1" i18n="@@Subtitle1">Subtitle 1</span>
      <span class="sub-2" i18n="@@Subtitle2">Subtitle 2</span>
      <span class="text-body-1" i18n="@@Body1">Body 1</span>
      <span class="text-body-2" i18n="@@Body1">Body 1</span>
      <span class="button" i18n="@@Button">Button</span>
      <span class="caption" i18n="@@Caption">Caption</span>
      <span class="overline" i18n="@@Overline">Overline</span>
      <span class="special-button" i18n="@@SpecialButton">Special Button</span>
    </div>
  </div>

  <div class="helvetica">
    <div class="row">
      <h4 i18n="@@Helvetica">Helvetica</h4>
    </div>
    <div class="row">
      <h1>H1</h1>
      <h2>H2</h2>
      <h3>H3</h3>
      <h4>H4</h4>
      <h5>H5</h5>
      <h6>H6</h6>
    </div>
    <div class="row">
      <span class="sub-1" i18n="@@Subtitle1">Subtitle 1</span>
      <span class="sub-2" i18n="@@Subtitle2">Subtitle 2</span>
      <span class="text-body-1" i18n="@@Body1">Body 1</span>
      <span class="text-body-2" i18n="@@Body1">Body 1</span>
      <span class="button" i18n="@@Button">Button</span>
      <span class="caption" i18n="@@Caption">Caption</span>
      <span class="overline" i18n="@@Overline">Overline</span>
      <span class="special-button" i18n="@@SpecialButton">Special Button</span>
    </div>
  </div>

  <div class="aeonki">
    <div class="row">
      <h4 i18n="@@Aeonik">Aeonik</h4>
    </div>
    <div class="row">
      <h1>H1</h1>
      <h2>H2</h2>
      <h3>H3</h3>
      <h4>H4</h4>
      <h5>H5</h5>
      <h6>H6</h6>
    </div>
    <div class="row">
      <span class="sub-1" i18n="@@Subtitle1">Subtitle 1</span>
      <span class="sub-2" i18n="@@Subtitle2">Subtitle 2</span>
      <span class="text-body-1" i18n="@@Body1">Body 1</span>
      <span class="text-body-2" i18n="@@Body1">Body 1</span>
      <span class="button" i18n="@@Button">Button</span>
      <span class="caption" i18n="@@Caption">Caption</span>
      <span class="overline" i18n="@@Overline">Overline</span>
      <span class="special-button" i18n="@@SpecialButton">Special Button</span>
    </div>
  </div>
</section>
