<div class="row">
  <h4 i18n="@@Modal">Modal</h4>
</div>

<div class="row">
  <app-button-default (click)="callSucessModal()" [size]="'md'" i18n="@@Sucess">Sucess</app-button-default>
  <app-button-default (click)="callErrorModal()" [type]="'danger'" [size]="'md'" i18n="@@Error">Error</app-button-default>
  <app-button-default (click)="callChoiceModal()" [type]="'secondary'" [size]="'md'" i18n="@@Choice">Choice</app-button-default>
  <app-button-clear (click)="callConfirmModal()" [size]="'md'" i18n="@@Confirm">Confirm</app-button-clear>
  <app-button-clear (click)="callEletronicSignatureModal()" [size]="'md'" i18n="@@Confirm">Confirm</app-button-clear>
</div>

<div class="row">
  <div class="col-4 col-sm-8">
    <div class="modal modal-sucess">
      <div class="modal-header">
        <h6 i18n="@@SUCESS">SUCESS</h6>
      </div>
      <div class="modal-body">
        <h6 i18n="@@NewClientsaddedsuccessfully">New Clients added successfully</h6>
      </div>
      <div class="modal-footer">
        <button class="btn-md btn-secondary">CLOSE WINDOW</button>
      </div>
    </div>
  </div>
  <div class="col-4 col-sm-8">
    <div class="modal">
      <div class="modal-header">
        <h6 i18n="@@CONFIRM">CONFIRM</h6>
      </div>
      <div class="modal-body">
        <h6 i18n="@@Somemessagetoconfirm.Ok?">Some message to confirm. Ok?</h6>
      </div>
      <div class="modal-footer">
        <button class="btn-md">CONFIRM</button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-4 col-sm-8">
    <div class="modal modal-error">
      <div class="modal-header">
        <h6 i18n="@@SOMETHINGWENTWRONG">SOMETHING WENT WRONG</h6>
        <i class="ic-x-sm ic-cancel"></i>
      </div>
      <div class="modal-body">
        <h6 i18n="@@Systemerror.Pleasetryagaininafewminutes.">System error. Please try again in a few minutes.</h6>
      </div>
      <div class="modal-footer">
        <button class="btn-md">TRY AGAIN</button>
      </div>
    </div>
  </div>
  <div class="col-4 col-sm-8">
    <div class="modal modal-choice">
      <div class="modal-header">
        <span i18n="@@ACESSREMOVAL">ACESS REMOVAL</span>
        <i class="ic-x-sm ic-cancel"></i>
      </div>
      <div class="modal-body">
        <p i18n="@@Areyousureyouwanttoremove?">Are you sure you want to remove?</p>
      </div>
      <div class="modal-footer">
        <button class="btn-md btn-light">CANCEL</button>
        <button class="btn-md">REMOVE</button>
      </div>
    </div>
  </div>
</div>
