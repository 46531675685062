<section class="profile gap-container">
  <app-display-large>{{'Profile' | translate}}</app-display-large>
  <div class="profile-container">
    <div class="data-container">
      <div class="tabs">
        <div
          class="tab"
          [class.active]="tabActive === 'edit'"
          (click)="changeTab('edit')"
        >
          {{'EditProfile' | translate}}
        </div>
        <!-- <div
          class="tab"
          [class.active]="tabActive === 'docs'"
          (click)="changeTab('docs')"
        >
          Documents
        </div> -->
        <div
          class="tab"
          [class.active]="tabActive === 'sec'"
          (click)="changeTab('sec')"
        >
          {{'Security' | translate}}
        </div>
        <div
          class="tab"
          [class.active]="tabActive === 'notification-preferencies'"
          (click)="changeTab('notification-preferencies')"
        >
          {{'Notifications' | translate}}
        </div>
      </div>
      <div class="tab-container">
        <!-- <app-edit-profile></app-edit-profile> -->
        <div [ngSwitch]="tabActive">
          <div *ngSwitchCase="'edit'">
            <app-edit-profile></app-edit-profile>
          </div>
          <div *ngSwitchCase="'docs'">
            <app-documents></app-documents>
          </div>
          <div *ngSwitchCase="'sec'">
            <app-security></app-security>
          </div>
          <div *ngSwitchCase="'notification-preferencies'">
            <app-notification-preferencies></app-notification-preferencies>
          </div>
          <div *ngSwitchDefault>output2</div>
        </div>
      </div>
    </div>

    <div class="legal-info">
      <app-legal-information></app-legal-information>
    </div>
  </div>
</section>
