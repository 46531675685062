<div class="container">
  <div class="header">
    <mat-icon class="title-icon success" *ngIf="success">celebration</mat-icon>
    <mat-icon class="title-icon error" *ngIf="error">highlight_off</mat-icon>

    <h5 class="title success" *ngIf="success" >{{'Success' | translate}}</h5>
    <h5 class="title error" *ngIf="error" >{{'Error' | translate}}</h5>
  </div>
  <p class="text" *ngIf="success" >{{'MFAconfigured!' | translate}}</p>
  <p
    class="text"
    *ngIf="error"

  >
    {{'Someerroroccurredonthisprocess,tryagainlater.' | translate}}
  </p>
  <button class="btn-close" (click)="close$.emit(true)">Close</button>
</div>
