<mat-form-field>
  <mat-label i18n="@@Chooseadate">{{"Choose a date" | translate}}</mat-label>
  <input
    matInput=""
    [(ngModel)]="question.answer!.value"
    [min]="min"
    [max]="max"
    [matDatepicker]="picker"
    (change)="change()"
    (dateChange)="change()"
  />
  <mat-datepicker-toggle
    matIconSuffix=""
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-datepicker #picker=""></mat-datepicker>

  <mat-error i18n="@@Checkdateinformed">Check date informed</mat-error>
</mat-form-field>
