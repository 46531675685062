<section id="login">
  <div class="left-image">
    <img src="/assets/img/mdr-Logo.svg" alt="" class="logo-left" />
    <img src="/assets/img/login-left-img.svg" alt="" class="img-left" />
  </div>
  <div class="login-container">
    <form class="login-form" [formGroup]="form" *ngIf="form && !mfaNeeded">
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <h5 class="title" i18n="@@Signup">Sign up</h5>
      <div class="form-group-login">
        <app-shared-form-input-text
          formControlName="name"
          type="name"
          name="name"
          i18n-label="@@SignUpFullName"
          label="Full Name"
        ></app-shared-form-input-text>
        <app-shared-form-input-text
          formControlName="email"
          type="email"
          name="email"
          i18n-label="@@SignUpEmail"
          label="Email"
        ></app-shared-form-input-text>
        <app-shared-form-input-text
          formControlName="password"
          type="password"
          [passwordInput]="true"
          name="password"
          i18n-label="@@SignUpPassword"
          label="Password"
        ></app-shared-form-input-text>
        <app-shared-form-input-text
          formControlName="repeat"
          type="password"
          [passwordInput]="true"
          name="repeat"
          i18n-label="@@SignUpRepeat"
          label="Repeat"
        ></app-shared-form-input-text>

        <button
          class="submit-btn"
          type="submit"
          [disabled]="isLoading"
          (click)="onSubmit()"
        >
          <ng-container *ngIf="!isLoading">Sign up</ng-container>
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </ng-container>
        </button>

        <p>
          Already registered?
          <span [routerLink]="'/auth/login'" class="link" i18n="@@Signin"
            >Sign in</span
          >
        </p>
      </div>
    </form>
    <form class="mfa-form" [formGroup]="mfaForm" *ngIf="mfaForm && mfaNeeded">
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <h5 class="title" i18n="@@MFAcodechallenger">MFA code challenger</h5>
      <p
        class="desc"
        i18n="
          @@Foraddedsecurity,pleaseverifyyouridentityusingMulti-FactorAuthentication(MFA).Enterthecodegeneratedbyyourauthenticatorappbelowtoproceed."
      >
        For added security, please verify your identity using Multi-Factor
        Authentication (MFA). Enter the code generated by your authenticator app
        below to proceed.
      </p>
      <div class="form-group-login">
        <app-shared-form-code-challenger
          formControlName="code"
          type="code"
          name="code"
          label="MFA Code"
        ></app-shared-form-code-challenger>

        <button
          class="submit-btn mfa-btn"
          [disabled]="isLoading"
          (click)="submitMfa()"
        >
          <ng-container *ngIf="!isLoading">Sign In</ng-container>
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
            Loading...
          </ng-container>
        </button>
      </div>
    </form>
  </div>
</section>
