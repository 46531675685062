<mat-tab-group [(selectedIndex)]="activeTabIndex">
  <mat-tab label="{{ doc.name }}" *ngFor="let doc of dtbs">
    <div class="full-content">
      <div class="a">
        <pdf-viewer
          src="{{ doc.url }}"
          [render-text]="true"
          [original-size]="false"
          [show-all]="false"
          style="width: 100%; height: 500px; background-color: white"
          [show-borders]="true"
          [(page)]="pagenbr"
        >
        </pdf-viewer>
        <div class="padme">
          <button
            mat-raised-button=""
            (click)="previousPage()"
            [disabled]="pagenbr <= 1"
          >
            Previous
          </button>
          <button mat-raised-button="" (click)="nextPage()">{{'Next' | translate}}</button>
        </div>

        <ng-container *ngIf="doc.signature_type == 'drawing'">
          <div class="container">
            <signature-pad
              #signature=""
              [options]="signaturePadOptions"
              (drawStart)="drawStart($event)"
              (drawEnd)="drawComplete($event)"
            ></signature-pad>
          </div>

          <div class="explanation">
            <p
              i18n="
                @@Pleaseprovideyoursignatureintheboxabovebyclickinganddraggingthemouseortappinganddraggingonyourscreen."
            >
              Please provide your signature in the box above by clicking and
              dragging the mouse or tapping and dragging on your screen.
            </p>
            <p
              i18n="
                @@Thisdoesnotrequireaperfectrenditionofyourlegalfirm,however,itmustresembleit."
            >
              This does not require a perfect rendition of your legal firm,
              however, it must resemble it.
            </p>
          </div>

          <div class="padme">
            <button mat-raised-button="" (click)="clearSig()">Clear</button>
            <button mat-raised-button="" (click)="doSign()" color="primary">
              Sign document
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="doc.signature_type == 'checkbox'">
          <div class="container">
            <mat-checkbox color="primary" i18n="@@Iacceptthetermsofthisdocument"
              >I accept the terms of this document</mat-checkbox
            >
          </div>
          <div class="padme">
            <button mat-raised-button="" (click)="clearSig()">Clear</button>
            <button mat-raised-button="" (click)="doSign()" color="primary">
              Sign document
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
