<div class="container">
  <h1 mat-dialog-title="">{{ data.title | translate}}</h1>
  <p class="description" mat-dialog-content="" *ngIf="data.description">
    {{ data.description | translate }}
  </p>
  <div mat-dialog-actions="">
    <button mat-stroked-button="" class="cancel-btn" mat-button="" title="cancel" [mat-dialog-close]="false">
      {{ data.cancelText | translate}}
    </button>

    <button class="confirm-btn" mat-button="" mat-raised-button="" [mat-dialog-close]="true" cdkFocusInitial="" title="confirm">
      {{ data.confirmText | translate}}
    </button>
  </div>
</div>
