import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { News } from 'src/app/core/models/news';

@Component({
  selector: 'app-more-news',
  templateUrl: './more-news.component.html',
  styleUrls: ['./more-news.component.scss'],
})
export class MoreNewsComponent {
  @Input() otherNews!: News[];

  constructor(private router: Router) {}

  goToNews(news: News) {
    if (news.metadata.link) {
      window.location.href = news.metadata.link;
    } else {
      this.router.navigate([`client/news/${news.id}`]);
    }
  }
}
