<mat-accordion>
  <mat-expansion-panel class="single" hideToggle="">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="flex align-itens-center">
          <i class="ic-sm ic-content"></i>
          <h6 i18n="@@HowcanIaccessthenewpage?">How can I access the new page?</h6>
        </div>
        <i id="icon" class="ic-sm ic-arrow-left"></i>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{ item.content }}</p>
  </mat-expansion-panel>
</mat-accordion>
