<ng-container *ngIf="type === 'light' && size == 'sm'">
  <button mat-button="" class="btn-clear-sm btn-clear-light">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'light' && size == 'md'">
  <button mat-button="" class="btn-clear-md btn-clear-light">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'light' && size == 'lg'">
  <button mat-button="" class="btn-clear-lg btn-clear-light">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'secondary' && size == 'sm'">
  <button mat-button="" class="btn-clear-sm btn-clear-outline">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'secondary' && size == 'md'">
  <button mat-button="" class="btn-clear-md btn-clear-secondary">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'secondary' && size == 'lg'">
  <button mat-button="" class="btn-clear-lg btn-clear-secondary">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'danger' && size == 'sm'">
  <button mat-button="" class="btn-clear-sm btn-clear-danger">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'danger' && size == 'md'">
  <button mat-button="" class="btn-clear-md btn-clear-danger">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'danger' && size == 'lg'">
  <button mat-button="" class="btn-clear-lg btn-clear-danger">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'dark' && size == 'sm'">
  <button mat-button="" class="btn-clear-sm btn-clear-dark">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'dark' && size == 'md'">
  <button mat-button="" class="btn-clear-md btn-clear-dark">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'dark' && size == 'lg'">
  <button mat-button="" class="btn-clear-lg btn-clear-dark">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type == undefined && size == 'sm'">
  <button mat-button="" class="btn-clear-sm btn-clear-default">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type == undefined && size == 'md'">
  <button mat-button="" class="btn-clear-md btn-clear-default">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type == undefined && size == 'lg'">
  <button mat-button="" class="btn-clear-lg btn-clear-default">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-template #contentTpl=""><ng-content></ng-content></ng-template>
