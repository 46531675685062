<div
  [class.unread]="isUnread"
  class="has-messages-list-item"
  (click)="goToMessage()"
  *ngIf="thread"
>
  <div class="start">
    <mat-icon>
      {{
        thread.medium === "CHAT"
          ? "chat"
          : thread.medium === "INTERNAL_MESSAGE"
          ? "chat"
          : thread.medium === "EMAIL"
          ? "mail_outline"
          : "chat"
      }}
    </mat-icon>
  </div>
  <div class="middle">
    <p class="subject">{{ thread.subject }}</p>
    <p class="message">
      {{ thread.cp_claim?.cp_case?.code ?? "-" }}
    </p>
  </div>
  <div class="end">
    <span class="date">
      <ng-container *ngIf="!!getNewestMessageFromThread(thread)">
        {{
          getNewestMessageFromThread(thread)!.created_at | date : "dd/MM/yyyy"
        }}
      </ng-container>
    </span>
  </div>
</div>
