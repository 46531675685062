<section class="claim gap-container" *ngIf="claim">
  <div class="info">
    <!-- info -->

    <!-- Image -->
    <img
      class="img-case"
      [src]="claim.cp_case.configuration.images.high_res"
      alt=""
    />
    <span class="chip-type" [class.type-1]="true">
      {{ claim.cp_case.case_type | translate }}
    </span>
    <!-- Case status -->

    <h4 class="claim-title">{{ claim.cp_case.summary }}</h4>
    <!-- <h1 class="claim-subtitle">
      Claim up to <span class="claim-value">£ 177,000</span>
    </h1> -->

    <!-- Claim data -->
    <div class="summary">
      <div class="summary-item">
        {{"Join Date:" | translate}}
        <span class="summary-item-label"> {{ (claim?.created_at | date ) }} </span>
      </div>
    </div>

    <!-- Case Summary -->
    <div class="description-case">
      <markdown>{{ claim.cp_case?.description_clients }}</markdown>
    </div>
  </div>

  <div class="data">
    <!-- data -->
    <div class="item actions">
      <app-os-actions-case
        [claim]="claim"
        [showImage]="false"
      ></app-os-actions-case>
    </div>

    <div class="item messages">
      <div class="messages-header">
        <h2 class="data-subtitle" >{{'Messages' | translate}}</h2>

        <div class="container-btn">
          <button
            *ngIf="messages.length > 0"
            class="write-messages-btn"
            (click)="goToNewMessage()"
          >
            {{'Contact us' | translate}}
            <mat-icon> add</mat-icon>
          </button>
        </div>
      </div>

      <div class="messages-container">
        <ng-container *ngIf="messages.length > 0">
          <!-- Has messages -->
          <div class="has-messages">
            <h5 class="has-message-subtitle"  >
              {{'Recentmessages' | translate}}
            </h5>
            <div class="has-messages-list">
              <ng-container
                class="has-messages-list-item"
                *ngFor="let index of [0, 1, 2, 3]"
              >
                <message-card [thread]="messages[index]"></message-card>
              </ng-container>
            </div>
            <div class="see-more" *ngIf="messages.length > 4">
              <button class="see-more-messages-btn" (click)="goAllMessages()">
                See more
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="messages.length === 0">
          <!-- No messages -->
          <div class="no-messages">
            <div class="no-messages-image">
              <img
                src="assets/img/no-messages.svg"
                alt="There are no messages for this claim"
              />
            </div>
            <div class="no-messages-info">
              <h3
                class="no-messages-text"
               
              >
                {{'Therearenomessagesforthisclaim' | translate}}
              </h3>

              <div class="container-btn">
                <button class="write-messages-btn" (click)="goToNewMessage()">
                  Contact us
                  <mat-icon> add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="item related-news" *ngIf="relatedNews">
      <h2 class="data-subtitle" >{{'RelatedNews' | translate}}</h2>

      <div class="related-news-container">
        <app-case-teaser-card [news]="relatedNews"></app-case-teaser-card>
        <ng-container>
          <div class="no-news-related">
            <div class="no-news-related-info">
              <h3
                class="no-news-related-text"
               
              >
                {{'Therearenorelatednewsforthisclaim' | translate}}
              </h3>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="item options">
      <h2 class="data-subtitle">{{'Options' | translate}}</h2>

      <div class="options-container">
        <div class="withdraw-card" (click)="withdrawClaim()">
          <div class="start">
            <mat-icon> delete </mat-icon>
          </div>
          <div class="middle">
            <p class="subject">
              {{'Withdrawfromclaim' | translate}}
            </p>
          </div>
          <div class="end"></div>
        </div>
      </div>
    </div>
  </div>
</section>
