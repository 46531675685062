<mat-card class="card">
    <mat-card-header class="card-header">
        <mat-card-title i18n="@@ClaimInformation">Claim Information</mat-card-title>
        <mat-card-subtitle i18n="@@Descriptions">Descriptions</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p i18n="@@Thiscardindeterminatesprogressbar.">This card indeterminates progress bar.</p>

    </mat-card-content>
    <mat-card-actions>
        <button mat-button="">CLAIN NOW</button>
    </mat-card-actions>
</mat-card>