<div class="modal modal-sucess">
  <div class="modal-header">
    <h6 i18n="@@SUCESS">SUCESS</h6>
  </div>
  <div class="modal-body">
    <h6 i18n="@@NewClientsaddedsuccessfully">New Clients added successfully</h6>
  </div>
  <div class="modal-footer">
    <button mat-button="" [mat-dialog-close]="true" class="btn-md btn-secondary">
      CLOSE WINDOW
    </button>
  </div>
</div>
