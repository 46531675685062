<div class="container">
  <div class="header">
    <mat-icon class="title-icon">error_outline</mat-icon>
    <h5 class="title" >{{'Important' | translate}}</h5>
  </div>
  <p class="text">
    {{'YouareenablingMulti-FactorAuthentication' | translate}}
    <span >{{'IOSStore' | translate}}</span> {{'or' | translate}}
    <span >{{'PlayStore' | translate}}</span>.
  </p>

  <mat-checkbox
    [(ngModel)]="confirmed"
    color="primary"
    
    >{{'Ihaveanauthenticatorinstalled' | translate}}</mat-checkbox
  >

  <button class="btn-close" [disabled]="!confirmed" (click)="init()">
    {{'Enable' | translate}}
  </button>
</div>
