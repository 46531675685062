import { Injectable } from '@angular/core';

import { DatabaseService } from './base/database.service';
import { Faq } from '../models/faq';
import { Kyc_Check } from '../models/kyc_check.model';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export type KycProvider = 'mock' | 'thirdfort' | 'textract';
export type DocumentType = 'passport' | 'driving_license' | 'identity_card';

export interface KycCheckRequest {
  provider: KycProvider;
  documentType: DocumentType;
  user_id: string;
  front_id: string;
  back_id?: string; // Optional for some document types
}
@Injectable({
  providedIn: 'root',
})
export class KycService extends DatabaseService<Kyc_Check> {
  constructor(private http: HttpClient) {
    super('cp_kyc_check');
  }

  async getAllForUser(id: string): Promise<Kyc_Check[]> {
    return (
      await this.supabase
        .from('cp_kyc_check')
        .select()
        .eq('checked_user_id', id)
        .order('date_requested', { ascending: true })
    ).data as unknown as Kyc_Check[];
  }

  async getById(id: string) {
    return (
      await this.supabase.from('cp_kyc_check').select().eq('id', id).single()
    ).data as unknown as Kyc_Check;
  }

  async submitKycCheck(checkRequest: KycCheckRequest): Promise<any> {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    return firstValueFrom(
      this.http.post(environment.lambdaUrl + '/kyc_check', checkRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  }
}
