<mat-form-field>
  <!-- Input for short text -->
  <input *ngIf="!isLongText" [(ngModel)]="question.answer!.value" matInput="" [disabled]="autofillMode && !autofillEditable && !!question.answer!.value" placeholder="Insert here" [required]="!question.elective" (input)="validateAndEmit()" />

  <!-- Textarea for long text -->
  <textarea *ngIf="isLongText" [(ngModel)]="question.answer!.value" matInput="" [disabled]="autofillMode && !autofillEditable && !!question.answer!.value" cdkTextareaAutosize="" placeholder="Insert here" [required]="!question.elective" (input)="validateAndEmit()" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>

  <!-- Error message for validation -->
  <!-- <mat-error *ngIf="!question.isValid">
    {{ question.messageError || "Invalid" }}
  </mat-error> -->
</mat-form-field>
