import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { Case, Requirement } from '../models/case';
import { Claim } from '../models/claim';
import { DatabaseService } from './base/database.service';
import { UserService } from './user.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Action } from '../models/action.model';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class ClaimsService extends DatabaseService<Claim> {
  constructor(private us: UserService, private http: HttpClient) {
    super('cp_claim');
  }

  async getMyClaimsWithCase(limit: number, page: number) {
    const user = await this.us.getCurrentUser();
    console.log('getMyClaimsWithCase User', user);
    if (!user) {
      return [];
    }
    return this.getClaimWithCaseForUserId(user.id!, limit, page);
  }

  async getAllClaimsForUserId(userId: string) {
    const query = this.supabase
      .from('cp_claim')
      .select('*,cp_claim_permission!inner(*)')
      .eq('cp_claim_permission.principal_id', userId);

    const { data, error } = await query;

    if (error) throw new Error(error.message);

    return data as unknown as Claim[];
  }

  async getClaimWithCaseForUserId(userId: string, limit: number, page: number) {
    const query = this.supabase
      .from('cp_claim')
      .select('*, cp_case (*),cp_claim_permission!inner(*)')
      .eq('cp_claim_permission.principal_id', userId)
      .range(page * limit, (page + 1) * limit - 1);
    const { data, error } = await query;

    if (error) throw new Error(error.message);

    return data as unknown as ClaimWithCase[];
  }

  async getUserForClaimId(claimId: string): Promise<any> {
    try {
      const { data, error } = await this.supabase
        .from('cp_claim')
        .select('*, cp_claim_permission!inner(principal_id)')
        .eq('id', claimId);

      if (error) {
        throw new Error(error.message);
      }

      const userIds = data.flatMap((claim) =>
        claim.cp_claim_permission.map((permission) => permission.principal_id)
      );

      const uniqueUserIds = [...new Set(userIds)].filter((id) => id !== null);

      // Fetch user details based on the userIds
      const { data: users, error: userError } = await this.supabase
        .from('cp_user') // Replace 'users' with your actual user table name
        .select('*')
        .in('id', uniqueUserIds);

      if (userError) {
        throw new Error(userError.message);
      }

      return {
        users: users as unknown as User[],
        claims: data as unknown as Claim[],
      };
    } catch (error) {
      console.error('Error fetching user for claim:', error);
      throw error;
    }
  }

  async getClaimWithCase(claimId: string) {
    const query = this.supabase
      .from('cp_claim')
      .select('*, cp_case (*)')
      .eq('id', claimId);
    const { data, error } = await query;

    if (error) throw new Error(error.message);

    return data[0] as unknown as ClaimWithCase;
  }

  async getRequirementsListForClaim(
    cwc: ClaimWithCase
  ): Promise<ClaimRequirement[]> {
    if (!cwc.cp_case.requirements) {
      return [];
    } else {
      const r: ClaimRequirement[] = [];

      for (let i = 0; i < cwc.cp_case.requirements.length; i++) {
        const element = cwc.cp_case.requirements[i];
        const reqStatus = await this.checkRequirementStatus(element, cwc);
        r.push({
          claimId: cwc.id,
          requirement: element,
          status: reqStatus.status,
          data: reqStatus.data,
        });
      }

      return r;
    }
  }

  async getRequirementsListForClaimV2(claim_id: string) {
    const ret = await this.supabase
      .from('cpv_actions_complete')
      .select('*')
      .eq('claim_id', claim_id)
      .filter('prstatus', 'not.is', null)
      .filter('prstatus', 'not.eq', 'IN_PROGRESS')
      .filter('type', 'neq', 'CLAIM_AGENT')
      .order('ac_status', { ascending: false });
    return ret.data as Action[] | null;
  }

  override async create(claim: Partial<Claim>) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.post(
        environment.lambdaUrl + '/create_claim',
        {
          case_id: claim.case_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    )) as any;

    return ret.message.claim;
  }

  private async checkRequirementStatus(
    r: Requirement,
    cwc: ClaimWithCase
  ): Promise<{
    status: ClaimRequirementStatus;
    data?: any;
  }> {
    switch (r.type) {
      case 'KYC':
        return { status: await this.checkPersonalDocumentRequirement(r, cwc) };

      case 'QUESTIONNAIRE':
        return { status: await this.checkQuestionnaireRequirement(r, cwc) };

      case 'SIGNATURE':
        return this.checkSignatureRequirement(r, cwc);

      default:
        return { status: ClaimRequirementStatus.UNMET };
    }

    // TODO: Implement
  }

  private async checkQuestionnaireRequirement(
    r: Requirement,
    cwc: ClaimWithCase
  ): Promise<ClaimRequirementStatus> {
    const qid = r.config.sid;

    const ur = await this.supabase
      .from('cp_questionnaire_answer')
      .select('status')
      .eq('claim_id', cwc.id)
      .eq('status', 'SUBMITTED');

    if (ur.data && ur.data.length > 0) {
      return ClaimRequirementStatus.MET;
    } else {
      return ClaimRequirementStatus.UNMET;
    }
  }

  private async checkSignatureRequirement(
    r: Requirement,
    cwc: ClaimWithCase
  ): Promise<{
    status: ClaimRequirementStatus;
    data?: { ref?: string; status?: string | null };
  }> {
    const qid = r.config.sid;

    const ur = await this.supabase
      .from('cp_signed_contract')
      .select('*')
      .eq('claim_id', cwc.id)
      .eq('contract_id', r.config.contract_id);

    if (ur.data && ur.data.length > 0) {
      return {
        status: ClaimRequirementStatus.MET,
        data: { ref: ur.data[0].id, status: ur.data[0].status },
      };
    } else {
      return { status: ClaimRequirementStatus.UNMET };
    }
  }

  private async checkPersonalDocumentRequirement(
    r: Requirement,
    cwc: ClaimWithCase
  ): Promise<ClaimRequirementStatus> {
    const ur = await this.supabase
      .from('cp_claim_permission')
      .select('principal_id')
      .eq('claim_id', cwc.id)
      .eq('permission_type', 'OWNER');

    if (!ur.data) {
      throw new Error(
        'Could not find matter`s owner, and KYC document is required.'
      );
    }

    const u = ur.data[0].principal_id!;

    const docsr = await this.supabase
      .from('cp_personal_document')
      .select('*')
      .eq('user_id', u)
      .eq('doc_type', r.config.document_title);

    console.log('docsr', docsr);

    if (!docsr.data || docsr.data.length == 0) {
      return ClaimRequirementStatus.UNMET;
    }

    const okdocs = docsr.data.map((d) => {
      if (
        d.status == 'UPLOADED' ||
        d.status == 'VALID' ||
        d.status == 'NO_VIRUS' ||
        d.status == 'CHECKING'
      )
        return true;
      else if (
        d.status == 'PRE_UPLOAD' ||
        d.status == 'REJECTED' ||
        d.status == 'VIRUS'
      ) {
        return false;
      } else {
        console.error('UNKNOWN DOCUMENT VALUE', d.status);
        return false;
      }
    });

    const some_ok = okdocs.reduce((p, curr) => p || curr, false);

    if (some_ok) {
      return ClaimRequirementStatus.MET;
    } else {
      return ClaimRequirementStatus.UNMET;
    }
  }

  async getWithDrawnClaimsRequest(user: string) {
    const { data, error } = await this.supabase
      .from('cp_withdrawal_requests')
      .select('id, status, request_date, cp_claim (id, cp_case (summary))')
      .eq('requester_id', user)
      .in('status', ['WAITING_ADMIN', 'REQUESTED']);

    if (error) {
      throw new Error(error.message);
    }

    return data as any[];
  }

  async withdrawClaim(claimId: string) {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.post(
        environment.lambdaUrl + '/withdraw',
        {
          claim_id: claimId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    )) as any;

    return ret.message.claim;
  }
  async manageWithdrawClaim(wc: string, status: 'APPROVE' | 'REJECT') {
    const token = (await this.supabase.auth.getSession()).data.session
      ?.access_token;

    const ret = (await firstValueFrom(
      this.http.post(
        environment.lambdaUrl + '/withdraw',
        {
          withdraw_id: wc,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    )) as any;

    return ret;
  }

  /**
   *
   * create table
  public.cp_claim_permission (
    id uuid not null default gen_random_uuid (),
    created_at timestamp with time zone null default now(),
    valid_from timestamp with time zone null,
    valid_until timestamp with time zone null,
    permission_type text null,
    principal_id uuid null,
    claim_id uuid null,
    constraint cp_claim_permission_pkey primary key (id),
    constraint cp_claim_permission_claim_id_fkey foreign key (claim_id) references cp_claim (id) on delete cascade,
    constraint cp_claim_permission_principal_id_fkey foreign key (principal_id) references cp_user (id) on delete cascade
  ) tablespace pg_default;
   */
  async getRepresentativesForClaim(claimId: string) {
    const query = this.supabase
      .from('cp_claim_permission')
      .select(
        'id, principal_id, permission_type, cp_user(id, display_name, cp_pii(id_document_value))'
      )
      .eq('claim_id', claimId);
    // .eq('permission_type', 'REPRESENTATIVE');
    const { data, error } = await query;

    if (error) throw new Error(error.message);

    return data as unknown as string[];
  }
}

export interface ClaimWithCase {
  id: string;
  name: string;
  status: string;
  nickname: string;
  slug: string;
  case_id: string;
  owner_client_id: string;
  is_private_access: boolean;
  description: string;
  created_at: '2023-04-12';
  updated_at: null;
  cp_case: Case;
}

export interface ClaimRequirement {
  requirement: Requirement;
  status: ClaimRequirementStatus;
  claimId: string;
  data?: any;
}

export enum ClaimRequirementStatus {
  'MET',
  'UNMET',
  'DEADLINE_MISSED',
}

export type CaseConfiguration = {
  images: {
    high_res: string;
  };
  requirements: [];
};
