<div class="container">
  <div class="header">
    <mat-icon>visibility</mat-icon>
    <p >{{'DocumentPreview' | translate}}</p>
  </div>
  <div class="uploaded">
    <p class="file-name">{{ imageData.name }}</p>
  </div>
  <div class="upload-box">
    <img [src]="imageData.src" class="image-preview" />
  </div>
  <div class="uploaded">
    <div class="action-btns">
      <button class="cancel" mat-button="" (click)="dialogRef.close(null)">
        {{'GoBack' | translate}}

      </button>
    </div>
  </div>
</div>
