<div class="dialog">
  <div class="title-container">
    <mat-icon class="icon-title" *ngIf="data.titleIcon">
      {{ data.titleIcon }}
    </mat-icon>
    <h3 class="title">{{ (data.title || "Are you sure?" )| translate }}</h3>
  </div>
  <div class="desc">
    {{ (data.message || "Are you sure you want to do this?") | translate }}
  </div>
  <div class="actions">
    <button
      class="def-button confirm-btn"
      color="primary"
      (click)="onConfirm()"
    >
      {{ (data.confirmText || "Yes") | translate }}
    </button>
    <button class="def-button cancel-btn" (click)="onDismiss()">
      {{ (data.cancelButton || "No") | translate }}
    </button>
  </div>
</div>
