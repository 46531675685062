<div style="display: none">
  <h1 i18n="@@CONCAT">CONCAT</h1>

  <mat-form-field>
    <input *ngIf="!isLongText" [(ngModel)]="question.answer!.value" [pattern]="this.regex" matInput="" [disabled]="
        autofillMode && (!autofillEditable || !question.answer!.value)
      " placeholder="{{'Insert here' | translate}}" [required]="!question.elective" (change)="change()" />

    <textarea *ngIf="isLongText" [(ngModel)]="question.answer!.value" (change)="change()" required="" matInput="" cdkTextareaAutosize="" placeholder="{{'Insert here' | translate}}" [pattern]="this.regex" #autosize="cdkTextareaAutosize" [required]="!question.elective" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
    <mat-error i18n="@@Invalid">Invalid</mat-error>
  </mat-form-field>
</div>
