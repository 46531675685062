<div class="news" #newsContainer="" [class.dark]="isDark" *ngIf="news">
  <div class="img-container">
    <img
      class="img"
      *ngIf="news.metadata!.picture"
      [src]="news.metadata!.picture"
      alt=""
    />
    <img
      class="img"
      *ngIf="!news.metadata!.picture && showNoImage"
      src="assets/img/no-image-news.svg"
      alt=""
    />
  </div>

  <div class="content">
    <div class="news-bullet" *ngIf="!isPreview">
      {{ news.metadata!.bullet || news.metadata!.bullet || "Author" }}
    </div>
    <div class="news-bullet" *ngIf="isPreview">
      {{ news.metadata!.bullet || news.metadata!.bullet || "Author" }}
    </div>

    <p class="title" *ngIf="!isPreview">{{ news.metadata!.title }}</p>
    <p class="title" *ngIf="isPreview">{{ news.metadata!.title || "Title" }}</p>

    <p class="news-date" *ngIf="!isPreview">
      <mat-icon>schedule</mat-icon>
      {{ news.created_at | date : "dd/MM/yyyy" }}
    </p>
    <p class="news-date" *ngIf="isPreview">
      <mat-icon>schedule</mat-icon>
      <ng-container *ngIf="news.valid_from">
        {{ news.valid_from | date : "dd/MM/yyyy" }}
      </ng-container>
      <ng-container *ngIf="!news.valid_from && news.created_at">
        {{ news.created_at | date : "dd/MM/yyyy" }}
      </ng-container>
      <ng-container *ngIf="!news.valid_from && !news.created_at">
        Date
      </ng-container>
    </p>

    <div
      class="preview"
      *ngIf="!isPreview"
      [innerHTML]="getFirstSentence(news.content!)"
    ></div>
    <div
      class="preview"
      *ngIf="isPreview"
      [innerHTML]="getFirstSentence(news.content!) || 'Content'"
    ></div>

    <button class="read-more-btn" (click)="readMore(news)" *ngIf="!isPreview">
      {{ "Read more" | translate }}
      <mat-icon>arrow_right</mat-icon>
    </button>
  </div>
</div>
