<div class="form-field">
  <label [for]="id">{{ label }}</label>
  <div class="input-content">
    <select [class.invalid]="!!error" [id]="id" [name]="id" (ngModelChange)="emitChange($event)" (focus)="emitFocus()" (focusout)="emitFocusOut()" [(ngModel)]="value" (change)="emitChange(value)">
      <option *ngFor="let option of options" [value]="option[optionConfig.value]">
        {{ option[optionConfig.label] }}
      </option>
    </select>
  </div>
</div>
