<form class="recovery" *ngIf="form" [formGroup]="form">
  <h6 class="title" >{{'Createyournewpassword' | translate}}</h6>
  <p class="desc" >
    {{'Createyournewpassword,includingacapitalletter.' | translate}}
  </p>

  <app-shared-form-input-text
    id="password"
    formControlName="password"
    
    label="{{'RecoveryNewPasswordNewPassword' | translate}}"
    placeholder="•••••••••••"
    name="password"
    [passwordInput]="true"
    [error]="getErrorMessage('password') | translate"
  ></app-shared-form-input-text>
  <app-shared-form-input-text
    id="confirmPassword"
    formControlName="confirmPassword"
    
    label="{{'RecoveryNewPasswordConfirmPassword' | translate}}"
    placeholder="•••••••••••"
    name="confirmPassword"
    [passwordInput]="true"
    [error]="getErrorMessage('confirmPassword') | translate"
  ></app-shared-form-input-text>

  <button
    class="submit-btn"
    [disabled]="isLoading || form.invalid"
    (click)="submit()"
  >
    <ng-container *ngIf="!isLoading"> {{'Create new password' | translate}} </ng-container>
    <ng-container *ngIf="isLoading">
      <mat-spinner diameter="20"></mat-spinner> {{'Loading...' | translate}}
    </ng-container>
  </button>
</form>
