<section class="detail-communication-component gap-container" *ngIf="thread">
  <div class="breadcrumb">
    <span class="bc-pointer" (click)="goToMessage()"
      ><b >{{'Message/' | translate}}</b></span
    >
    <span class="bc-pointer" (click)="goToMessageWithClaim()">
      <b>{{ thread?.cp_claim?.cp_case?.code }} / </b>
    </span>
    <span class="atual-chat">{{ thread?.subject }}</span>
  </div>

  <div class="divider"></div>

  <div class="content">
    <div class="list-item" *ngIf="thread">
      <div class="content">
        <div class="start">
          <mat-icon>
            {{
              thread.medium === "CHAT"
                ? "chat"
                : thread.medium === "INTERNAL_MESSAGE"
                ? "chat"
                : thread.medium === "EMAIL"
                ? "mail_outline"
                : "chat"
            }}
          </mat-icon>
        </div>
        <div class="middle">
          <p class="subject">{{ thread.subject }}</p>
          <p class="message">
            {{ thread.cp_claim?.cp_case?.code ?? "-" }}
          </p>

          <div [ngSwitch]="thread.medium" class="details-desktop">
            <div *ngSwitchCase="'EMAIL'">
              <app-communication-email
                [thread]="thread"
                [user]="user"
              ></app-communication-email>
            </div>
            <div *ngSwitchCase="'INTERNAL_MESSAGE' || 'CHAT'">
              <app-communication-chat
                [thread]="thread"
                [user]="user"
              ></app-communication-chat>
            </div>
            <div *ngSwitchDefault="">
              <app-communication-chat
                [thread]="thread"
                [user]="user"
              ></app-communication-chat>
            </div>
          </div>
        </div>
        <div class="end">
          <span class="date">
            {{
              getNewestMessageFromThread(thread)!.created_at
                | date : "dd/MM/yyyy"
            }}
          </span>
        </div>
      </div>
      <div [ngSwitch]="thread.medium" class="details-mobile">
        <div *ngSwitchCase="'EMAIL'">
          <app-communication-email
            [thread]="thread"
            [user]="user"
          ></app-communication-email>
        </div>
        <div *ngSwitchCase="'INTERNAL_MESSAGE' || 'CHAT'">
          <app-communication-chat
            [thread]="thread"
            [user]="user"
          ></app-communication-chat>
        </div>
        <div *ngSwitchDefault="">
          <app-communication-chat
            [thread]="thread"
            [user]="user"
          ></app-communication-chat>
        </div>
      </div>
    </div>
  </div>
</section>
