<div class="more-news">
  <h3 class="other-title">{{ "OtherNews" | translate }}</h3>
  <img class="img" src="assets/img/other-news.svg" alt="" />

  <div class="bullet">{{ "MishcondeReya" | translate }}</div>
  <h3 class="other-subtitle">
    {{ "Wewillsoonbemakingmorefreshnewsavailabletoyou" | translate }}
  </h3>

  <ul class="list">
    <li class="item" *ngFor="let item of otherNews" (click)="goToNews(item)">
      <p class="other-item-title">{{ item.metadata.title }}</p>
      <button class="go-btn">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </li>
  </ul>
</div>
