<div
  class="item"
  routerLink="/client/claim/{{ action.claim_id }}/q/{{
    action.questionnaire_id
  }}"
>
  <button
    class="label"
    [disabled]="checkDependsOn() || action.ac_status === 'SUBMITTED'"
  >
    <img
      class="icon"
      src="../../../../../../assets/icons/questionnaire.png"
      alt=""
    />
    <span class="oa-title"
      >{{ action.action_name }}
      <img
        *ngIf="action.ac_status !== 'SUBMITTED'"
        src="../../../../../../../../assets/icons/Tag_to_do.svg"
        alt=""
      />
    </span>
  </button>

  <div *ngIf="checkDependsOn()" class="actions">
    <mat-icon class="info" [matTooltip]="action.description || ''"
      >info</mat-icon
    >
  </div>
  <div
    *ngIf="!checkDependsOn() && action.ac_status === 'SUBMITTED'"
    class="actions"
  >
    <mat-icon class="success">done</mat-icon>

    <button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="menu" xPosition="before">
      <button mat-menu-item="">
        <span>{{ "DownloadDocument" | translate }} </span>
      </button>
      <button mat-menu-item="">
        <span>{{ "RetractSignature" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
