<section class="documents">
  <app-title-large >{{'' | translate}}</app-title-large>
  <p class="description">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed consequat.
  </p>

  <div class="documents-list mobile">
    <div class="document" *ngFor="let item of documents">
      <ng-container *ngIf="!!item.file">
        <div class="info">
          <p class="doc-title">{{ item.name }}</p>
          <p class="doc-name">{{ item.file.name }}</p>
          <p class="doc-sent" >{{'' | translate}}</p>
        </div>
        <div class="actions">
          <button
            mat-icon-button=""
            class="eye-btn"
            (click)="openDocumentPreview(item.id)"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <button
            mat-icon-button=""
            class="trash-btn"
            (click)="openDeleteDocumentDialog(item.id)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!item.file">
        <div class="empty-container">
          <div class="doc-title">{{ item.name }}</div>
          <p class="doc-empty" >{{'' | translate}}</p>
          <button class="upload-btn" (click)="openUploadPhoto(item.id)">
            {{'Upload' | translate}}
            <mat-icon>file_upload</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="documents-list desktop">
    <table class="documents-table">
      <thead>
        <tr>
          <th >{{'' | translate}}</th>
          <th >{{'' | translate}}</th>
          <th >{{'' | translate}}</th>
          <th >{{'' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of documents">
          <td>{{ item.name }}</td>
          <td>{{ item.file?.name || "" }}</td>
          <td>
            <span
              class="chip"
              [class.sent]="!!item.file"
              [class.empty]="!item.file"
            >
              {{ item.file ? "Document Sent" : "Empty" }}
            </span>
          </td>
          <td>
            <ng-container *ngIf="item.file">
              <button
                mat-icon-button=""
                class="eye-btn"
                (click)="openDocumentPreview(item.id)"
              >
                <mat-icon>visibility</mat-icon>
              </button>
              <button
                mat-icon-button=""
                class="trash-btn"
                (click)="openDeleteDocumentDialog(item.id)"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="!item.file">
              <button class="upload-btn" (click)="openUploadPhoto(item.id)">
                {{'Upload' | translate}}
                <mat-icon>file_upload</mat-icon>
              </button>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
