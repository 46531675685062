<mat-dialog-content class="mat-typography">
  <div class="modal-container">
    <h3 i18n="@@PasswordReset">Password Reset</h3>
    <p i18n="@@Pleaseentertheemailyouregisteredwithandwewillhelpyouresetyourpassword.">
      Please enter the email you registered with and we will help you reset your
      password.
    </p>
    <form style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      ">
      <mat-form-field floatLabel="always" class="example-full-width">
        <input [formControl]="email" matInput="" placeholder="Emaill" value="" />
        <mat-error i18n="@@Pleasetypeavalidemail.">Please type a valid email.</mat-error>
      </mat-form-field>

      <button style="margin: 10px 0px" (click)="goToResetPage()" class="send">
        Send Code
      </button>
      <button mat-dialog-close="" class="btn-clear-sm" style="color: var(--color-tertiary-40)">
        Cancel
      </button>
    </form>
  </div>
</mat-dialog-content>
