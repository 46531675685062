<div class="avaliable-list-case">
  <header>
    <app-input-dropdown id="tag-filter" [title]="'Filter by categories' | translate" [matMenuTriggerFor]="menu">
      <mat-menu #menu="matMenu" classList="drop-btn-menu">
        <button mat-menu-item="" [id]="itemMenu" (click)="onClickItemMenu($event.currentTarget)" *ngFor="let itemMenu of itemsMenu" id="">
          {{ itemMenu | translate }}
        </button>
      </mat-menu>
    </app-input-dropdown>
  </header>

  <section class="list-case">
    <ng-container *ngFor="let case of casesToShow">
      <app-block-case [case]="case"></app-block-case>
    </ng-container>
  </section>
</div>
