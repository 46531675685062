<form [formGroup]="addressForm">
  <mat-form-field>
    <input matInput="" placeholder="{{'Search...'| translate}}" formControlName="address" [required]="!question.elective" [matAutocomplete]="auto" (keyup)="doSearch()" />

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngIf="loading; else loaded" style="pointer-events: none">
        <div class="spinner-container">
          <mat-spinner [mode]="'indeterminate'"> </mat-spinner>
          {{"searching..."| translate}}
        </div>
      </mat-option>

      <ng-template #loaded="">
        <mat-option *ngIf="!addressList.length; else hasAddress" disabled="" i18n="@@Noresult">{{'No result'| translate}}</mat-option>
        <ng-template #hasAddress="">
          <mat-option *ngFor="let address of addressList" [value]="address.description" style="line-height: 19px" (click)="selectAddress(address)">
            {{ address.description }}
          </mat-option>
        </ng-template>
      </ng-template>
    </mat-autocomplete>

    <mat-error *ngIf="addressForm.invalid && addressForm.dirty" i18n="@@Selectanaddress">Select an address</mat-error>
  </mat-form-field>

  <p class="custom-error" *ngIf="
      addressForm.controls.address.dirty &&
      addressForm.controls.addtional_information.invalid
    " i18n="@@Selectanaddress">
    {{"Select an address"| translate}}
  </p>
</form>

<div class="extra" *ngIf="showAdd">
  <mat-form-field>
    <mat-label i18n="@@Streetnumber">{{"Street number"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.streetNumber" placeholder="Street number" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Streetname">{{"Street name"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.streetName" placeholder="Street name" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@City">{{"City"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.city" placeholder="City" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Neighborhood">{{"Neighborhood"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.neighborhood" placeholder="Neighborhood" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@State">{{"State"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.state" placeholder="State" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@County">{{"County"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.county" placeholder="County" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Country">{{"Country"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.country" placeholder="Country" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Postalcode">{{"Postal code"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.postalCode" placeholder="Postal code" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@BuildingName">{{"Building Name"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.buildingName" placeholder="Building Name" value="value" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Unit/ApartmentNumber">{{"Unit / Apartment Number"|translate}}</mat-label>
    <input matInput="" [(ngModel)]="additional_info.unitNumber" placeholder="Unit/Apartment Number" value="value" />
  </mat-form-field>
</div>
