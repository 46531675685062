<form class="recovery" *ngIf="form" [formGroup]="form">
  <h6 class="title" i18n="@@ForgotPassword">{{'ForgotPassword'| translate}}</h6>
  <p class="desc" i18n="@@Enteryourregisteredemailtoreceivetheaccesscode.">
    {{'Enteryourregisteredemailtoreceivetheaccesscode.' | translate}}
  </p>

  <app-shared-form-input-text
    formControlName="email"
    label="{{'RecoveryEmailEmailaddress' | translate}}"
    
    placeholder="{{'RecoveryEmailEmailaddress' | translate}}"
    name="email"
    type="email"
  ></app-shared-form-input-text>

  <button
    class="submit-btn"
    [disabled]="
      isLoading ||
      form.invalid ||
      (!sendCodePermission && lastSendTime !== null)
    "
    (click)="submit()"
  >
    <ng-container
      *ngIf="!sendCodePermission && lastSendTime !== null; else openToSend"
    >
      Wait {{ getDiffLastTimeAndNowInSec() }}...
    </ng-container>
    <ng-template #openToSend="">
      <ng-container *ngIf="!isLoading"> {{'Send Code'| translate}} </ng-container>
      <ng-container *ngIf="isLoading">
        <mat-spinner diameter="20"></mat-spinner> {{'Loading...' | translate}}
      </ng-container>
    </ng-template>
  </button>
</form>
