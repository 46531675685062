<div class="row">
  <h4 class="font-helvetica" i18n="@@List">List</h4>
</div>

<div class="row">
  <div class="col-6">
    <div class="card">
      <div class="card-title" i18n="@@List">List</div>
      <div class="card-body">
        <table class="table" id="table_1">
          <tr>
            <td><i class="ic-sm ic-alert-yellow-rd"></i></td>
            <td><h6 i18n="@@RicardoPereira">Ricardo Pereira</h6></td>
            <td>
              <h6 i18n="@@ricardopereira@hotmail.com">
                ricardopereira@hotmail.com
              </h6>
            </td>
            <td><h6 i18n="@@Lawyer">Lawyer</h6></td>
            <td><span i18n="@@Requested">Requested</span></td>
          </tr>
          <tr>
            <td><i class="ic-sm ic-alert-yellow-rd"></i></td>
            <td><h6 i18n="@@RonaldRichards">Ronald Richards</h6></td>
            <td>
              <h6 i18n="@@ronaldrichards@hotmail.com">
                ronaldrichards@hotmail.com
              </h6>
            </td>
            <td><h6 i18n="@@Lawyer">Lawyer</h6></td>
            <td><ng-container *ngTemplateOutlet="actions"></ng-container></td>
          </tr>
          <tr>
            <td><i class="ic-sm ic-alert-yellow-rd"></i></td>
            <td><h6 i18n="@@CameronWilliamson">Cameron Williamson</h6></td>
            <td>
              <h6 i18n="@@cameronwilliamson@hotmail.com">
                cameronwilliamson@hotmail.com
              </h6>
            </td>
            <td><h6 i18n="@@Lawyer">Lawyer</h6></td>
            <td><ng-container *ngTemplateOutlet="actions"></ng-container></td>
          </tr>
          <tr>
            <td><i class="ic-sm ic-alert-yellow-rd"></i></td>
            <td><h6 i18n="@@EleanorPena">Eleanor Pena</h6></td>
            <td>
              <h6 i18n="@@eleanorpena@hotmail.com">eleanorpena@hotmail.com</h6>
            </td>
            <td><h6 i18n="@@Lawyer">Lawyer</h6></td>
            <td><ng-container *ngTemplateOutlet="actions"></ng-container></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="div">
  <div class="col-4">
    <div class="card">
      <div class="card-title" i18n="@@List">List</div>
      <div class="card-body">
        <table class="table" id="table_2">
          <tr>
            <th i18n="@@Name">Name</th>
            <th i18n="@@CPF">CPF</th>
            <th i18n="@@RG">RG</th>
            <th i18n="@@Powerofattorney">Power of attorney</th>
            <th i18n="@@Reengagement">Reengagement</th>
            <th i18n="@@Questionnaire">Questionnaire</th>
            <th i18n="@@More">More</th>
          </tr>
          <tr>
            <td i18n="@@JaneCooper">Jane Cooper</td>
            <td i18n="@@101.122.123-12">101.122.123-12</td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-gray-rd"></i></td>
            <td><i class="ic-sm ic-3-point"></i></td>
          </tr>
          <tr>
            <td i18n="@@AparecidaSilvaGomes">Aparecida Silva Gomes</td>
            <td i18n="@@101.122.123-12">101.122.123-12</td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-gray-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-3-point"></i></td>
          </tr>
          <tr>
            <td i18n="@@JaneCooper">Jane Cooper</td>
            <td i18n="@@101.122.123-12">101.122.123-12</td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-gray-rd"></i></td>
            <td><i class="ic-sm ic-3-point"></i></td>
          </tr>
          <tr>
            <td i18n="@@AparecidaSilvaGomes">Aparecida Silva Gomes</td>
            <td i18n="@@101.122.123-12">101.122.123-12</td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-gray-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-3-point"></i></td>
          </tr>
          <tr>
            <td i18n="@@JaneCooper">Jane Cooper</td>
            <td i18n="@@101.122.123-12">101.122.123-12</td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-gray-rd"></i></td>
            <td><i class="ic-sm ic-3-point"></i></td>
          </tr>
          <tr>
            <td i18n="@@AparecidaSilvaGomes">Aparecida Silva Gomes</td>
            <td i18n="@@101.122.123-12">101.122.123-12</td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-gray-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-confirm-green-rd"></i></td>
            <td><i class="ic-sm ic-3-point"></i></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #actions="">
  <div class="flex">
    <i class="ic-sm ic-cancel-red-rd"></i>
    <i class="ic-sm ic-confirm-green-rd"></i>
  </div>
</ng-template>
