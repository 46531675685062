<div class="card presentation-card">
  <div class="card-body">
    <button (click)="emitChangeMode()" id="edit" mat-button="" class="btn-icon">
      <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
    </button>
    <div class="card-section">
      <div class="content-img">
        <img class="img-circle" [src]="user.avatar_url" alt="" />
      </div>
      <div class="content">
        <span id="username">{{ user.display_name }}</span>
        <ul id="list_1" class="list">
          <li class="list-item">
            <mat-icon>mail_outlined</mat-icon>
            <span class="text_body_1">{{ user.primary_email }}</span>
          </li>
          <li class="list-item">
            <mat-icon fontSet="material-icons-outlined">call_outlined</mat-icon>
            <span class="body_1" 
              >+55 31 94544-1221</span
            >
          </li>
          <li class="list-item">
            <mat-icon fontSet="material-icons-outlined">location_on</mat-icon>
            <span class="body_1" 
              >123 Test Street, Little Rock, AZ</span
            >
          </li>
          <li class="list-item">
            <mat-icon>newspaper</mat-icon>
            <span class="body_1">{{ "12/12/1985" }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
