<mat-toolbar [ngClass]="{ 'sidenav-open': isSidenavOpen }">
  <header class="gap-container full">
    <a class="content-left" [routerLink]="['/client/home']">
      <img src="assets/img/mdr-Logo.svg" alt="Mishcon de Reya" />
    </a>
    <app-search-input></app-search-input>
    <div class="content-right">
      <button
        mat-button=""
        id="notification"
        class="btn-notification"
        aria-label="Notifications"
        [matMenuTriggerFor]="menu"
      >
        <div class="circle-icon">
          <span
            *ngIf="notifications && notifications.length > 0"
            class="circle-topic"
          ></span>
          <img src="assets/icons/bell.svg" alt="Notifications" />
        </div>
      </button>
      <a [routerLink]="['/client/profile']" class="btn-profile">
        <div class="profile-avatar">
          <span class="profile-name" *ngIf="!profileImg">
            {{ profileName }}
          </span>
          <img
            *ngIf="profileImg"
            class="img-circle"
            [src]="profileImg"
            alt="Profile"
          />
        </div>
      </a>
    </div>
    <app-button-toggle-menu
      [isSideNavOpen]="isSidenavOpen"
      (click)="toggleSideNav()"
      color="#1A1E1F"
    >
    </app-button-toggle-menu>
  </header>
</mat-toolbar>

<mat-menu #menu="matMenu" class="men">
  <button
    mat-menu-item=""
    *ngFor="let note of notifications"
    routerLink="/client/messages/chat/{{ note.id }}"
    [class.highlight]="
      note.cp_message &&
      note.cp_message![note.cp_message!.length - 1].user_reads &&
      note.cp_message![note.cp_message!.length - 1].user_reads!.length === 0
    "
  >
    <div class="btn-content">
      <div class="icont">
        <span
          *ngIf="
            note.cp_message &&
            note.cp_message![note.cp_message!.length - 1].user_reads &&
            note.cp_message![note.cp_message!.length - 1].user_reads!.length ===
              0
          "
          class="circle-topic"
        ></span>
        <mat-icon>chat_bubble</mat-icon>
      </div>
      <div class="text">
        <h6>{{ note.subject }}</h6>
        <p *ngIf="!!note.cp_message">
          {{ note.cp_message![note.cp_message!.length - 1].value }}
        </p>
      </div>
    </div>
    <span></span>
  </button>
  <button mat-menu-item="" *ngIf="notifications?.length == 0">
    All set. You have no new notifications.
  </button>
</mat-menu>
