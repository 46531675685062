export const environment: any = {
  production: false,
  projectUrl: 'https://dev.somosclientportal.com/',
  supabaseUrl: 'https://api.dev.somosclientportal.com/',
  supabaseKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzA3ODc5NjAwLAogICJleHAiOiAxODY1NzMyNDAwCn0.eBJ1-lacY2gAoMaMEcXhlxSUwc6e9ONJwUG7kJb676M',
  lambdaUrl: 'https://lambda.dev.somosclientportal.com',
};


// export const environment: any = {
//   production: false,
//   projectUrl: 'https://portal.portalirpa.com.br/',
//   supabaseUrl: 'https://api.prod.p1.somosclientportal.com/',
//   supabaseKey:
//     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjogImFub24iLCJpc3MiOiAic3VwYWJhc2UiLCJpYXQiOiAxNzM5MjI0MTMzLCJleHAiOiAyMDU0NzU2OTMzfQ.ucoCcXOoQL9bLVBUmWo08qFpvyg0BkjfBVzUigP_vOQ',
//   lambdaUrl: 'https://lambda.prod.p1.somosclientportal.com/',
// };


