<span class="example-list-section">
  <mat-checkbox *ngIf="options.length < 1" [(ngModel)]="question.answer!.value" (change)="change()" color="primary" i18n="@@Yes">
    Yes
  </mat-checkbox>

  <ul *ngIf="options.length > 0">
    <li *ngFor="let option of options">
      <mat-checkbox [(ngModel)]="question.answer!.value" (change)="change()" color="primary">
        {{ option }}
      </mat-checkbox>
    </li>
  </ul>
</span>
