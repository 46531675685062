<div class="row">
  <h4 class="font-helvetica">Grid</h4>
</div>
<div class="row">
  <div class="col-8 col-md-4 col-sm-2">
    <div class="card">
      <div class="card-title">col-8 col-md-4 col-sm-2</div>
      <div class="card-box"></div>
    </div>
  </div>
  <div class="col-8 col-md-4 col-sm-2">
    <div class="card">
      <div class="card-title">col-8 col-md-4 col-sm-2</div>
      <div class="card-box"></div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-4 col-sm-8">
    <div class="card">
      <div class="card-title">col-4 col-sm-8</div>
      <div class="card-box"></div>
    </div>
  </div>
  <div class="col-4 col-sm-8">
    <div class="card">
      <div class="card-title">col-4 col-sm-8</div>
      <div class="card-box"></div>
    </div>
  </div>
</div>
