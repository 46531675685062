import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormInput } from '../_form-input';

import { v4 as uuid } from 'uuid';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-shared-form-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent implements FormInput, ControlValueAccessor {
  @Input() value: any;
  @Input() id: string = uuid();
  @Input() name: string = 'id';
  @Input() label: string = 'Input';
  @Input() placeholder?: string;

  @Input() error: string = '';

  @Input() type: string = 'text';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Input() passwordInput: boolean = false;

  @Input() autocompleteAnchor?: MatAutocomplete;

  visibleValue: boolean = false;

  @Output() onChange$: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus$: EventEmitter<void> = new EventEmitter<void>();
  @Output() focusOut$: EventEmitter<void> = new EventEmitter<void>();

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  emitChange(value: any): void {
    this.value = value;
    this.onChange(value);
    this.onChange$.emit(value);
  }

  emitFocus(): void {
    this.onTouched();
    this.onFocus$.emit();
  }

  emitFocusOut(): void {
    this.focusOut$.emit();
  }
}
