<div class="question-container">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let section of sections; let sectionIndex = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>{{ question.configuration.prefixSection }} &nbsp;{{
              sectionIndex + 1
            }}</span>
          <div class="delete" color="warn" (click)="removeSection($event, sectionIndex)">
            <mat-icon i18n="@@delete">delete</mat-icon>
            Delete {{ question.configuration.prefixSection ?? "Item" }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngFor="let question of section.questions; let questionIndex = index">
        <app-question *ngIf="!section.hideMap[questionIndex]" [question]="question" (changeAnswer)="onChildQuestionsChangeAnswer(sectionIndex, $event)"></app-question>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>

  <button mat-raised-button="" (click)="addNewSection()">
    Add {{ question.configuration.sectionName ?? "Item" }}
  </button>
</div>
