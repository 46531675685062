<div class="row">
  <h4 i18n="@@Block">Block</h4>
</div>
<div class="row">
  <div class="col-4">
    <section>
      <div class="block-lg">
        <img width="100%" class="img-rectangle-lg" src="https://s2.glbimg.com/YbyUBmqvCCsiHFTDay8TNrpYd-k=/0x0:3861x2574/924x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2021/E/a/mpE9ldQjaGJchsXsTNeg/02-pinheiro-2021.jpg" alt="" />
        <h4 i18n="@@BraskemseeshighercostsfromAlagoassaltminedamage">Braskem sees higher costs from Alagoas salt mine damage</h4>
        <p i18n="@@PetrochemicalproducerBraskemexpectsanadditionalReal3.3billion($622million)incoststoimplementcompensationandrelocationmeasuresstemmingfromgeologicaldamagefromitsformersaltminingoperationintheBrazilianstateofAlagoas,thecompanysaidSept.15inanoticetoinvestors.">
          Petrochemical producer Braskem expects an additional Real 3.3 billion
          ($622 million) in costs to implement compensation and relocation
          measures stemming from geological damage from its former salt mining
          operation in the Brazilian state of Alagoas, the company said Sept. 15
          in a notice to investors.
        </p>
        <p i18n="@@ThatamountincludesReal300million($56.5million)foranadditional800propertiestobeadded&quot;asacautionarymeasure&quot;totheBraziliancompany'scompensationandrelocationsupportprogram.TheReal3.3billionalso&quot;isinadditiontoamountspreviouslyprovisionedbythecompany,&quot;Braskemsaid.">
          That amount includes Real 300 million ($56.5 million) for an
          additional 800 properties to be added "as a cautionary measure" to the
          Brazilian company's compensation and relocation support program. The
          Real 3.3 billion also "is in addition to amounts previously
          provisioned by the company," Braskem said.
        </p>
        <p i18n="@@Theissuestemsfromfissures,cracksandamildearthquakeinAlagoas'capitalcity,Maceio,thatledBrazil'sGeologicalSurveyinMay2019toissueareportlinkingBraskem'ssaltminingoperationwiththegroundmovement.Thatoperationprovidedsalttofeedthecompany'schlor-alkaliplantinMaceio.">
          The issue stems from fissures, cracks and a mild earthquake in
          Alagoas' capital city, Maceio, that led Brazil's Geological Survey in
          May 2019 to issue a report linking Braskem's salt mining operation
          with the ground movement. That operation provided salt to feed the
          company's chlor-alkali plant in Maceio.
        </p>
      </div>
    </section>
  </div>
  <div class="col-2">
    <section>
      <div class="block-sm">
        <img width="100%" class="img-rectangle-sm" src="https://s2.glbimg.com/YbyUBmqvCCsiHFTDay8TNrpYd-k=/0x0:3861x2574/924x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_59edd422c0c84a879bd37670ae4f538a/internal_photos/bs/2021/E/a/mpE9ldQjaGJchsXsTNeg/02-pinheiro-2021.jpg" alt="" />
        <h5 i18n="@@SoilsinkinginMaceiócausedbytheBraskemcompany">Soil sinking in Maceió caused by the Braskem company</h5>
        <p i18n="@@Braskemisapetrochemicalcompanythat,fordecades,extractedrocksaltinaregionwherethereweregeologicalfaultsinthecityofMaceió.Thisresultedinthegroundsinking,causingcracksinhousesandbuildings.Thousandsofpeoplehadtoleavetheirhomesortheirestablishmentsduetotheriskthatthebuildingspresented.Butwhenitcametomakingproposalsforindemnities,thevaluesproposedbythecompanywerelowerthanthedamagecaused.">
          Braskem is a petrochemical company that, for decades, extracted rock
          salt in a region where there were geological faults in the city of
          Maceió. This resulted in the ground sinking, causing cracks in houses
          and buildings. Thousands of people had to leave their homes or their
          establishments due to the risk that the buildings presented. But when
          it came to making proposals for indemnities, the values proposed by
          the company were lower than the damage caused.
        </p>
      </div>
    </section>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <section>
      <div class="news">
        <div class="title">
          <i class="ic-sm ic-content"></i>
          <h6 i18n="@@News">News</h6>
        </div>
        <div class="container">
          <div class="content">
            <span class="title" i18n="@@Brumadinhominingdisaster">Brumadinho mining disaster</span>
            <p class="special-button" i18n="@@BrazilianmininggiantValehassofarpaidnearly20bnreais(US$3.66bn)incompensationtocoverthedamagecausedbythetailingsdamcollapseinMinasGeraisstate’sBrumadinhomunicipality.">
              Brazilian mining giant Vale has so far paid nearly 20bn reais
              (US$3.66bn) in compensation to cover the damage caused by the
              tailings dam collapse in Minas Gerais state’s Brumadinho
              municipality.
            </p>
          </div>
          <div class="content">
            <span class="title" i18n="@@BraskemseeshighercostsfromAlagoassaltminedamage">Braskem sees higher costs from Alagoas salt mine damage</span>
            <p class="special-button" i18n="@@PetrochemicalproducerBraskemexpectsanadditionalReal3.3billion($622million)incoststoimplementcompensationandrelocationmeasuresstemmingfromgeologicaldamagefromitsformersaltminingoperationintheBrazilianstateofAlagoas,thecompanysaidSept.15inanoticetoinvestors.">
              Petrochemical producer Braskem expects an additional Real 3.3
              billion ($622 million) in costs to implement compensation and
              relocation measures stemming from geological damage from its
              former salt mining operation in the Brazilian state of Alagoas,
              the company said Sept. 15 in a notice to investors.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="col-2">
    <div class="not-found">
      <p class="title" i18n="@@Noresultswerefoundfor:clim">No results were found for: clim</p>
      <span i18n="@@Youcantry:">You can try:</span>
      <ul>
        <li i18n="@@Checkspelling">Check spelling</li>
        <li i18n="@@Searcharelatedterm">Search a related term</li>
        <li i18n="@@Searchwithfewerwords">Search with fewer words</li>
      </ul>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-4">
    <section>
      <div class="block-rectangle">
        <div style="position: relative">
          <span id="badge_1" class="badge badge-priority-4" i18n="@@ENVIROMENTAL">ENVIROMENTAL</span>
          <img height="100%" class="img-rectangle-sm" src="https://www.cnnbrasil.com.br/wp-content/uploads/2021/06/23697_26CE20AF2B7B83A2.jpeg" alt="" />
        </div>
        <div class="content">
          <h6 i18n="@@Brumadinhominingdisaster">Brumadinho mining disaster</h6>
          <p i18n="@@BrazilianmininggiantValehassofarpaidnearly20bnreais(US$3.66bn)incompensationtocoverthedamagecausedbythetailingsdamcollapseinMinasGeraisstate’sBrumadinhomunicipality.">
            Brazilian mining giant Vale has so far paid nearly 20bn reais
            (US$3.66bn) in compensation to cover the damage caused by the
            tailings dam collapse in Minas Gerais state’s Brumadinho
            municipality.
          </p>
          <div id="div_1" class="flex align-itens-center justify-content-space-between">
            <div class="flex align-itens-center">
              <i id="icon_1" class="ic-x-sm ic-clock-gray"></i>
              <span id="span_1" i18n="@@5Dec,2020"> 5 Dec, 2020</span>
            </div>
            <button class="btn-md">READ</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
