<ul class="list">
  <li class="list-item">
    <mat-icon class="icon-confirm"
      >check_circle_outline</mat-icon
    >
    <div style="display: inline-grid; margin-left: 13px">
      <span>
        <b >{{'JoinDate' | translate}}</b> {{ claim?.created_at | date }}
      </span>
    </div>
  </li>
  <li class="list-item">
    <mat-icon fontSet="material-icons-outlined">group</mat-icon>
    <div style="display: inline-grid; margin-left: 13px">
      <!--<mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon> -->
    </div>
  </li>
  <!-- <li class="list-item">
    <mat-icon>gavel</mat-icon>
    <div style="display: inline-grid; margin-left: 13px">
      <span> <b>Court:</b> {{ claim?.cp_case?.lead_lawyer }} </span>
    </div>
  </li> -->
</ul>
