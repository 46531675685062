<section id="login">
  <div class="left-image">
    <img src="/assets/img/mdr-Logo.svg" alt="" class="logo-left" />
    <img src="/assets/img/login-left-img.svg" alt="" class="img-left" />
  </div>
  <div class="login-container">
    <form class="login-form" [formGroup]="form" *ngIf="form">
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <h5 class="title">{{ "introductionHeader" | translate }}</h5>
      <div class="form-group-login">
        <app-shared-form-input-text
          formControlName="email"
          type="email"
          name="email"
          required=""
          email=""
          label="{{ 'Email' | translate }}"
        ></app-shared-form-input-text>
        <app-shared-form-input-text
          formControlName="password"
          type="password"
          [passwordInput]="true"
          required=""
          name="password"
          label="{{ 'Password' | translate }}"
        ></app-shared-form-input-text>
        <app-shared-form-input-text
          formControlName="supervisorEmail"
          type="supervisorEmail"
          name="supervisorEmail"
          required=""
          supervisorEmail=""
          label="{{ 'Supervisor Email' | translate }}"
        ></app-shared-form-input-text>
        <app-shared-form-input-text
          formControlName="supervisorPassword"
          type="password"
          [passwordInput]="true"
          required=""
          name="supervisorPassword"
          label="{{ 'supervisorPassword' | translate }}"
        ></app-shared-form-input-text>

        <button
          class="submit-btn"
          type="submit"
          [disabled]="isLoading || form.invalid"
          (click)="onSubmit()"
        >
          <ng-container *ngIf="!isLoading">{{
            "loginLoginBtn" | translate
          }}</ng-container>
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </ng-container>
        </button>
      </div>
    </form>
  </div>
</section>
