<div class="condition">
  <div class="conditions-header">
    <span class="title" i18n="@@Text"> Text </span>
    <div class="select" (click)="$event.stopPropagation()">
      <mat-select
        appearance="outline"
        placeholder="Operator"
        [(ngModel)]="operator"
        name="item"
        class="operator"
      >
        <mat-option *ngFor="let item of operators" [value]="item.value">
          <span [innerHTML]="item.label"></span>
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="conditions-clear" (click)="$event.stopPropagation()">
    <mat-icon
      (click)="clearFilter()"
      class="clear"
      svgIcon="custom_filter_clear"
      >clear</mat-icon
    >
  </div>
</div>

<div class="form-field" (click)="$event.stopPropagation()">
  <input type="number" [(ngModel)]="text" />
</div>

<div class="apply" (click)="$event.stopPropagation()">
  <button class="apply-btn" (click)="addFilter()">Apply</button>
</div>
<div class="filters" (click)="$event.stopPropagation()">
  <div class="filter-item" *ngFor="let item of filters; let i = index">
    <span [innerHTML]="getOperatorLabel(item.operator)"></span> {{ item.value }}
    <mat-icon (click)="removeFilter(i)">close</mat-icon>
  </div>
</div>
