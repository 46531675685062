<div class="container">
  <button mat-icon-button="" class="close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <div class="header">
    <mat-icon class="title-icon">celebration</mat-icon>
    <h5 class="title" >{{'Success' | translate}}</h5>
  </div>
  <p class="text" >{{'Changesavedsuccessfully' | translate}}</p>
  <button class="btn-close" (click)="dialogRef.close()">Close</button>
</div>
