<section id="verify-doc" *ngIf="!!data && !showNotAuthorized">
  <div class="header">
    <img src="/assets/img/mdr-Logo.svg" alt="Logo" />
    <div class="divider"></div>
  </div>
  <div class="main">
    <div class="info-signature">
      <h3 class="title-info">{{ data.contract.name }}</h3>

      <div class="list-info">
        <div class="info-item">
          <p class="info-label" i18n="@@Username:">User name:</p>
          <p class="info-value">{{ data.signer.cp_pii.legal_name }}</p>
        </div>
        <div class="info-item">
          <p class="info-label" i18n="@@UserIP:">User IP:</p>
          <p class="info-value">
            {{ data.signature_data.qrcode_info.ip ?? "-" }}
          </p>
        </div>
        <div class="info-item">
          <p class="info-label" i18n="@@ClaimNumber:">Claim Number:</p>
          <p class="info-value">{{ data.claim.id ?? "-" }}</p>
        </div>
        <div class="info-item">
          <p class="info-label" i18n="@@Casename:">Case name:</p>
          <p class="info-value">{{ data.claim.case.summary }}</p>
        </div>
        <!-- <div class="info-item">
          <p class="info-label">Deadline for signature withdrawal:</p>
          <p class="info-value">{{ data.signer.some || "-" }}</p>
        </div> -->
        <div class="info-item">
          <p class="info-label" i18n="@@Dateofsignature:">Date of signature:</p>
          <p class="info-value">
            {{ data.created_at | date : "dd/MM/yyyy" }}
          </p>
        </div>
        <div class="info-item">
          <p class="info-label" i18n="@@DocumentID:">Document ID:</p>
          <p class="info-value">{{ data.id }}</p>
        </div>
        <!-- <div class="info-item">
          <p class="info-label">User's agent who signed:</p>
          <p class="info-value">{{ data.signer.display_name }}</p>
        </div> -->
      </div>

      <!-- <button class="authenticity-check-btn">
        <span>Authenticity Check</span>
        <img src="/assets/icons/signature.svg" alt=""  />
      </button> -->
    </div>

    <div class="doc-view" *ngIf="data.downloadLink">
      <pdf-viewer
        [src]="data.downloadLink"
        [render-text]="true"
        [original-size]="false"
        [rotation]="0"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom-scale]="'page-width'"
        [stick-to-page]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        class="pdf-viewer"
      ></pdf-viewer>
    </div>
  </div>
</section>
<section *ngIf="showNotAuthorized">
  <div class="not-authorized">
    <h3 class="title" i18n="@@Youarenotauthorizedtoviewthisdocument">
      You are not authorized to view this document
    </h3>
    <p
      class="description"
      i18n="@@Pleasecontactthedocumentownerformoreinformation"
    >
      Please contact the document owner for more information
    </p>
    <button class="authenticity-check-btn">
      <span i18n="@@Gotohome">Go to home</span>
      <mat-icon>home</mat-icon>
    </button>
  </div>
</section>
