<div class="chat">
  <div
    class="message-chat"
    *ngFor="let item of getSortedMessages(thread.cp_message)"
    [class.me]="item.author_user_id === user.id"
    [class.other]="item.author_user_id !== user.id"
  >
    <p class="message-chat-header">
      {{ item.created_at | date : "dd/MM/yyyy HH:mm:ss" }}
    </p>
    <p class="message-chat-text" [innerHTML]="item.value"></p>
  </div>
</div>
<div
  class="advanced-chat-input"
  *ngIf="sendField === 'advanced' && thread.open_for_replies"
>
  <app-input-html
    [(ngModel)]="message"
    label=""
    placeholder="Type a message"
    name="message"
    type="text"
  ></app-input-html>

  <div class="action-btns">
    <button class="action-btn close" (click)="closeThread()">
      Close Thread
    </button>
    <button
      class="action-btn send"
      [disabled]="loadingSendMessage"
      (click)="sendMessage()"
    >
      Send Message
    </button>
  </div>
</div>

<div
  class="send-message"
  *ngIf="sendField === 'simple' && thread.open_for_replies"
>
  <div class="content">
    <div class="search">
      <app-shared-form-input-text
        [(ngModel)]="message"
        label=""
        placeholder="Type a message"
        name="message"
        type="text"
      ></app-shared-form-input-text>
    </div>
    <button
      class="send-btn"
      [disabled]="loadingSendMessage"
      (click)="sendMessage()"
    >
      <mat-icon *ngIf="!loadingSendMessage">send</mat-icon>
      <mat-spinner diameter="25" *ngIf="loadingSendMessage"></mat-spinner>
    </button>
  </div>
</div>
