import { Directive, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
import { KycService } from 'src/app/core/services/kyc.service';
import { PersonalDocumentService } from 'src/app/core/services/personal_document.service';
import { ClaimDocumentService } from 'src/app/core/services/claim_document.service';
import {
  DocumentTypeEnum,
  KycCheckBody,
  ProcessingStatus,
} from './document-kyc-upload-modal.component';
import { first } from 'rxjs';
import { UploadReturn } from 'src/app/core/services/base/document.service';

@Directive()
export abstract class BaseKycProviderComponent {
  steps:
    | 'TYPE-DOC'
    | 'UPLOAD'
    | 'PROCESSING'
    | 'SUCCESS'
    | 'ERROR'
    | 'END_PROCESSING' = 'TYPE-DOC';
  result: any = {};

  // TYPEDOC Controllers
  DocumentType = DocumentTypeEnum;
  documentType: DocumentTypeEnum = DocumentTypeEnum.NATIONAL_IDENTITY_CARD;
  options = [
    { value: DocumentTypeEnum.PASSPORT, label: 'Passport' },
    {
      value: DocumentTypeEnum.NATIONAL_IDENTITY_CARD,
      label: 'National Identity Card',
    },
    { value: DocumentTypeEnum.RESIDENCE_PERMIT, label: 'Residence Permit' },
    { value: DocumentTypeEnum.DRIVER_LICENSE, label: 'Driver License' },
  ];

  // Upload Controllers
  frontImageFile: File | null = null;
  backImageFile: File | null = null;
  frontImage: string | null = '';
  backImage: string | null = '';

  // Processing Controllers
  kyc: {
    provider: string;
    front_id: string;
    back_id?: string;
  };

  checkId: string = '';
  processing: ProcessingStatus = ProcessingStatus.PROCESSING;
  progress: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      bucket: string;
      name: string;
      type: 'PERSONAL' | 'CLAIM';
      multiple: boolean;
      ref: string;
      provider?: string;
    },
    public dialog: MatDialog,
    protected pds: PersonalDocumentService,
    protected cds: ClaimDocumentService,
    protected ks: KycService,
    protected us: UserService,
    protected dialogRef: MatDialogRef<any>
  ) {
    this.result = {
      status: 'CANCELLED',
      value: null,
    };

    if (!data.provider) data.provider = 'thirdfort';
    this.kyc = {
      provider: data.provider,
      front_id: '',
    };

    const sub = this.dialogRef
      .backdropClick()
      .pipe(first())
      .subscribe(() => this.close());
  }

  changeImage(event: File | null, side: 'front' | 'back') {
    if (!event) {
      if (side === 'front') {
        this.frontImage = null;
        this.frontImageFile = null;
      } else {
        this.backImage = null;
        this.backImageFile = null;
      }
      return;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (side === 'front') {
          this.frontImage = e.target?.result as string;
          this.frontImageFile = event;
        } else {
          this.backImage = e.target?.result as string;
          this.backImageFile = event;
        }
      };
      reader.readAsDataURL(event);
    }
  }

  getDocumentTypeLabel(type: DocumentTypeEnum) {
    return this.options.find((opt) => opt.value === type)?.label;
  }

  protected processDocumentType() {
    this.frontImage = '';
    this.backImage = '';
    this.steps = 'UPLOAD';
  }

  protected async processUpload() {
    // Get client name
    const { display_name } = (await this.us.getCurrentUser())!;
    const [firstName, ...rest] = display_name!.split(' ');
    const restName = rest.join(' ');

    // Send to server
    this.steps = 'PROCESSING';
    this.processing = ProcessingStatus.PROCESSING;
    this.progress = 0;

    const idSetInterval = setInterval(() => {
      if (this.progress < 95) this.progress += 0.25;
    }, 50);

    try {
      let uls = this.pds;

      const uploads: any = [];
      if (this.frontImageFile) uploads.push(this.frontImageFile);
      if (this.backImageFile) uploads.push(this.backImageFile);

      if (uploads.length === 0) {
        throw new Error('No files to upload');
      }

      const p$ = await Promise.all<UploadReturn>(
        uploads.map((f: File, i: number) => {
          return uls.upload(
            f,
            this.data.ref,
            this.documentType,
            this.data.bucket,
            i
          ) as Promise<UploadReturn>;
        })
      );

      if (!p$ || !p$[0] || !p$[0].data || !p$[0].data.doc.id) {
        throw new Error('Document upload failed.');
      }

      // Create the request object
      const kycCheckBody: KycCheckBody = {
        provider: (this.data.provider ?? 'thirdfort') as
          | 'thirdfort'
          | 'mock'
          | 'textract',
        documentType: this.getDocumentTypeForAPI(this.documentType) as any,
        user_id: (await this.us.getCurrentUser())!.id!,
        front_id: p$[0].data.doc.id,
      };

      if (p$.length > 1) {
        if (!p$ || !p$[1] || !p$[1].data || !p$[1].data.doc.id) {
          throw new Error('Document upload failed.');
        }
        kycCheckBody.back_id = p$[1].data.doc.id;
      }

      const res = await this.ks.submitKycCheck(kycCheckBody);

      if (!res || !res.id) {
        throw new Error('Failed to get KYC check ID');
      }

      const checkId = res.id;
      this.checkId = checkId;

      await this.sleep(5000);
      let kycCheck = await this.ks.getById(checkId);

      if (kycCheck && kycCheck.stage && kycCheck.stage === 'DONE') {
        this.processing =
          kycCheck.passed === true
            ? ProcessingStatus.APPROVED
            : ProcessingStatus.REJECTED;

        this.steps =
          this.processing === ProcessingStatus.APPROVED ? 'SUCCESS' : 'ERROR';
      } else {
        await this.sleep(5000);
        kycCheck = await this.ks.getById(checkId);

        if (kycCheck && kycCheck.stage) {
          if (kycCheck.stage === 'DONE') {
            this.processing =
              kycCheck.passed === true
                ? ProcessingStatus.APPROVED
                : ProcessingStatus.REJECTED;
            this.steps =
              this.processing === ProcessingStatus.APPROVED
                ? 'SUCCESS'
                : 'ERROR';
          } else {
            console.log('KYC check still processing, will complete soon');
            this.processing = ProcessingStatus.END_PROCESSING;
            this.steps = 'END_PROCESSING';
          }
        } else {
          throw new Error('Failed to get KYC check status');
        }
      }
    } catch (error) {
      console.log('error process kyc', error);
      this.processing = ProcessingStatus.REJECTED;
      this.steps = 'ERROR';
    } finally {
      this.progress = 100;
      clearInterval(idSetInterval);
    }
  }

  protected sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  protected getDocumentTypeForAPI(docType: DocumentTypeEnum): string {
    switch (docType) {
      case DocumentTypeEnum.PASSPORT:
        return 'passport';
      case DocumentTypeEnum.DRIVER_LICENSE:
        return 'driving_license';
      case DocumentTypeEnum.NATIONAL_IDENTITY_CARD:
      case DocumentTypeEnum.RESIDENCE_PERMIT:
        return 'identity_card';
      default:
        return 'passport';
    }
  }

  protected processError() {
    this.steps = 'UPLOAD';
    this.frontImage = '';
    this.backImage = '';
  }

  nextStep() {
    switch (this.steps) {
      case 'TYPE-DOC':
        this.processDocumentType();
        break;
      case 'UPLOAD':
        this.processUpload();
        break;
      case 'ERROR':
        this.processError();
        break;
      default:
        break;
    }
  }

  backStep() {
    switch (this.steps) {
      case 'UPLOAD':
        this.steps = 'TYPE-DOC';
        break;
      case 'PROCESSING':
        this.steps = 'UPLOAD';
        break;
      default:
        break;
    }
  }

  close() {
    this.dialogRef.close(JSON.stringify(this.result));
  }
}
