import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AccordionComponent } from './accordion/accordion.component';
import { AccordionMultiComponent } from './accordion-multi/accordion-multi.component';
import { AccordionSingleComponent } from './accordion-single/accordion-single.component';
import { ActionsModalUiComponent } from './actions-modal-ui/actions-modal.ui.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { BlockComponent } from './block/block.component';
import { ButtonActionComponent } from './button-action/button-action.component';
import { ButtonClearComponent } from './button-clear/button-clear.component';
import { ButtonComponent } from './button/button.component';
import { ButtonDefaultComponent } from './button-default/button-default.component';
import { ButtonExtraComponent } from './button-extra/button-extra.component';
import { ButtonOutlineComponent } from './button-outline/button-outline.component';
import { ButtonRoundedComponent } from './button-rounded/button-rounded.component';
import { ButtonToggleMenuComponent } from './button-toggle-menu/button-toggle-menu.component';
import { CardClaimDetailComponent } from './card-details-claim/card-details-claim.component';
import { CardComponent } from './card/card.component';

import { CaseStatusPipe } from './case-status/case-status.pipe';
import { ChoiceModalComponent } from './choice-modal/choice-modal.component';
import { ColorComponent } from './color/color.component';
import { CommonModule } from '@angular/common';
import { CommunicationCallNotesComponent } from './communication-call-notes/communication-call-notes.component';
import { CommunicationChatComponent } from './communication-chat/communication-chat.component';
import { CommunicationEmailComponent } from './communication-email/communication-email.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CustomPaginatorComponent } from './custom-paginator/custom-paginator.component';
import { DisplayLargeComponent } from './display-large/display-large.component';
import { DocumentDropdownComponent } from './document-dropdown/document-dropdown.component';
import { DocumentKycUploadModalComponent } from './document-kyc-upload-modal/document-kyc-upload-modal.component';
import { DocumentPreviewModalComponent } from './document-preview-modal/document-preview-modal.component';
import { DocumentUploadModalComponent } from './document-upload-modal/document-upload-modal.component';
import { EmailResetModalComponent } from './email-reset-modal/email-reset-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { FormV2ComponentsModule } from '../../forms-v2/form-v2.module';
import { GridComponent } from './grid/grid.component';
import { IconComponent } from './icon/icon.component';
import { ImageComponent } from './image/image.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { InputComponent } from './input/input.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputDropdownComponent } from './input-dropdown/input-dropdown.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputTelephoneComponent } from './input-telephone/input-telephone.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { InputToggleSlideComponent } from './input-toggle-slide/input-toggle-slide.component';
import { InputUploadFileComponent } from '../../forms-v2/input-upload-file/input-upload-file.component';
import { ListComponent } from './list/list.component';
import { MarkdownModule } from 'ngx-markdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '../../modules/material/material-module.module';
import { MessageCardComponent } from './message-card/message-card.component';
import { ModalComponent } from './modal/modal.component';
import { NgModule } from '@angular/core';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NotificationComponent } from './notification/notification.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { QuestionCheckboxComponent } from './question-checkbox/question-checkbox.component';
import { QuestionComponent } from './question/question.component';
import { QuestionCurrencyComponent } from './question-currency/question-currency.component';
import { QuestionDateComponent } from './question-date/question-date.component';
import { QuestionDateTimeComponent } from './question-date-time/question-date-time.component';
import { QuestionEmailComponent } from './question-email/question-email.component';
import { QuestionExplanationComponent } from './question-explanation/question-explanation.component';
import { QuestionMonthYearComponent } from './question-month-year/question-month-year.component';
import { QuestionMultiFileComponent } from './question-multi-file/question-multi-file.component';
import { QuestionMultiSelectComponent } from './question-multi-select/question-multi-select.component';
import { QuestionNumberComponent } from './question-number/question-number.component';
import { QuestionPhoneComponent } from './question-phone/question-phone.component';
import { QuestionSelectComponent } from './question-select/question-select.component';
import { QuestionTableComponent } from './question-table/question-table.component';
import { QuestionTextComponent } from './question-text/question-text.component';
import { QuestionTimeComponent } from './question-time/question-time.component';
import { QuestionUKAddressComponent } from '../smart/question-uk-address/question-uk-address.component';
import { QuestionWorldAddressComponent } from '../smart/question-world-address/question-world-address.component';
import { QuestionYesNoComponent } from './question-yes-no/question-yes-no.component';
import { RouterModule } from '@angular/router';
import { SearchInputComponent } from './search-input/search-input.component';
import { SignatureComponent } from './signature/signature.component';
import { StatusProgressBarComponent } from './status-progress-bar/status-progress-bar.component';
import { SucessModalComponent } from './sucess-modal/sucess-modal.component';
import { TestStorageComponent } from './test-storage/test-storage.component';
import { TitleLargeComponent } from './title-large/title-large.component';
import { ToolbarNavComponent } from './toolbar/toolbar-nav/toolbar-nav.component';
import { ToolbarSearchComponent } from './toolbar/toolbar-search/toolbar-search.component';
import { TopbarComponent } from './topbar/topbar.component';
import { TypographComponent } from './typograph/typograph.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { QuestionCountryComponent } from './question-country/question-country.component';
import { QuestionLinkComponent } from './question-link/question-link.component';
import { DocumentKycUploadManualModalComponent } from './document-kyc-upload-manual-modal/document-kyc-upload-manual-modal.component';
import { QuestionConcatComponent } from './question-concat/question-concat.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ThirdfortKycProviderComponent } from './document-kyc-upload-modal/thirdfort-kyc-provider/thirdfort-kyc-provider.component';
import { TextractKycProviderComponent } from './document-kyc-upload-modal/textract-kyc-provider/textract-kyc-provider.component';

const allComponents = [
  AccordionComponent,
  AccordionMultiComponent,
  AccordionSingleComponent,
  BlockComponent,
  ButtonComponent,
  ButtonActionComponent,
  ButtonClearComponent,
  ButtonDefaultComponent,
  ButtonExtraComponent,
  ButtonOutlineComponent,
  ButtonRoundedComponent,
  ButtonToggleMenuComponent,
  BreadcrumbComponent,
  CardComponent,
  CardClaimDetailComponent,
  CaseStatusPipe,
  ChoiceModalComponent,
  ColorComponent,
  ConfirmModalComponent,
  CustomPaginatorComponent,
  DisplayLargeComponent,
  DocumentDropdownComponent,
  DocumentPreviewModalComponent,
  DocumentUploadModalComponent,
  EmailResetModalComponent,
  ErrorModalComponent,
  GridComponent,
  IconComponent,
  ImageComponent,
  InputComponent,
  InputCheckboxComponent,
  InputDateComponent,
  InputDropdownComponent,
  InputPasswordComponent,
  InputRadioComponent,
  InputSelectComponent,
  InputTelephoneComponent,
  InputTextComponent,
  InputTextareaComponent,
  InputToggleSlideComponent,
  ListComponent,
  ModalComponent,
  NotificationComponent,
  SearchInputComponent,
  SignatureComponent,
  StatusProgressBarComponent,
  SucessModalComponent,
  TestStorageComponent,
  TitleLargeComponent,
  ToolbarNavComponent,
  ToolbarSearchComponent,
  TopbarComponent,
  TypographComponent,
  QuestionComponent,
  QuestionConcatComponent,
  QuestionCountryComponent,
  QuestionExplanationComponent,
  QuestionLinkComponent,
  QuestionNumberComponent,
  QuestionSelectComponent,
  QuestionMultiFileComponent,
  QuestionTextComponent,
  QuestionDateComponent,
  QuestionPhoneComponent,
  QuestionMonthYearComponent,
  QuestionYesNoComponent,
  QuestionCheckboxComponent,
  QuestionMultiSelectComponent,
  QuestionTimeComponent,
  QuestionDateTimeComponent,
  QuestionEmailComponent,
  QuestionCurrencyComponent,
  QuestionTableComponent,
  ActionsModalUiComponent,
  MessageCardComponent,
  DocumentKycUploadModalComponent,
  ThirdfortKycProviderComponent,
  TextractKycProviderComponent,
  DocumentKycUploadManualModalComponent,

  CommunicationCallNotesComponent,
  CommunicationEmailComponent,
  CommunicationChatComponent,
  InfoModalComponent,
];

@NgModule({
  declarations: allComponents,
  exports: allComponents,
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    AngularSignaturePadModule,
    RouterModule,
    MatInputModule,
    MatFormFieldModule,
    MarkdownModule.forRoot(),
    NgxMatIntlTelInputComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    QuestionWorldAddressComponent,
    QuestionUKAddressComponent,
    FormV2ComponentsModule,
    TranslateModule
  ],
  providers: [provideNgxMask()],
})
export class SimpleModule {}
