<mat-card>
  <mat-card-content *ngIf="column">
    <div class="sort">
      <div
        class="sort-changer asc"
        [class.active]="column.sort == 'asc'"
        (click)="toggleSortAsc()"
      >
        <span i18n="@@Sortascending">Sort ascending</span>
        <mat-icon>arrow_upward</mat-icon>
      </div>
      <div
        class="sort-changer desc"
        [class.active]="column.sort == 'desc'"
        (click)="toggleSortDesc()"
      >
        <span i18n="@@Sortdescending">Sort descending</span>
        <mat-icon>arrow_downward</mat-icon>
      </div>
    </div>
    <div [ngSwitch]="column.filterType">
      <div *ngSwitchCase="'text'">
        <app-filter-text
          [column]="column"
          (columnChanges)="onFiltersChange($event)"
        ></app-filter-text>
      </div>
      <div *ngSwitchCase="'number'">
        <app-filter-number
          [column]="column"
          (columnChanges)="onFiltersChange($event)"
        ></app-filter-number>
      </div>
      <div *ngSwitchCase="'date'">
        <app-filter-date
          [column]="column"
          (columnChanges)="onFiltersChange($event)"
        ></app-filter-date>
      </div>
      <div *ngSwitchCase="'boolean'">
        <app-filter-boolean
          [column]="column"
          (columnChanges)="onFiltersChange($event)"
        ></app-filter-boolean>
      </div>
      <div *ngSwitchCase="'options'">
        <app-filter-options
          [column]="column"
          (columnChanges)="onFiltersChange($event)"
          [settings]="settings"
        ></app-filter-options>
      </div>
      <div *ngSwitchDefault="">
        <app-filter-text
          [column]="column"
          (columnChanges)="onFiltersChange($event)"
        ></app-filter-text>
      </div>
    </div>
    <!-- <app-filter-text
      [column]="column"
      (columnChanges)="onFiltersChange($event)"
    ></app-filter-text> -->
  </mat-card-content>

  <!-- <mat-card-actions>
    <button mat-button (click)="saveFilter(editedFilter, tempFilter)">
      Ok
    </button>
    <button mat-button (click)="deleteFilter(editedFilter)">Remove</button>
  </mat-card-actions> -->
</mat-card>
