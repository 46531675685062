<div class="container">
  <h5 class="title" >{{'' | translate}}</h5>
  <p class="desc">
    To complete the setup,
    <strong 
      >{{'entertheverificationcodegeneratedbyyourauthenticatorapp' | translate}}</strong
    >. {{'Thiscodechanges' | translate}}
    <strong >{{'everyfewseconds' | translate}}</strong> for added
    security. If you're unable to generate a code, ensure your authenticator app
    is properly synced with the account and try again.
    <strong
     
      >{{'Ifyou\'restillexperiencingissues,contactsupportforassistance.' | translate}}</strong
    >
  </p>

  <app-shared-form-input-text
    [(ngModel)]="code"
    i18n-label="@@CodeVerifyCode"
    label="Code"
  ></app-shared-form-input-text>

  <button class="btn-close" [disabled]="code.length < 6" (click)="sendCode()">
    {{'Next' | translate}}
  </button>
</div>
