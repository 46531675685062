import { inject, Injectable } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard {
  private validLanguages: string[] = ['pt', 'en'];

  constructor(private translate: TranslateService, private us:UserService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree | Observable<boolean | UrlTree>> {
    let ul;
    try {
      const cu = await this.us.getCurrentUser();
      ul = cu?.language;
      if (ul) {
        this.translate.use(ul);
      }
    } catch (error) {
      
    }


    const browserLang = this.getBrowserLanguage();
    const defaultLang = browserLang || 'en';

    this.translate.setDefaultLang(defaultLang);
    this.translate.use(defaultLang);

    return true;
  }

  changeLanguage(lang: string) {
    if (this.validLanguages.includes(lang)) {
      this.translate.use(lang);
    }
  }

  private getBrowserLanguage(): string | null {
    const browserLanguage = navigator.language?.split('-')[0];
    const browserLanguages = navigator.languages?.map(
      (lang) => lang.split('-')[0]
    );

    if (browserLanguage && this.validLanguages.includes(browserLanguage)) {
      return browserLanguage;
    }

    if (browserLanguages) {
      for (const lang of browserLanguages) {
        if (this.validLanguages.includes(lang)) {
          return lang;
        }
      }
    }

    return null;
  }
}
