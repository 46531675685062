<div class="row">
  <h4 i18n="@@Button">Button</h4>
</div>
<div class="row">
  <div class="col-lg-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@default">default</th>
        </tr>
        <tr>
          <td>
            <app-button-default [type]="'light'" [size]="'md'" i18n="@@light">light</app-button-default>
          </td>
          <td>
            <app-button-default [size]="'md'" i18n="@@default">default</app-button-default>
          </td>
          <td>
            <app-button-default [type]="'secondary'" [size]="'md'" i18n="@@secondary">secondary</app-button-default>
          </td>
          <td>
            <app-button-default [type]="'danger'" [size]="'md'" i18n="@@danger">danger</app-button-default>
          </td>
          <td>
            <app-button-default [type]="'dark'" [size]="'md'" i18n="@@dark">dark</app-button-default>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="col-lg-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@Clear">Clear</th>
        </tr>
        <tr>
          <td>
            <app-button-clear type="light" [size]="'md'" i18n="@@light">light</app-button-clear>
          </td>
          <td><app-button-clear [size]="'md'" i18n="@@default">default</app-button-clear></td>
          <td>
            <app-button-clear type="secondary" [size]="'md'" i18n="@@secondary">secondary</app-button-clear>
          </td>
          <td>
            <app-button-clear type="danger" [size]="'md'" i18n="@@danger">danger</app-button-clear>
          </td>
          <td>
            <app-button-clear type="dark" [size]="'md'" i18n="@@dark">dark</app-button-clear>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@outline">outline</th>
        </tr>
        <tr>
          <td>
            <app-button-outline type="light" [size]="'md'" i18n="@@light">light</app-button-outline>
          </td>
          <td>
            <app-button-outline [size]="'md'" i18n="@@default">default</app-button-outline>
          </td>
          <td>
            <app-button-outline type="secondary" [size]="'md'" i18n="@@secondary">secondary</app-button-outline>
          </td>
          <td>
            <app-button-outline type="danger" [size]="'md'" i18n="@@danger">danger</app-button-outline>
          </td>
          <td>
            <app-button-outline type="dark" [size]="'md'" i18n="@@dark">dark</app-button-outline>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="col-lg-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@rounded">rounded</th>
        </tr>
        <tr>
          <td>
            <app-button-rounded type="light" i18n="@@light">light</app-button-rounded>
          </td>
          <td>
            <app-button-rounded i18n="@@default">default</app-button-rounded>
          </td>
          <td>
            <app-button-rounded type="secondary" i18n="@@secondary">secondary</app-button-rounded>
          </td>
          <td>
            <app-button-rounded type="danger" i18n="@@danger">danger</app-button-rounded>
          </td>
          <td>
            <app-button-rounded type="dark" i18n="@@dark">dark</app-button-rounded>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-4 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@Size">Size</th>
        </tr>
        <tr>
          <td>
            <app-button-default [size]="'sm'" i18n="@@default">default</app-button-default>
          </td>
          <td>
            <app-button-default [size]="'md'" i18n="@@default">default</app-button-default>
          </td>
          <td>
            <app-button-default [size]="'lg'" i18n="@@default">default</app-button-default>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="col-4 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@Action">Action</th>
        </tr>
        <tr>
          <td>
            <app-button-action [type]="'back'"></app-button-action>
          </td>
          <td>
            <app-button-action [type]="'next'"></app-button-action>
          </td>
          <td>
            <app-button-action [type]="'up'"></app-button-action>
          </td>
          <td>
            <app-button-action [type]="'down'"></app-button-action>
          </td>
          <td>
            <app-button-action [type]="'right'"></app-button-action>
          </td>
          <td>
            <app-button-action [type]="'left'"></app-button-action>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-8 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@Extra">Extra</th>
        </tr>
        <tr>
          <td>
            <app-button-extra [type]="'home'">
              <i class="ic-sm ic-home-white"></i>
              <span i18n="@@home">home</span>
            </app-button-extra>
          </td>
          <td>
            <app-button-extra [type]="'work'">
              <i class="ic-sm ic-bag-blue"></i>
              <span i18n="@@work">work</span>
            </app-button-extra>
          </td>
          <td>
            <app-button-extra [type]="'icon'"><i class="ic-sm ic-beer-blue"></i> <span i18n="@@pub">pub</span>
            </app-button-extra>
          </td>
          <td>
            <app-button-extra [type]="'best-friends'">
              <i class="ic-sm ic-pet-green"></i>
              <span i18n="@@bestfriends">best friends</span>
            </app-button-extra>
          </td>
          <td>
            <app-button-extra [type]="'remove'">
              <i class="ic-sm ic-cancel-white"></i>
              <span i18n="@@remove">remove</span>
            </app-button-extra>
          </td>
          <td>
            <app-button-extra [type]="'tools'">
              <i class="ic-sm ic-tools-white"></i>
              <span i18n="@@tools">tools</span>
            </app-button-extra>
          </td>
          <td>
            <app-button-extra [type]="'minus'">
              <i class="ic-sm ic-minus-red-rd"></i>
            </app-button-extra>
          </td>
          <td>
            <app-button-extra [type]="'icon'">
              <i class="ic-sm ic-hammer-tools"></i>
            </app-button-extra>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div class="row">
  <h4 i18n="@@Badges">Badges</h4>
</div>
<div class="row">
  <div class="col-8 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <td><span class="badge" i18n="@@9">9</span></td>
        <td><span class="badge" i18n="@@260k">260k</span></td>
        <td><span class="badge badge-danger" i18n="@@9">9</span></td>
        <td><span class="badge badge-danger" i18n="@@260k">260k</span></td>
        <td><span class="badge badge-priority-4" i18n="@@9">9</span></td>
        <td><span class="badge badge-priority-4" i18n="@@260k">260k</span></td>
        <td><span class="badge badge-priority-2" i18n="@@9">9</span></td>
        <td><span class="badge badge-priority-2" i18n="@@260k">260k</span></td>
      </table>
    </div>
  </div>
</div>
<div class="row">
  <h4 i18n="@@ButtonAngularMaterial">Button Angular Material</h4>
</div>

<div class="row">
  <div class="col-4 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@Basic">Basic</th>
        </tr>
        <tr>
          <td><button mat-button="">Basic</button></td>
          <td><button mat-button="" color="accent">Accent</button></td>
          <td><button mat-button="" color="warn">Warn</button></td>
          <td><button mat-button="" disabled="">Disabled</button></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="col-4 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@Raised">Raised</th>
        </tr>
        <tr>
          <td><button mat-raised-button="">Basic</button></td>
          <td><button mat-raised-button="" color="accent">Accent</button></td>
          <td><button mat-raised-button="" color="warn">Warn</button></td>
          <td><button mat-raised-button="" disabled="">Disabled</button></td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-4 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@stroked">stroked</th>
        </tr>
        <tr>
          <td><button mat-stroked-button="">Basic</button></td>
          <td><button mat-stroked-button="" color="accent">Accent</button></td>
          <td><button mat-stroked-button="" color="warn">Warn</button></td>
          <td><button mat-stroked-button="" disabled="">Disabled</button></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="col-4 col-md-4 col-sm-8 flex flex-direction-column">
    <div style="overflow-x: auto; padding-bottom: 10px">
      <table>
        <tr>
          <th i18n="@@flat">flat</th>
        </tr>
        <tr>
          <td><button mat-flat-button="">Basic</button></td>
          <td><button mat-flat-button="" color="accent">Accent</button></td>
          <td><button mat-flat-button="" color="warn">Warn</button></td>
          <td><button mat-flat-button="" disabled="">Disabled</button></td>
        </tr>
      </table>
    </div>
  </div>
</div>
