<div class="form-field">
  <label [for]="id">{{ label }}</label>
  <div class="input-content">
    <input
      [class.invalid]="!!error"
      [id]="id"
      [name]="id"
      type="text"
      [placeholder]="placeholder ?? ''"
      [(ngModel)]="value"
      (ngModelChange)="emitChange($event)"
      [ngModelOptions]="{ standalone: true }"
      (focus)="emitFocus()"
      (focusout)="emitFocusOut()"
      [matDatepicker]="picker"
    />

    <!-- <button
      mat-icon-button
      class="password-visibility"
      (click)="visibleValue = !visibleValue"
    >
      <mat-icon>{{  }}</mat-icon>
    </button> -->
    <mat-datepicker-toggle
      class="btn-date-toogle"
      matIconSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <p class="hint-error" *ngIf="error">{{ error }}</p>
</div>
