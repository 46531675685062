<div class="modal">
  <div class="modal-header">
    <h6 >{{'Downloaddocument' | translate}}
</h6>
  </div>
  <div class="modal-body">
    <h6 >{{'Pleasewait,yoursignatureisbeingprocessed.' | translate}}
</h6>
  </div>
  <div class="modal-footer">
    <button [mat-dialog-close]="true" class="btn-md">{{'Close' | translate}}
</button>
  </div>
</div>
