import { Component, Inject, type OnInit } from '@angular/core';
import {
  DocumentTypeEnum,
  KycCheckBody,
  ProcessingStatus,
} from '../document-kyc-upload-modal.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { first } from 'rxjs';
import { UploadReturn } from 'src/app/core/services/base/document.service';
import { ClaimDocumentService } from 'src/app/core/services/claim_document.service';
import { DocumentType, KycService } from 'src/app/core/services/kyc.service';
import { PersonalDocumentService } from 'src/app/core/services/personal_document.service';
import { UserService } from 'src/app/core/services/user.service';
import { ThirdfortKycProviderComponent } from '../thirdfort-kyc-provider/thirdfort-kyc-provider.component';
import { BaseKycProviderComponent } from '../base-kyc-provider.component';

@Component({
  selector: 'app-textract-kyc-provider',
  templateUrl: './textract-kyc-provider.component.html',
  styleUrls: ['./textract-kyc-provider.component.scss'],
})
export class TextractKycProviderComponent
  extends BaseKycProviderComponent
  implements OnInit
{
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    dialog: MatDialog,
    pds: PersonalDocumentService,
    cds: ClaimDocumentService,
    ks: KycService,
    us: UserService,
    dialogRef: MatDialogRef<ThirdfortKycProviderComponent>
  ) {
    super(data, dialog, pds, cds, ks, us, dialogRef);
    if (!data.provider) data.provider = 'textract';
  }

  ngOnInit() {}
}
