<ng-container *ngIf="type === 'light' && size == 'sm'">
  <button mat-button="" [disabled]="disabled" class="btn-sm btn-light">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'light' && size == 'md'">
  <button mat-button="" [disabled]="disabled" class="btn-md btn-light">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'light' && size == 'lg'">
  <button mat-button="" [disabled]="disabled" class="btn-lg btn-light">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'secondary' && size == 'sm'">
  <button mat-button="" [disabled]="disabled" class="btn-sm btn-outline">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'secondary' && size == 'md'">
  <button mat-button="" [disabled]="disabled" class="btn-md btn-secondary">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'secondary' && size == 'lg'">
  <button mat-button="" [disabled]="disabled" class="btn-lg btn-secondary">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'danger' && size == 'sm'">
  <button mat-button="" [disabled]="disabled" class="btn-sm btn-danger">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'danger' && size == 'md'">
  <button mat-button="" [disabled]="disabled" class="btn-md btn-danger">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'danger' && size == 'lg'">
  <button mat-button="" [disabled]="disabled" class="btn-lg btn-danger">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'dark' && size == 'sm'">
  <button mat-button="" [disabled]="disabled" class="btn-sm btn-dark">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'dark' && size == 'md'">
  <button mat-button="" [disabled]="disabled" class="btn-md btn-dark">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'dark' && size == 'lg'">
  <button mat-button="" [disabled]="disabled" class="btn-lg btn-dark">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type == undefined && size == 'sm'">
  <button mat-button="" [disabled]="disabled" class="btn-sm btn-default">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type == undefined && size == 'md'">
  <button mat-button="" [disabled]="disabled" class="btn-md btn-default">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type == undefined && size == 'lg'">
  <button mat-button="" [disabled]="disabled" class="btn-lg btn-default">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-template #contentTpl=""><ng-content></ng-content></ng-template>
