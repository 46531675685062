<div class="form-group" [style.minHeight]="height">
  <label [htmlFor]="id">{{ label }}</label>
  <quill-editor
    [modules]="modules"
    [(ngModel)]="value"
    (ngModelChange)="emitChange($event)"
    [ngModelOptions]="{ standalone: true }"
    (onEditorChanged)="onEditorChanged$.emit($event)"
    (onContentChanged)="onContentChanged$.emit($event)"
    (onSelectionChanged)="onSelectionChanged$.emit($event)"
    (onFocus)="onFocus$.emit()"
    [name]="name"
    [id]="id"
    [styles]="{
      height: '100%',
      minHeight: height,
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      overflow: 'auto',
    }"
  ></quill-editor>
</div>
