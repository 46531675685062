<section class="container">
  <ng-container *ngIf="!claimId">
    <div class="header-all">
      <h1 class="title"  >{{'Messages' | translate}}</h1>
      <div class="sub">
        <h2 class="subtitle" >{{'AllMessages' | translate}}</h2>
        <!-- <button class="write-messages-btn" (click)="goToNewMessage()">
          Contact us <mat-icon> add </mat-icon>
        </button> -->
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="claimId && claim">
    <div class="header-all">
      <div class="breadcrumb">
        <span class="bc-pointer" (click)="goToMessage()"
          ><b  > {{'Message/' | translate}} </b></span
        >
        <span class="bc-pointer" (click)="goToMessageWithClaim()">
          <b> {{ " " + claim?.cp_case?.code }} </b>
        </span>
      </div>
      <div class="sub">
        <h2 class="subtitle">{{ claim.cp_case.summary }}</h2>
        <button class="write-messages-btn" (click)="goToNewMessage()">
          Contact us <mat-icon> add </mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="message-list" *ngIf="!loading; else loadingData">
    <message-card
      *ngFor="let item of threads.data"
      [thread]="item"
    ></message-card>
    <button class="load-more-button" *ngIf="">Load more...</button>
  </div>

  <ng-template #loadingData="">
    <div class="loading">
      <p  >{{'Loadingchats...' | translate}}</p>
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </ng-template>
</section>
