<div class="container">
  <mat-icon class="icon">celebration</mat-icon>
  <h6 class="title" i18n="@@Success">{{'Success' | translate}}</h6>
  <p
    class="text"

  >
    {{'Yournewpasswordhasbeencreatedsuccessfully.Entertheportalnow' | translate}}
  </p>
  <button class="to-login-btn" routerLink="/auth/login">{{'SignIn' | translate}}</button>
</div>
