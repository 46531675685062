<div class="legal-card">
  <app-title-large >{{'LegalInformation' | translate}}</app-title-large>

  <img src="assets/img/legal-information.png" alt="Legal Information" />

  <p>
    {{'Whenyouaccessedthiswebsite,youacceptedour'|translate}}
    <a href="javascript:void(0)" (click)="openTerms()">{{'Termsofuse' | translate}}</a>. {{'IfyouwouldliketoseeourPrivacyNotice,pleaseclick' | translate}}
    <a href="javascript:void(0)" (click)="openPrivacy()">{{'here' | translate}}</a> {{'andPleasemakesuretoreviewthesepoliciesregularlytostayinformedofanyupdatesorchanges.' | translate}}
    
  </p>
</div>
