<div class="news-list">
  <app-title-large
    color="var(--color-neutral-40)"
    [paddingMobile]="true"
    
    >{{'News' | translate}}
  </app-title-large>

  <div class="news-content" [class.multi]="multiClaims">
    <ng-container *ngIf="news">
      <app-news-teaser-card
        *ngFor="let item of news"
        [news]="item"
        [multiClaims]="multiClaims"
      ></app-news-teaser-card>
    </ng-container>

    <ng-container *ngIf="!news">
      <div class="spin-container">
        <mat-spinner [value]="'intederminate'" diameter="60"></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
