<div class="container">
  <button mat-icon-button="" class="close-btn" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="'before-init'">
      <app-pre-init (go$)="changeStep('qrcode')"></app-pre-init>
    </ng-container>
    <ng-container *ngSwitchCase="'qrcode'">
      <app-mfa-qr-code (go$)="getTargetId($event.targetId)"></app-mfa-qr-code>
    </ng-container>
    <ng-container *ngSwitchCase="'code-verify'">
      <app-mfa-code-verify (code$)="verify($event)"></app-mfa-code-verify>
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
      <app-success-or-error
        [success]="true"
        (close$)="onClose()"
      ></app-success-or-error>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <app-success-or-error
        [error]="true"
        (close$)="onClose()"
      ></app-success-or-error>
    </ng-container>
  </ng-container>
</div>
