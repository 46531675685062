<ng-container>
  <mat-form-field id="select" appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="field">
      <mat-option *ngFor="let option of options" value="{{ option.value }}">
        {{ option.text }}
      </mat-option>
    </mat-select>
    <!-- <mat-icon id="icon" matSuffix>expand_more</mat-icon> -->
  </mat-form-field>
</ng-container>
