<form [formGroup]="addressForm">
  <mat-form-field>
    <input matInput="" placeholder="{{'Search...'| translate}}" formControlName="address" [required]="!question.elective" [matAutocomplete]="auto" (keyup)="doSearch()" />

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngIf="loading; else loaded" style="pointer-events: none">
        <div class="spinner-container">
          <mat-spinner [mode]="'indeterminate'"> </mat-spinner>
          searching...
        </div>
      </mat-option>

      <ng-template #loaded="">
        <mat-option *ngIf="!addressList.length; else hasAddress" disabled="" i18n="@@Noresult">No result</mat-option>
        <ng-template #hasAddress="">
          <mat-option *ngFor="let address of addressList" [value]="address.value" style="line-height: 19px" (click)="selectAddress(address.info)">
            {{ address.value }}
          </mat-option>
        </ng-template>
      </ng-template>
    </mat-autocomplete>

    <mat-error *ngIf="addressForm.invalid && addressForm.dirty" i18n="@@Selectanaddress">Select an address</mat-error>
  </mat-form-field>

  <p class="custom-error" *ngIf="
      addressForm.controls.address.dirty &&
      addressForm.controls.additional_info.invalid
    " i18n="@@Selectanaddress">
    Select an address
  </p>
</form>
