<ul class="list-claim" *ngIf="claims$ | async as claims">
  <ng-container *ngFor="let claim of claims">
    <li class="list-claim-item">
      <div mat-card="" class="card-list-claim">
        <div class="image">
          <img src="https://source.unsplash.com/random/300×300" alt="" />
        </div>
        <div class="data">
          <div class="infos">
            <h2>{{ claim.name }}</h2>
            <h3>{{ claim.nickname }}</h3>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet
              voluptas exercitationem laudantium quos expedita, quia illum magni
              iure perferendis omnis consequatur sit, perspiciatis voluptates
              eligendi rerum nobis impedit et minima?
            </p>
          </div>
          <button
            mat-flat-button=""
            color="warn"
            *ngIf="claim.status === 'current'"
          >
            COMPLETE YOUR REGISTRATION
          </button>
          <button
            mat-flat-button=""
            color="warn"
            *ngIf="claim.status === 'new'"
          >
            CLAIM NOW
          </button>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
