<div class="container">
  <button mat-icon-button="" class="close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <div class="header">
    <mat-icon class="title-icon">sentiment_dissatisfied</mat-icon>
    <h5 class="title">{{'UploadError' | translate}}</h5>
  </div>
  <p
    class="text"
  >
    {{'Wecouldn\'tuploadyourfile.Pleasecheckyourfileformat,andtryagain.' | translate}}
  </p>
  <button class="btn-close" (click)="dialogRef.close()">{{'Close' | translate}}</button>
</div>
