<form class="recovery" *ngIf="form" [formGroup]="form">
  <h6 class="title" >{{'Resetpassword' | translate}}</h6>
  <p class="desc" >
    {{'Enterthecodesenttoyouremail.' | translate}}
  </p>

  <!-- <app-shared-form-input-text
    formControlName="code"
    label="Code"
    placeholder="Code"
    name="code"
  ></app-shared-form-input-text> -->

  <app-shared-form-code-challenger
    formControlName="code"
    name="code"
  ></app-shared-form-code-challenger>

  <button
    class="submit-btn"
    [disabled]="isLoading || form.invalid"
    (click)="submit()"
  >
    <ng-container *ngIf="!isLoading"> {{'Continue' | translate}} </ng-container>
    <ng-container *ngIf="isLoading">
      <mat-spinner diameter="20"></mat-spinner> {{'Loading...' | translate}}
    </ng-container>
  </button>

  <button
    class="resend-code"
    color="primary"
    [disabled]="!sendCodePermission && lastSendTime !== null"
    (click)="resendCode()"
  >
    <ng-container
      *ngIf="!sendCodePermission && lastSendTime !== null; else openToSend"
    >
      Wait {{ getDiffLastTimeAndNowInSec() }}...
    </ng-container>
    <ng-template #openToSend="">
      <ng-container *ngIf="!isLoading">
        <mat-icon>autorenew</mat-icon>
        <span >{{'Resendcode' | translate}} </span>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <mat-spinner diameter="20"></mat-spinner> {{'Loading...' | translate}}
      </ng-container>
    </ng-template>
  </button>
</form>
