<div class="modal modal-choice">
  <div class="modal-header">
    <span i18n="@@ACESSREMOVAL">ACESS REMOVAL</span>
    <app-button-extra [type]="'icon'">
      <i [mat-dialog-close]="false" class="ic-x-sm ic-cancel"></i>
    </app-button-extra>
  </div>
  <div class="modal-body">
    <p i18n="@@Areyousureyouwanttoremove?">Are you sure you want to remove?</p>
  </div>
  <div class="modal-footer">
    <button mat-button="" [mat-dialog-close]="false" class="btn-md btn-light">
      CANCEL
    </button>
    <button mat-button="" [mat-dialog-close]="true" class="btn-md">REMOVE</button>
  </div>
</div>
