<ng-container *ngIf="type === 'home'">
  <button mat-button="" class="btn-extra btn-md btn-secondary btn-icon">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="type === 'work'">
  <button mat-button="" class="btn-extra btn-outline-md btn-icon">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="type === 'icon'">
  <button mat-button="" class="btn-extra btn-clear-md btn-icon">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="type === 'best-friends'">
  <button mat-button="" class="btn-extra btn-outline-md btn-outline-secondary btn-icon">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="type === 'remove'">
  <button mat-button="" class="btn-extra btn-md btn-danger btn-icon">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="type === 'tools'">
  <button mat-button="" class="btn-extra btn-md btn-dark btn-icon">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="type === 'minus'">
  <button mat-button="" class="btn-extra btn-outline btn-outline-danger btn-icon">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>
<ng-template #contentTpl=""><ng-content></ng-content></ng-template>
