import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from 'src/app/core/models/action.model';

@Component({
  selector: 'app-oa-questionnaire',
  templateUrl: './oa-questionnaire.component.html',
  styleUrls: ['./oa-questionnaire.component.scss', '../oa-styles.scss'],
})
export class OaQuestionnaireComponent implements OnInit {
  @Input() action!: Action;
  @Output() changed = new EventEmitter<boolean>();

  ngOnInit(): void {
    console.log('[OA QUESTIONNAIRE] ngOnInit', this.action);
  }

  checkDependsOn() {
    return !(
      (this.action.prstatus as any) === 'DONE' ||
      (this.action.prstatus as any) === null
    );
  }
}
