<section id="news" class="gap-container">
  <app-display-large >{{'News' | translate}}</app-display-large>

  <div class="content" *ngIf="!loadingNews">
    <div class="news-list">
      <div
        *ngFor="let item of news; let i = index"
        class="news-item"
        [class.full]="i === 0"
      >
        <card-news [news]="item" [isDark]="i === 0"></card-news>
      </div>
    </div>
    <div class="more-news">
      <h3 class="other-title" >{{'OtherNews' | translate}}</h3>
      <img class="img" src="assets/img/other-news.svg" alt="" />

      <div class="bullet" >{{'MishcondeReya' | translate}}</div>
      <h3
        class="other-subtitle"
        
      >
       {{'Wewillsoonbemakingmorefreshnewsavailabletoyou' | translate}}
      </h3>

      <ul class="list">
        <li class="item" *ngFor="let item of otherNews">
          <p class="other-item-title">{{ item.metadata.title }}</p>
          <button class="go-btn">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</section>
