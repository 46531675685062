import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { News } from 'src/app/core/models/news';
import { NewsService } from 'src/app/core/services/news.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss'],
})
export class NewsDetailsComponent implements OnInit {
  news?: News;

  otherNews: News[] = [];
  currentLang: string = 'en-US';

  constructor(
    private newsService: NewsService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this._getCurrentLang();

    this.translateService.onLangChange.subscribe(() => {
      this._getCurrentLang();
    });

    const newsId = this.route.snapshot.paramMap.get('id');
    if (newsId) {
      this.news = await this.newsService.get(newsId);
      const newsBatch: News[] = await this.newsService.getByRelevance(4);
      this.otherNews = newsBatch.filter((e) => e.id != newsId);
    }
  }

  private _getCurrentLang() {
    this.currentLang =
      this.translateService.currentLang.toLowerCase() == 'pt'
        ? 'pt-BR'
        : 'en-US';
  }

  goToNews(news: News) {
    if (news.metadata.link) {
      window.location.href = news.metadata.link;
    } else {
      this.router.navigate([`client/news/${news.id}`]);
    }
  }
}
