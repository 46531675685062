<div class="case-description__container" *ngIf="case">
  <div class="case-description__container__content">
    <img [src]="case.configuration.images.high_res" [alt]="case.case_type  | translate" />
    <div class="row">
      <span class="circle red"></span>
      <span>{{ case.case_type | translate }}</span>
    </div>
    <h4>{{ case.summary }}</h4>
    <!-- <h5>
      Claim up to
      <span class="case-description__container__content--value">
        $ 177,000</span
      >
    </h5> -->
    <p>
      <markdown>{{ case.description_clients }}</markdown>
    </p>
  </div>
</div>
