<section id="login">
  <div class="left-image">
    <img src="/assets/img/mdr-Logo.svg" alt="" class="logo-left" />
    <img src="/assets/img/login-left-img.svg" alt="" class="img-left" />
  </div>
  <div class="login-container">
    <form
      class="login-form"
      [formGroup]="form"
      *ngIf="form && !mfaNeeded && !emailCodeNeeded && !magicLinkCodeNeeded"
    >
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <h5 class="title">{{'introductionHeader' | translate}}</h5>
      <div class="form-group-login">
        <app-shared-form-input-text
          formControlName="email"
          type="email"
          name="email"
          required=""
          email=""
          label="{{'Email' | translate}}"
        ></app-shared-form-input-text>
        <app-shared-form-input-text
          formControlName="password"
          type="password"
          [passwordInput]="true"
          required=""
          name="password"
          label="{{'Password' | translate}}"
        ></app-shared-form-input-text>

        <p
          class="link forgot-password"
          routerLink="/auth/forgot"
        >
        {{'forgotPassword' | translate}}

        </p>

        <mat-checkbox class="remember" 
          >        {{'loginRememberMe' | translate}}</mat-checkbox
        >

        <button
          class="submit-btn"
          type="submit"
          [disabled]="isLoading || form.invalid"
          (click)="onSubmit()"
        >
          <ng-container *ngIf="!isLoading" 
            >{{'loginLoginBtn' | translate}}</ng-container
          >
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </ng-container>
        </button>

        <p>
          <span >
            {{'Lookingforawaytoregister' | translate}}
          </span>
          <a href="https://onecoinvictims.com" class="link">
           {{'Clickhere' | translate}}            </a>
        </p>
      </div>
      <div class="styled-divider" style="display: flex">
        <hr />
        or
        <hr />
      </div>
      <p class="mb" >
        {{'Alreadyaclient'|translate}}
        
      </p>
      <div class="form-group-login">
        <app-shared-form-input-text
          formControlName="magiclink"
          type="email"
          name="magiclink"
          email=""
          label="{{'LoginLabelMagicLink' | translate}}"

        ></app-shared-form-input-text>
        <button
          class="submit-btn"
          type="submit"
          [disabled]="
            isLoading ||
            form.controls['magiclink'].invalid ||
            !form.controls['magiclink'].value
          "
          (click)="sendMagicLink()"
        >
          <div *ngIf="!isLoading" >
            {{'GetOneTimeText' | translate}}
          </div>
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </ng-container>
        </button>
      </div>
    </form>

    <form class="mfa-form" [formGroup]="mfaForm" *ngIf="mfaForm && mfaNeeded">
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <h5 class="title" >{{'MFAcodechallenger' | translate}}</h5>
      <p
        class="desc"
       
      >
      {{'Foraddedsecurity,pleaseverifyyouridentityusingMulti-FactorAuthentication(MFA).Enterthecodegeneratedbyyourauthenticatorappbelowtoproceed.' | translate}}
      
      </p>
      <div class="form-group-login">
        <app-shared-form-code-challenger
          formControlName="code"
          type="code"
          name="code"
          label="MFA Code"
        ></app-shared-form-code-challenger>

        <button
          class="submit-btn mfa-btn"
          [disabled]="isLoading || form.invalid"
          (click)="submitMfa()"
        >
          <ng-container *ngIf="!isLoading">Sign In</ng-container>
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
            Loading...
          </ng-container>
        </button>
      </div>
    </form>

    <form class="mfa-form" [formGroup]="emailCodeForm" *ngIf="emailCodeNeeded">
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <h5 class="title">{{'EmailVerification' | translate}}</h5>
      <p
        class="desc"

      >
      {{'Pleasevalidateyouraccountbytypinginthecodeyoureceivedonyouremail.' | translate}}

      </p>
      <div class="form-group-login">
        <app-shared-form-code-challenger
          formControlName="emailCode"
          type="code"
          name="emailCode"
          label="Email verification code"
        ></app-shared-form-code-challenger>

        <button
          class="submit-btn mfa-btn"
          [disabled]="isLoading"
          (click)="submitCode()"
        >
          <ng-container *ngIf="!isLoading">Sign In</ng-container>
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
            Loading...
          </ng-container>
        </button>
      </div>
    </form>

    <form
      class="mfa-form"
      [formGroup]="magicLinkCodeForm"
      *ngIf="magicLinkCodeNeeded"
    >
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <h5 class="title" >{{'One-Timeaccesslink' | translate}}</h5>
      <p
        class="desc"

      >
      {{'Pleasevalidateyouraccountbytypinginthecodeyoureceivedonyouremail.' | translate}}

      </p>
      <div class="form-group-login">
        <app-shared-form-code-challenger
          formControlName="magicLinkCode"
          type="code"
          name="MagicLinkCode"
          label="MagicLink verification code"
        ></app-shared-form-code-challenger>

        <button
          class="submit-btn mfa-btn"
          [disabled]="isLoading"
          (click)="submitMagicLink()"
        >
          <ng-container *ngIf="!isLoading">{{'SignIn' | translate}}</ng-container>
          <ng-container *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
            {{'Loading...' | translate}}
          </ng-container>
        </button>
      </div>
    </form>
  </div>
</section>
