<div mat-dialog-content="" class="mat-typography">
  <div class="container-img-close">
    <svg
      mat-button=""
      mat-dialog-close=""
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 8.12644L1.36782 13.7586C1.2069 13.9195 1.01916 14 0.804598 14C0.590038 14 0.402299 13.9195 0.241379 13.7586C0.0804597 13.5977 0 13.41 0 13.1954C0 12.9808 0.0804597 12.7931 0.241379 12.6322L5.87356 7L0.241379 1.36782C0.0804597 1.2069 0 1.01916 0 0.804598C0 0.590038 0.0804597 0.402299 0.241379 0.241379C0.402299 0.0804597 0.590038 0 0.804598 0C1.01916 0 1.2069 0.0804597 1.36782 0.241379L7 5.87356L12.6322 0.241379C12.7931 0.0804597 12.9808 0 13.1954 0C13.41 0 13.5977 0.0804597 13.7586 0.241379C13.9195 0.402299 14 0.590038 14 0.804598C14 1.01916 13.9195 1.2069 13.7586 1.36782L8.12644 7L13.7586 12.6322C13.9195 12.7931 14 12.9808 14 13.1954C14 13.41 13.9195 13.5977 13.7586 13.7586C13.5977 13.9195 13.41 14 13.1954 14C12.9808 14 12.7931 13.9195 12.6322 13.7586L7 8.12644Z"
        fill="var(--color-neutral-60)"
      ></path>
    </svg>
  </div>

  <div draggable="true" *ngIf="!boxFile" class="container-input">
    <h2 mat-dialog-title="" i18n="@@UploadDocument">Upload Document</h2>
    <div>
      <img class="icon-img" src="../../../assets/icons/Vectorupload.svg" />
      <h5 i18n="@@Selecta.pdfor.jpgfile">Select a .pdf or .jpg file</h5>
    </div>
    <label for="input-file" i18n="@@UPLOADAFILE"> UPLOAD A FILE </label>
    <input id="input-file" type="file" (change)="fileChange($event)" />
  </div>

  <div *ngIf="renderFile" class="container-input">
    <div
      *ngIf="renderFile && !isPdf"
      class="background"
      [style.background]="backgroundImage"
    >
      <img class="render" [src]="renderFile" alt="" />
    </div>
    <pdf-viewer
      *ngIf="renderFile && isPdf"
      [src]="renderFile"
      [render-text]="true"
      [original-size]="false"
      [show-all]="false"
      style="margin-top: 10px; overflow: hidden"
    ></pdf-viewer>

    <button (click)="uploadFile()" id="continue">CONTINUE</button>
  </div>

  <div *ngIf="sendFile" class="container-input">
    <h2 mat-dialog-title="" i18n="@@UploadingDocument...">
      Uploading Document...
    </h2>
    <div>
      <img class="icon-img spinning" src="../../../assets/icons/loading.svg" />
    </div>
    <div class="name-file">
      <h5>{{ nameFile }}</h5>
    </div>
  </div>

  <div *ngIf="successFile" class="container-input">
    <h2 class="successText" i18n="@@Success">Success</h2>
    <div>
      <h5 i18n="@@Thankyou,yourdocumentwassuccessfullyuploaded.">
        Thank you, your document was successfully uploaded.
      </h5>
    </div>
    <button (click)="close()" class="success">CLOSE WINDOW</button>
  </div>

  <div *ngIf="errorFile" class="container-input">
    <h2 class="errorText" i18n="@@UploadError">Upload Error</h2>
    <div>
      <h5
        i18n="@@Wecouldn'tuploadyourfile.Pleasecheckyourfileformatandtryagain."
      >
        We couldn't upload your file. Please check your file format and try
        again.
      </h5>
    </div>
    <button (click)="close()" class="error">CLOSE WINDOW</button>
  </div>
</div>
