<section id="news" class="gap-container">
  <app-display-large>{{ "News" | translate }}</app-display-large>

  <div class="content" *ngIf="!loadingNews">
    <div class="news-list">
      <div
        *ngFor="let item of news; let i = index"
        class="news-item"
        [class.full]="i === 0"
      >
        <card-news
          [news]="item"
          [isDark]="i === 0"
          (clickNews)="goToNews($event)"
        ></card-news>
      </div>
    </div>
    <div class="more-news">
      <app-more-news [otherNews]="otherNews"></app-more-news>
    </div>
  </div>
</section>
