<div class="row">
  <h4 class="font-helvetica" i18n="@@Colors">Colors</h4>
</div>
<div class="row">
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Priority1">Priority 1</div>
      <div class="card-body" style="background-color: var(--color-black)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Priority2">Priority 2</div>
      <div class="card-body" style="background-color: var(--color-priority-2)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Priority3">Priority 3</div>
      <div class="card-body" style="background-color: var(--color-primary-30)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Priority4">Priority 4</div>
      <div class="card-body" style="background-color: var(--color-tertiary-60)"></div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Priority5">Priority 5</div>
      <div class="card-body" style="background-color: var(--color-white)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Priority6">Priority 6</div>
      <div class="card-body" style="background-color: var(--color-neutral-60)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@SecondaryBlue">Secondary Blue</div>
      <div class="card-body" style="background-color: var(--color-tertiary-40)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@TertiaryBlue">Tertiary Blue</div>
      <div class="card-body" style="background-color: var(--color-tertiary-blue)"></div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@SupportBlue">Support Blue</div>
      <div class="card-body" style="background-color: var(--color-support-blue)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Sucess">Sucess</div>
      <div class="card-body" style="background-color: var(--color-sucess)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@warning">warning</div>
      <div class="card-body" style="background-color: var(--color-warning)"></div>
    </div>
  </div>
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Danger">Danger</div>
      <div class="card-body" style="background-color: var(--color-primary-20)"></div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2 col-md-4 col-sm-8">
    <div class="card">
      <div class="card-title" i18n="@@Disabled">Disabled</div>
      <div class="card-body" style="background-color: var(--color-disabled)"></div>
    </div>
  </div>
</div>
