<ng-container *ngIf="type === 'light'">
  <button mat-button="" class="btn-rounded btn-rounded-light">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'secondary'">
  <button mat-button="" class="btn-rounded btn-rounded-outline">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'danger'">
  <button mat-button="" class="btn-rounded btn-rounded-danger">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type === 'dark'">
  <button mat-button="" class="btn-rounded btn-rounded-dark">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-container *ngIf="type == undefined">
  <button mat-button="" class="btn-rounded btn-rounded-default">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-template #contentTpl=""><ng-content></ng-content></ng-template>
