<div class="container">
  <div class="header">
    <mat-icon class="title-icon">error_outline</mat-icon>
    <h5 class="title" >{{'Important' | translate}}
</h5>
  </div>
  <p
    class="text"
    
  >
    {{'AreyousureyouwanttodisableMulti-FactorAuthentication(MFA)?Thiswillremoveanadditionallayerofsecurityfromyouraccount.Proceedwithcaution.IfyoudisableMFA,youraccountmaybecomemorevulnerabletounauthorizedaccess.OnlydisableMFAifyouareabsolutelycertain.' | translate}}
  </p>

  <mat-checkbox [(ngModel)]="confirmed" color="primary"
    >{{'Iagree' | translate}}</mat-checkbox
  >

  <div class="action-btns">
    <button
      class="btn-act cancel"
      (click)="matDialogRef.close({ remove: false })"
    >
      Cancel
    </button>
    <button
      class="btn-act confirm"
      [disabled]="!confirmed"
      (click)="matDialogRef.close({ remove: true })"
    >
      Confirm
    </button>
  </div>
</div>
