<div class="card-modal">
  <div class="header">
    <h2 class="title" >
      
      {{'WelcometotheGroupActionsPortal' | translate}}
    </h2>
    <button mat-icon-button="" (click)="dialog.close()">
      <mat-icon> close </mat-icon>
    </button>
  </div>
  <hr />
  <img class="img-logo" src="assets/img/mdr-Logo.svg" alt="" />
  <span class="sub-logo"
    >It's business. But it's personal.</span
  >

  <p
    class="header-text"
  >
    {{'Helloandwelcome!We\'redelightedtohaveyouonboard.' | translate}}
  </p>
  <p
    class="header-text"
   
  >
  {{'InyourpersonalisedPortal,you\'llfindalltheessentialinformationyouneed.Thisincludes:' | translate}}

  </p>
  <ul class="list">
    <li
   
    >
     {{'Importantinformation:stayupdatedwiththelatestdetailsrelevanttoyourcase.' | translate}}
    </li>
    <li>
      {{'Notifications:receivetimelyalertsandreminders.' | translate}}
    </li>
    <li >
      {{'Messages:accessandrespondtomessagesfromourteam.' | translate}}
    </li>
    <li
      
    >
      {{'OutstandingActions:viewandcompleteanypendingtaskstokeepyourclaimmovingforward.' | translate}}
    </li>
  </ul>
  <p
    class="footer-text"
    
  >
  {{'We\'reheretoassistyoueverystepoftheway.Ifyouhaveanyquestionsorneedsupport,pleasegetintouchwithus.' | translate}}
    
  </p>

  <button class="close-btn" (click)="dialog.close()">{{'Close' | translate}}</button>
</div>
