<div class="your-list-claim">
  <header>
    <mat-radio-group [(ngModel)]="status" (change)="changeStatus()" aria-label="Select an option">
      <mat-radio-button>All <span class="claim-text" >{{'Claims' | translate}}</span></mat-radio-button>
      <mat-radio-button value="active">Active <span class="claim-text" >{{'Claims' | translate}}</span></mat-radio-button>
      <mat-radio-button value="inactive">Inactive <span class="claim-text" >{{'Claims' | translate}}</span></mat-radio-button>
    </mat-radio-group>
  </header>

  <section *ngIf="claimsToShow">
    <ng-container *ngFor="let claim of claimsToShow">
      <app-block-claim [claim]="claim"></app-block-claim>
    </ng-container>
  </section>
</div>
