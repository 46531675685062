<div class="row">
  <h4 i18n="@@Accordion">Accordion</h4>
</div>
<div class="row">
  <div class="col-4 col-sm-8">
    <mat-accordion>
      <mat-expansion-panel class="single" hideToggle="">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex align-itens-center">
              <i class="ic-sm ic-content"></i>
              <h6 i18n="@@HowcanIaccessthenewpage?">
                How can I access the new page?
              </h6>
            </div>
            <i id="icon" class="ic-sm ic-arrow-left"></i>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p i18n="@@teste">teste</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="row">
  <div class="col-4 col-sm-8">
    <mat-accordion>
      <mat-expansion-panel class="multi" hideToggle="" multi="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex align-itens-center">
              <h6 i18n="@@Claims">Claims</h6>
            </div>
            <mat-icon id="icon">expand_more</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent="">
          <mat-accordion>
            <mat-expansion-panel class="item" hideToggle="" multi="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="flex align-itens-center">
                    <h6 i18n="@@BHM">BHM</h6>
                  </div>
                  <mat-icon id="icon">expand_more</mat-icon>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p i18n="@@TESTE">TESTE</p>
            </mat-expansion-panel>
            <mat-expansion-panel class="item" hideToggle="" multi="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="flex align-itens-center">
                    <h6 i18n="@@BHM">BHM</h6>
                  </div>
                  <mat-icon id="icon">expand_more</mat-icon>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p i18n="@@TESTE">TESTE</p>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
