<!-- <div *ngFor="let c of comms">
  {{ c.subject }}
</div> -->

<button
  mat-menu-item=""
  *ngFor="let note of comms"
  [class.highlight]="
    note.cp_message![note.cp_message!.length - 1].user_reads!.length === 0
  "
  routerLink="/client/thread/{{ note.id }}"
>
  <div class="btn-content">
    <div class="icont">
      <span
        *ngIf="
          note.cp_message![note.cp_message!.length - 1].user_reads!.length === 0
        "
        class="circle-topic"
      ></span>
      <mat-icon>mail_outline</mat-icon>
    </div>
    <div class="text">
      <h6>{{ note.subject }}</h6>
      <p [innerHTML]="note.cp_message![note.cp_message!.length - 1].value"></p>
    </div>
    <div class="caret">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</button>

<p *ngIf="comms?.length == 0" >{{'Nomessages.' | translate}}</p>
