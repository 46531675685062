<div class="row">
  <h4 class="font-helvetica" i18n="@@Icons">Icons</h4>
</div>
<div class="row">
  <h5 i18n="@@Small">Small</h5>
  <div class="col-8">
    <table>
      <tr>
        <td><span class="ic-sm ic-home"></span></td>
        <td><span class="ic-sm ic-user"></span></td>
        <td><span class="ic-sm ic-plugin"></span></td>
        <td><span class="ic-sm ic-content"></span></td>
        <td><span class="ic-sm ic-exit"></span></td>
        <td><span class="ic-sm ic-star"></span></td>
        <td><span class="ic-sm ic-upload"></span></td>
        <td><span class="ic-sm ic-mark"></span></td>
        <td><span class="ic-sm ic-group"></span></td>
      </tr>
      <tr>
        <td><span class="ic-sm ic-arrow-down"></span></td>
        <td><span class="ic-sm ic-arrow-up"></span></td>
        <td><span class="ic-sm ic-arrow-right"></span></td>
        <td><span class="ic-sm ic-arrow-left"></span></td>
        <td><span class="ic-sm ic-minus"></span></td>
        <td><span class="ic-sm ic-plus"></span></td>
        <td><span class="ic-sm ic-question"></span></td>
        <td><span class="ic-sm ic-click"></span></td>
        <td><span class="ic-sm ic-bell"></span></td>
      </tr>
      <tr>
        <td><span class="ic-sm ic-forward"></span></td>
        <td><span class="ic-sm ic-bell-rd"></span></td>
        <td><span class="ic-sm ic-confirm-rd"></span></td>
        <td><span class="ic-sm ic-alert"></span></td>
        <td><span class="ic-sm ic-refresh-rd"></span></td>
        <td><span class="ic-sm ic-search"></span></td>
        <td><span class="ic-sm ic-3-line"></span></td>
        <td><span class="ic-sm ic-2-line"></span></td>
        <td><span class="ic-sm ic-bar-chart"></span></td>
      </tr>
      <tr>
        <td><span class="ic-sm ic-linkedin"></span></td>
        <td><span class="ic-sm ic-instagram"></span></td>
        <td><span class="ic-sm ic-facebook"></span></td>
        <td><span class="ic-sm ic-twitter"></span></td>
        <td><span class="ic-sm ic-refresh"></span></td>
        <td><span class="ic-sm ic-dump"></span></td>
        <td><span class="ic-sm ic-telephone"></span></td>
        <td><span class="ic-sm ic-enter"></span></td>
        <td><span class="ic-sm ic-edit"></span></td>
      </tr>
      <tr>
        <td><span class="ic-sm ic-mail-box"></span></td>
        <td><span class="ic-sm ic-mail-box-notification"></span></td>
        <td><span class="ic-sm ic-config"></span></td>
        <td><span class="ic-sm ic-3-point"></span></td>
        <td><span class="ic-sm ic-link"></span></td>
        <td><span class="ic-sm ic-login-user"></span></td>
        <td><span class="ic-sm ic-institution"></span></td>
        <td><span class="ic-sm ic-clipboard"></span></td>
        <td><span class="ic-sm ic-share"></span></td>
      </tr>
      <tr>
        <td><span class="ic-sm ic-cancel"></span></td>
        <td><span class="ic-sm ic-confirm"></span></td>
        <td><span class="ic-sm ic-hammer"></span></td>
        <td><span class="ic-sm ic-calendar"></span></td>
        <td><span class="ic-sm ic-clock"></span></td>
        <td><span class="ic-sm ic-bell-notification"></span></td>
        <td><span class="ic-sm ic-cancel-rd"></span></td>
        <td><span class="ic-sm ic-map-point"></span></td>
        <td><span class="ic-sm ic-download"></span></td>
      </tr>
    </table>
  </div>
</div>
<div class="row">
  <h5 i18n="@@Medium">Medium</h5>
  <div class="col-8">
    <table>
      <tr>
        <td><span class="ic-md ic-home"></span></td>
        <td><span class="ic-md ic-user"></span></td>
        <td><span class="ic-md ic-plugin"></span></td>
        <td><span class="ic-md ic-content"></span></td>
        <td><span class="ic-md ic-exit"></span></td>
        <td><span class="ic-md ic-star"></span></td>
        <td><span class="ic-md ic-upload"></span></td>
        <td><span class="ic-md ic-mark"></span></td>
        <td><span class="ic-md ic-group"></span></td>
      </tr>
      <tr>
        <td><span class="ic-md ic-arrow-down"></span></td>
        <td><span class="ic-md ic-arrow-up"></span></td>
        <td><span class="ic-md ic-arrow-right"></span></td>
        <td><span class="ic-md ic-arrow-left"></span></td>
        <td><span class="ic-md ic-minus"></span></td>
        <td><span class="ic-md ic-plus"></span></td>
        <td><span class="ic-md ic-question"></span></td>
        <td><span class="ic-md ic-click"></span></td>
        <td><span class="ic-md ic-bell"></span></td>
      </tr>
      <tr>
        <td><span class="ic-md ic-forward"></span></td>
        <td><span class="ic-md ic-bell-rd"></span></td>
        <td><span class="ic-md ic-confirm-rd"></span></td>
        <td><span class="ic-md ic-alert"></span></td>
        <td><span class="ic-md ic-refresh-rd"></span></td>
        <td><span class="ic-md ic-search"></span></td>
        <td><span class="ic-md ic-3-line"></span></td>
        <td><span class="ic-md ic-2-line"></span></td>
        <td><span class="ic-md ic-bar-chart"></span></td>
      </tr>
      <tr>
        <td><span class="ic-md ic-linkedin"></span></td>
        <td><span class="ic-md ic-instagram"></span></td>
        <td><span class="ic-md ic-facebook"></span></td>
        <td><span class="ic-md ic-twitter"></span></td>
        <td><span class="ic-md ic-refresh"></span></td>
        <td><span class="ic-md ic-dump"></span></td>
        <td><span class="ic-md ic-telephone"></span></td>
        <td><span class="ic-md ic-enter"></span></td>
        <td><span class="ic-md ic-edit"></span></td>
      </tr>
      <tr>
        <td><span class="ic-md ic-mail-box"></span></td>
        <td><span class="ic-md ic-mail-box-notification"></span></td>
        <td><span class="ic-md ic-config"></span></td>
        <td><span class="ic-md ic-3-point"></span></td>
        <td><span class="ic-md ic-link"></span></td>
        <td><span class="ic-md ic-login-user"></span></td>
        <td><span class="ic-md ic-institution"></span></td>
        <td><span class="ic-md ic-clipboard"></span></td>
        <td><span class="ic-md ic-share"></span></td>
      </tr>
      <tr>
        <td><span class="ic-md ic-cancel"></span></td>
        <td><span class="ic-md ic-confirm"></span></td>
        <td><span class="ic-md ic-hammer"></span></td>
        <td><span class="ic-md ic-calendar"></span></td>
        <td><span class="ic-md ic-clock"></span></td>
        <td><span class="ic-md ic-bell-notification"></span></td>
        <td><span class="ic-md ic-cancel-rd"></span></td>
        <td><span class="ic-md ic-map-point"></span></td>
        <td><span class="ic-md ic-download"></span></td>
      </tr>
    </table>
  </div>
</div>
<div class="row">
  <h5 i18n="@@Large">Large</h5>
  <div class="col-8">
    <table>
      <tr>
        <td><span class="ic-lg ic-home"></span></td>
        <td><span class="ic-lg ic-user"></span></td>
        <td><span class="ic-lg ic-plugin"></span></td>
        <td><span class="ic-lg ic-content"></span></td>
        <td><span class="ic-lg ic-exit"></span></td>
        <td><span class="ic-lg ic-star"></span></td>
        <td><span class="ic-lg ic-upload"></span></td>
        <td><span class="ic-lg ic-mark"></span></td>
        <td><span class="ic-lg ic-group"></span></td>
      </tr>
      <tr>
        <td><span class="ic-lg ic-arrow-down"></span></td>
        <td><span class="ic-lg ic-arrow-up"></span></td>
        <td><span class="ic-lg ic-arrow-right"></span></td>
        <td><span class="ic-lg ic-arrow-left"></span></td>
        <td><span class="ic-lg ic-minus"></span></td>
        <td><span class="ic-lg ic-plus"></span></td>
        <td><span class="ic-lg ic-question"></span></td>
        <td><span class="ic-lg ic-click"></span></td>
        <td><span class="ic-lg ic-bell"></span></td>
      </tr>
      <tr>
        <td><span class="ic-lg ic-forward"></span></td>
        <td><span class="ic-lg ic-bell-rd"></span></td>
        <td><span class="ic-lg ic-confirm-rd"></span></td>
        <td><span class="ic-lg ic-alert"></span></td>
        <td><span class="ic-lg ic-refresh-rd"></span></td>
        <td><span class="ic-lg ic-search"></span></td>
        <td><span class="ic-lg ic-3-line"></span></td>
        <td><span class="ic-lg ic-2-line"></span></td>
        <td><span class="ic-lg ic-bar-chart"></span></td>
      </tr>
      <tr>
        <td><span class="ic-lg ic-linkedin"></span></td>
        <td><span class="ic-lg ic-instagram"></span></td>
        <td><span class="ic-lg ic-facebook"></span></td>
        <td><span class="ic-lg ic-twitter"></span></td>
        <td><span class="ic-lg ic-refresh"></span></td>
        <td><span class="ic-lg ic-dump"></span></td>
        <td><span class="ic-lg ic-telephone"></span></td>
        <td><span class="ic-lg ic-enter"></span></td>
        <td><span class="ic-lg ic-edit"></span></td>
      </tr>
      <tr>
        <td><span class="ic-lg ic-mail-box"></span></td>
        <td><span class="ic-lg ic-mail-box-notification"></span></td>
        <td><span class="ic-lg ic-config"></span></td>
        <td><span class="ic-lg ic-3-point"></span></td>
        <td><span class="ic-lg ic-link"></span></td>
        <td><span class="ic-lg ic-login-user"></span></td>
        <td><span class="ic-lg ic-institution"></span></td>
        <td><span class="ic-lg ic-clipboard"></span></td>
        <td><span class="ic-lg ic-share"></span></td>
      </tr>
      <tr>
        <td><span class="ic-lg ic-cancel"></span></td>
        <td><span class="ic-lg ic-confirm"></span></td>
        <td><span class="ic-lg ic-hammer"></span></td>
        <td><span class="ic-lg ic-calendar"></span></td>
        <td><span class="ic-lg ic-clock"></span></td>
        <td><span class="ic-lg ic-bell-notification"></span></td>
        <td><span class="ic-lg ic-cancel-rd"></span></td>
        <td><span class="ic-lg ic-map-point"></span></td>
        <td><span class="ic-lg ic-download"></span></td>
      </tr>
    </table>
  </div>
</div>
