<section class="faq">
  <div class="gap-container">
    <div class="faq-content">
      <h4 >{{'FrequentlyAskedQuestions' | translate}}</h4>

      <app-search-input></app-search-input>
      <!-- 
      <div class="faq-content__categories" *ngIf="!selectedCategory">
        <h6 class="faq-content__categories__subtitle">
          Also search by categories
        </h6>

        <ul class="faq-content__categories__list">
          <li
            class="faq-content__categories__list__item"
            *ngFor="let category of categoryList"
            (click)="selectCategory(category)"
          >
            <mat-icon class="faq-content__categories__list__item--icon"
              >{{ category.icon }}
            </mat-icon>
            {{ category.label }}
          </li>
        </ul>
      </div> -->

      <mat-accordion class="faq-content__questions__list">
        <mat-expansion-panel class="faq-content__questions__list__item" *ngFor="let faq of faqsToShow">
          <mat-expansion-panel-header class="faq-content__questions__list__item--header">
            {{ faq.question }}
          </mat-expansion-panel-header>
          <markdown>
            {{ faq.answer }}
          </markdown>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</section>
