<div class="container">
  <mat-form-field [formGroup]="numberForm">
    <input formControlName="number" (change)="change()" matInput="" type="number" placeholder="Insert a value" />
    <mat-error *ngIf="
        numberForm.controls.number.invalid && numberForm.controls.number.dirty
      " i18n="@@Checknumberinformed">Check number informed</mat-error>
  </mat-form-field>

  <p class="custom-error" *ngIf="
      numberForm.controls.number.dirty &&
      numberForm.controls.number.valid &&
      !question.isValid
    " i18n="@@Numberinvalidaccordingtootherquestion">
    Number invalid according to other question
  </p>
</div>
