<div class="image-upload">
  <div class="no-upload" *ngIf="!fileSrc" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
    <img src="assets/img/upload-image.svg" alt="" />
    <p class="text" i18n="@@Draganddropyourimagehere">{{"Drag and drop your image here"  | translate}}</p>
    <p class="text" i18n="@@or">{{"or" | translate}}</p>
    <button class="upload-btn" (click)="inputfile.click()">
      {{"Upload a file" | translate}}
    </button>
  </div>
  <div class="has-upload" *ngIf="!!fileSrc" [class.vertical]="deleteButtonDisposition === 'vertical'" [class.horizontal]="deleteButtonDisposition === 'horizontal'">
    <div class="img-content">
      <img [style.maxHeight]="maxHeight ?? 'auto'" [src]="fileSrc" alt="" />
    </div>
    <div class="remove-content">
      <h3 class="subtitle" *ngIf="hasTitleDeleteImg" i18n="@@Imageuploaded!">Image uploaded!</h3>
      <button class="remove-btn" (click)="removeFile()">{{"Remove"| translate}}</button>
    </div>
  </div>
  <input type="file" class="upload-input" #inputfile="" (change)="onFileChange($event)" accept="image/*" />
</div>
