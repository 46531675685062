<!-- <div class="item">
  <button
    (click)="uploadDialog()"
    class="label"
    [disabled]="checkDependsOn() || action.ac_status === 'DONE'"
  >
    <img
      class="icon"
      src="../../../../../../assets/icons/upload_ID.png"
      alt=""
     />
    <span class="oa-title"
      >{{ action.action_name }}
      <img
        *ngIf="checkDependsOn() || action.ac_status !== 'DONE'"
        src="../../../../../../../../assets/icons/Tag_to_do.svg"
        alt=""
       />
    </span>
  </button>

  <div *ngIf="checkDependsOn()" class="actions">
    <mat-icon class="info" [matTooltip]="action.description || ''"
      >info</mat-icon
    >
  </div>

  <div *ngIf="!checkDependsOn() && action.ac_status === 'DONE'" class="actions">
    <mat-icon class="success">done</mat-icon>
  </div>
</div> -->

<!--  -->
<div [ngSwitch]="state">
  <div *ngSwitchCase="'LOADING'">
    <div class="item">
      <button class="label" [disabled]="true">
        <img
          class="icon"
          src="../../../../../../assets/icons/upload_ID.png"
          alt=""
        />
        <span class="oa-title disabled">{{ action.action_name }} </span>
      </button>

      <div class="actions">
        <mat-spinner [mode]="'indeterminate'" [diameter]="20"></mat-spinner>
      </div>
    </div>
  </div>

  <!--  -->

  <div *ngSwitchCase="'BLOCKED'">
    <div class="item">
      <button class="label" disabled="">
        <img
          class="icon"
          src="../../../../../../assets/icons/upload_ID.png"
          alt=""
        />
        <span class="oa-title disabled">{{ action.action_name }} </span>
      </button>

      <div class="actions">
        <mat-icon
          class="info"
          [matTooltip]="
            action.description ||
            'Please complete the other outstanding actions before proceeding with this one.'
          "
          >info</mat-icon
        >
      </div>
    </div>
  </div>

  <!--  -->

  <div *ngSwitchCase="'PENDING'">
    <div class="item">
      <button class="label" (click)="uploadDialog()">
        <img
          class="icon"
          src="../../../../../../assets/icons/upload_ID.png"
          alt=""
        />
        <span class="oa-title"
          >{{ action.action_name }}
          <img src="../../../../../../../../assets/icons/Tag_to_do.svg" alt=""
        /></span>
      </button>
    </div>
  </div>

  <!--  -->

  <div *ngSwitchCase="'IN_PROGRESS'">
    <div class="item">
      <button class="label" disabled="">
        <img
          class="icon"
          src="../../../../../../assets/icons/upload_ID.png"
          alt=""
        />
        <span class="oa-title disabled">{{ action.action_name }} </span>
      </button>

      <div class="actions">
        <mat-icon
          class="info"
          [matTooltip]="
            'Your information is being processed and should be ready in up to 10 minutes. Please refresh the page once this time has passed. If you have any issues, please contact us through the portal.' | translate
          "
          >info</mat-icon
        >
      </div>
    </div>
  </div>

  <!--  -->

  <div *ngSwitchCase="'DONE_OK'">
    <div class="item">
      <button class="label" disabled="">
        <img
          class="icon"
          src="../../../../../../assets/icons/upload_ID.png"
          alt=""
        />
        <span class="oa-title disabled">{{ action.action_name }} </span>
      </button>

      <div class="actions">
        <mat-icon class="success">done</mat-icon>
      </div>
    </div>
  </div>

  <!--  -->

  <div *ngSwitchCase="'DONE_FAIL'">
    <div class="item">
      <button class="label" (click)="uploadDialog()">
        <img
          class="icon"
          src="../../../../../../assets/icons/upload_ID.png"
          alt=""
        />
        <span class="oa-title"
          >{{ action.action_name }}
          <img src="../../../../../../../../assets/icons/Tag_to_do.svg" alt=""
        /></span>
      </button>

      <div class="actions">
        <mat-icon
          class="warning"
          [matTooltip]="
            'We could not verify your information with the documents you have previously provided. Please try again with other documents or improved pictures. If you have any trouble, please contact us through the portal.'
          "
          >warning</mat-icon
        >
      </div>
    </div>
  </div>

  <!--  -->
</div>
