import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientComponent } from './client.component';
import { CaseComponent } from './pages/case/case.component';
import { CasesComponent } from './pages/cases/cases.component';
import { ClaimComponent } from './pages/claim/claim.component';
import { CommunicationsComponent } from './pages/communications/communications.component';
import { DetailsCommunicationComponent } from './pages/communications/details-communication/details-communication.component';
import { NewCommunicationComponent } from './pages/communications/new-communication/new-communication.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { NewsDetailsComponent } from './pages/news/components/news-details/news-details.component';
import { NewsComponent } from './pages/news/news.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { QuestionnaireAnswerClientComponent } from './pages/questionnaire-answer-client/questionnaire-answer-client.component';
import { ThreadComponent } from './pages/thread/thread.component';

const routes: Routes = [
  {
    path: '',
    component: ClientComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'news', component: NewsComponent },
      { path: 'news/:id', component: NewsDetailsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'cases', component: CasesComponent },
      { path: 'home', component: HomeComponent },
      { path: 'cases/:id', component: CaseComponent },
      {
        path: 'claim/:id',
        component: ClaimComponent,
      },
      {
        path: 'messages',
        component: CommunicationsComponent,
      },
      {
        path: 'messages/:claim',
        component: CommunicationsComponent,
      },
      {
        path: 'messages/chat/:thread',
        component: DetailsCommunicationComponent,
      },
      // {
      //   path: 'messages/new/:new',
      //   component: NewCommunicationComponent,
      // },
      {
        path: 'messages/new/:claim',
        component: NewCommunicationComponent,
      },
      { path: 'thread/:id', component: ThreadComponent },
      {
        path: 'claim/:cid/q/:qcode',
        component: QuestionnaireAnswerClientComponent,
      },
      { path: 'faq', component: FaqComponent },
      { path: '**', redirectTo: 'home' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {}
