<div class="container">
  <div class="header">
    <mat-icon>cloud_upload</mat-icon>
    <p >{{'UploadDocument' | translate}}</p>
  </div>
  <div class="upload-box">
    <input
      type="file"
      #fileInput=""
      (change)="onFileSelected($event)"
      accept="image/png, image/jpeg"
      hidden=""
    />
    <div class="no-file" *ngIf="!imageData; else imagePreview">
      <mat-icon>cloud_upload</mat-icon>
      <p >{{'Dragyourfileanywherehere' | translate}}</p>
      <p >{{'or' | translate}}</p>
      <button (click)="fileInput.click()">{{'Upload' | translate}}</button>
    </div>
    <ng-template #imagePreview="">
      <img [src]="imageData.src" class="image-preview" />
    </ng-template>
  </div>
  <div class="uploaded">
    <p *ngIf="imageData" class="file-name">{{ imageData.name }}</p>
    <div class="action-btns">
      <button class="cancel" mat-button="" (click)="dialogRef.close(null)">
        {{'GoBack' | translate}}
      </button>
      <button
        *ngIf="imageData"
        class="confirm"
        mat-button=""
        (click)="dialogRef.close(imageData)"
      >
        {{'Confirm' | translate}}
      </button>
    </div>
  </div>
</div>
