import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material/material-module.module';
import { MarkdownModule } from 'ngx-markdown';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTooltipModule } from '@angular/material/tooltip';

import { register } from 'swiper/element/bundle';

register();

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
  DefaultDataServiceConfig,
  EntityDataModule,
  HttpUrlGenerator,
} from '@ngrx/data';
import { TokenInterceptor } from './core/interceptors/token.interceptors';

import { entityConfig } from './entity-metadata';

import { HttpClientModule } from '@angular/common/http';
import { AppHttpUrlGenerator } from './core/services/httpUrlGenerator.service';
import { ClientModule } from './modules/client/client.module';
import { DesignSampleModule } from './modules/design-sample/design-sample.module';
import { RootModule } from './modules/root/root.module';
import { ToastService, AngularToastifyModule } from 'angular-toastify';
import { QuestionnaireAnswerComponent } from './shared/components/examples/questionnaire-answer/questionnaire-answer.component';
import { InitialsPipe } from './shared/pipes/Initials.pipe';

@NgModule({
  declarations: [AppComponent, QuestionnaireAnswerComponent],
  imports: [
    AngularToastifyModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    AngularSignaturePadModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTooltipModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatTooltipModule,
    HttpClientModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    MatDialogModule,
    HttpClientModule,
    ClientModule,
    DesignSampleModule,
    RootModule,
  ],
  providers: [
    ToastService,
    MatDatepickerModule,
    MatNativeDateModule,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    HttpClientModule,
    { provide: HttpUrlGenerator, useClass: AppHttpUrlGenerator },
    { provide: DefaultDataServiceConfig, useValue: DefaultDataServiceConfig },
    HttpClient,
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
