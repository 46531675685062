<section class="questionnaire">
  <section class="questionnaire-header">
    <mat-progress-bar
      class="q-progress"
      mode="determinate"
      [value]="getProgressBarValue()"
    />
    <ng-container *ngIf="questionnaire">
      <h2 class="questionnaire-title">{{ atualPage()?.page?.name }}</h2>
      <p class="questionnaire-description">
        {{ questionnaire.name }}
      </p>
    </ng-container>
    <ng-container *ngIf="!questionnaire">
      <div class="loading-header">
        <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
      </div>
    </ng-container>
  </section>
  <section *ngIf="!(loading$ | async)?.loading" class="questionnaire-page">
    <ng-container *ngIf="questionnaire && navstack.length > 0">
      <ng-container *ngFor="let page of navstack">
        <ng-container *ngIf="page.isActive">
          <questionnaire-answer-page
            [page]="page.page"
            [questionnaireAnswer$]="getAnswersObservable()"
            (onChangeQuestionnaireAnswer)="onChangeQuestionnaireAnswer($event)"
            (onChangePageValid)="onChangePageValid($event)"
            (onLoadingPage)="onPageLoading($event)"
          />
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!(questionnaire && navstack.length > 0)">
      <div class="loading-page">
        <mat-spinner diameter="40" />
        <p>Loading...</p>
      </div>
    </ng-container>
  </section>
  <ng-container *ngIf="(loading$ | async)?.loading">
    <div class="loading-page">
      <mat-spinner diameter="40" />
      <p>Loading...</p>
    </div>
  </ng-container>
  <section class="questionnaire-footer" *ngIf="!(loading$ | async)?.loading">
    <div class="errors-content">
      <p *ngFor="let item of getErrorsList().page">
        <mat-icon>close</mat-icon> {{ item }}
      </p>
      <p *ngFor="let item of getErrorsList().global">
        <mat-icon>close</mat-icon> {{ item }}
      </p>
    </div>
    <div class="change-page-btns-content" *ngIf="!(loading$ | async)?.loading">
      <ng-container *ngIf="questionnaire">
        <button
          mat-stroked-button
          class="btn-outline-content"
          (click)="goPrev()"
          [disabled]="!isPreviousAllowed"
        >
          GO BACK
        </button>
        <button
          mat-raised-button
          class="btn-lg btn-dark"
          (click)="goNext()"
          [disabled]="!isNextAllowed || loadingPage"
        >
          {{ getProgressBarValue() === 100 ? "FINISH" : "NEXT" }}
        </button>
      </ng-container>
    </div>
  </section>
</section>
