<app-side-nav>
  <div class="row">
    <div class="col-2">
      <h4 >{{'Breadcrumb' | translate}}</h4>
      <div class="breadcrumb-group">
        <span class="previous-page" >{{'CLIENT' | translate}}</span>
        <i class="ic-x-sm ic-arrow-right"></i>
        <span class="current-page" >{{'CLIENTPROFILE' | translate}}</span>
      </div>
    </div>
  </div>

  <app-bottom-nav></app-bottom-nav>
</app-side-nav>
