<div class="kyc-modal">
  <div class="header" *ngIf="steps !== 'ERROR' && steps !== 'SUCCESS'">
    <div class="start">
      <mat-icon>
        {{
          steps === "TYPE-DOC"
            ? "description"
            : steps === "UPLOAD"
            ? "cloud_upload"
            : steps === "PROCESSING"
            ? "preview"
            : steps === "SUCCESS"
            ? "check_circle"
            : steps === "ERROR"
            ? "error"
            : ""
        }}
      </mat-icon>
      <h3 class="title">
        {{
          steps === "TYPE-DOC"
            ? "Select the type of document"
            : steps === "UPLOAD"
            ? "Upload your document - " + getDocumentTypeLabel(documentType)
            : steps === "PROCESSING"
            ? "Checking your document..."
            : steps === "SUCCESS"
            ? "Success"
            : steps === "ERROR"
            ? "Error"
            : ""
        }}
      </h3>
    </div>

    <div class="end">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
  </div>
  <ng-container *ngIf="steps === 'TYPE-DOC'">
    <div class="container typedoc">
      <input-text-select
        [(ngModel)]="documentType"
        id="doctype"
        name="doctype"
        label="Document Type"
        [options]="options"
      />
      <p class="text">
        Your identification documents will be reviewed using Thirdfort, our
        trusted third-party Know Your Customer (KYC) provider. Thirdfort
        specialises in the secure analysis and confirmation of personal data to
        ensure compliance with legal requirements and to protect against
        identity fraud. <br />
        Please be assured that your privacy is paramount to us, and all
        information will be processed in strict accordance with our Privacy
        Notice and applicable data protection laws.
      </p>
    </div>
    <div class="footer">
      <button class="action-btn cancel" (click)="close()">Cancel</button>
      <button class="action-btn save" (click)="nextStep()">Next</button>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'UPLOAD'">
    <div class="container upload">
      <ng-container *ngIf="documentType === DocumentType.PASSPORT">
        <app-input-upload-file
          class="single-upload"
          [fileSrc]="''"
          maxHeight="200px"
          [hasTitleDeleteImg]="false"
          [deleteButtonDisposition]="'vertical'"
          (file)="changeImage($event, 'front')"
        ></app-input-upload-file>
      </ng-container>
      <ng-container *ngIf="documentType !== DocumentType.PASSPORT">
        <div class="multiple-upload">
          <div class="side front">
            <h4 class="multiple-title" i18n="@@Frontimage">Front image</h4>
            <app-input-upload-file
              class="image-upload"
              [fileSrc]="''"
              maxHeight="180px"
              [hasTitleDeleteImg]="false"
              [deleteButtonDisposition]="'vertical'"
              (file)="changeImage($event, 'front')"
            ></app-input-upload-file>
          </div>
          <div class="side back">
            <h4 class="multiple-title" i18n="@@Backimage">Back image</h4>
            <app-input-upload-file
              class="image-upload"
              [fileSrc]="''"
              maxHeight="180px"
              [hasTitleDeleteImg]="false"
              [deleteButtonDisposition]="'vertical'"
              (file)="changeImage($event, 'back')"
            ></app-input-upload-file>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="footer">
      <button class="action-btn cancel" (click)="backStep()">Back</button>
      <button
        class="action-btn save"
        [disabled]="
          (documentType === DocumentType.PASSPORT && !frontImage) ||
          (documentType !== DocumentType.PASSPORT &&
            (!frontImage || !backImage))
        "
        (click)="nextStep()"
      >
        Next
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'PROCESSING'">
    <div class="container processing">
      <mat-progress-bar
        mode="determinate"
        [value]="progress"
      ></mat-progress-bar>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'SUCCESS'">
    <div class="container status success">
      <mat-icon>check_circle</mat-icon>
      <h3 class="title" i18n="@@Yourdocumenthasbeensuccessfullyuploaded">
        Your document has been successfully uploaded
      </h3>
    </div>
    <div class="footer central">
      <button class="action-btn cancel" (click)="close()">Finish</button>
    </div>
  </ng-container>
  <ng-container *ngIf="steps === 'ERROR'">
    <div class="container status error">
      <mat-icon>error</mat-icon>
      <h3 class="title" i18n="@@Anerroroccurredwhileuploadingyourdocument">
        An error occurred while uploading your document
      </h3>
    </div>
    <div class="footer central">
      <button class="action-btn cancel" (click)="close()">Leave</button>
      <button class="action-btn save" (click)="nextStep()">Try Again?</button>
    </div>
  </ng-container>
</div>
