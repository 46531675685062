<div class="security">
  <app-title-large 
    >{{'Multi-factorAuthentication(MFA)' | translate}}</app-title-large
  >
  <mat-slide-toggle
    [(ngModel)]="mfaStatus"
    class="mfa"
    color="primary"
    (ngModelChange)="changeMfaStatus()"
  >
  <ng-container *ngIf="mfaStatus">{{'Turnoff' | translate}}</ng-container>
  <ng-container *ngIf="!mfaStatus">{{'Turnon' | translate}}</ng-container>
  </mat-slide-toggle>
  <p
    class="mfa-desc"
   
  >
    {{'Pleaseenablethisfunctiontoimprovetheprotectionofyouraccount.Ifenabled,youwillreceiveanauthenticationcodeonyourphonewheneveryoulogin,toyourmaximumsafety.' | translate}}
  </p>

  <app-title-large>{{'ChangePassword' | translate}}</app-title-large>

  <form class="form">
    <app-shared-form-input-text
      [passwordInput]="true"
      [(ngModel)]="currentPassword"
      (ngModelChange)="onChangeInput('currentPassword')"
      [error]="updateMessageError('currentPassword') | translate"
      id="currentPassword"
      name="currentPassword"

      label="{{'SecurityCurrentPassword' | translate}}"
    ></app-shared-form-input-text>
    <app-shared-form-input-text
      [passwordInput]="true"
      [(ngModel)]="newPassword"
      (ngModelChange)="onChangeInput('newPassword')"
      [error]="updateMessageError('newPassword') | translate"
      id="newPassword"
      name="newPassword"
      
      
      label="{{'SecurityNewPassword' | translate}}"
    ></app-shared-form-input-text>
    <app-shared-form-input-text
      [passwordInput]="true"
      [(ngModel)]="confirmPassword"
      (ngModelChange)="onChangeInput('confirmPassword')"
      [error]="updateMessageError('confirmPassword') | translate"
      id="confirmPassword"
      name="confirmPassword"
      
      
      label="{{'SecurityConfirmPassword' | translate}}"
    ></app-shared-form-input-text>
    <div class="submit-actions">
      <button type="button" class="act-btn cancel">{{'Cancel' | translate}}</button>
      <button
        type="submit"
        class="act-btn save"
        (click)="handleSubmit()"
        [disabled]="form.invalid"
      >
        {{'Confirm' | translate}}
      </button>
    </div>
  </form>
</div>
