import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuillModule } from 'ngx-quill';

import { ClientRoutingModule } from './client-routing.module';
import { ClientComponent } from './client.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileEditComponent } from './pages/profile-edit/profile-edit.component';
import { HomeComponent } from './pages/home/home.component';
import { MyClaimsComponent } from './pages/my-claims/my-claims.component';
import { NewsComponent } from './pages/news/news.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ElectronicSignatureComponent } from './shared/smart/electronic-signature/electronic-signature.component';
import { MaterialModule } from 'src/app/shared/modules/material/material-module.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleModule } from 'src/app/shared/components/simple/simple.module';
import { BlockClaimComponent } from './shared/simple/block-claim/block-claim.component';
import { CaseDescriptionComponent } from './shared/simple/case-description/case-description.component';
import { CaseComponent } from './pages/case/case.component';
import { PresentationCardComponent } from './shared/simple/presentation-card/presentation-card.component';
import { EletronicSignatureModalComponent } from './shared/smart/electronic-signature/components/eletronic-signature-modal/eletronic-signature-modal.component';
import { SignaturePadElectronicAssignatureComponent } from './shared/smart/electronic-signature/components/eletronic-signature-modal/signature-pad-electronic-assignature/signature-pad-electronic-assignature.component';
import { PdfViewerContainerElectronicAssignatureComponent } from './shared/smart/electronic-signature/components/eletronic-signature-modal/pdf-viewer-container-electronic-assignature/pdf-viewer-container-electronic-assignature.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { AvaiableListCaseComponent } from './pages/cases/components/simple/avaiable-list-case/avaiable-list-case.component';
import { CasesComponent } from './pages/cases/cases.component';
import { YourListClaimComponent } from './pages/cases/components/simple/your-list-claim/your-list-claim.component';
import { BlockCaseComponent } from './shared/simple/block-case/block-case.component';
import { ClaimComponent } from './pages/claim/claim.component';
import { ClaimInformationComponent } from './pages/claim/components/simple/claim-information/claim-information.component';
import { ClaimStatusComponent } from './pages/claim/components/simple/claim-status/claim-status.component';
import { QuestionnaireAnswerClientComponent } from './pages/questionnaire-answer-client/questionnaire-answer-client.component';
import { SmartModule } from 'src/app/shared/components/smart/smart.module';
import { SideNavComponent } from './shared/simple/side-nav/side-nav.component';
import { NavigationComponent } from './shared/simple/navigation/navigation.component';
import { BottomNavComponent } from './shared/simple/bottom-nav/bottom-nav.component';
import { CaseInformationComponent } from './pages/case/components/smart/case-information/case-information.component';
import { OsActionsLstComponent } from './pages/home/components/smart/os-actions-lst/os-actions-lst.component';

import { CaseTeaserListComponent } from './pages/home/components/smart/case-teaser-list/case-teaser-list.component';
import { CaseTeaserCardComponent } from './pages/home/components/simple/case-teaser-card/case-teaser-card.component';
import { NewsTeaserCardComponent } from './pages/home/components/simple/news-teaser-card/news-teaser-card.component';
import { OsActionsCaseComponent } from './shared/simple/os-actions-case/os-actions-case.component';
import { OsActionItemComponent } from './shared/simple/os-action-item/os-action-item.component';
import { ClaimMessagelistComponent } from './pages/claim/components/simple/claim-messagelist/claim-messagelist.component';
import { MarkdownModule } from 'ngx-markdown';
import { ThreadComponent } from './pages/thread/thread.component';
import { WelcomePopupComponent } from './shared/smart/welcome-popup/welcome-popup.component';
import { PendingSignatureComponent } from './shared/simple/os-action-item/modals/pending-signature/pending-signature.component';
import { DownloadDocumentComponent } from './shared/simple/os-action-item/modals/download-document/download-document.component';
import { LegalInformationComponent } from './shared/smart/legal-information/legal-information.component';
import { register } from 'swiper/element/bundle';
import { OutstandingActionsComponent } from './shared/smart/outstanding-actions/outstanding-actions.component';

import { DocBundleComponent } from './shared/simple/e-sign/doc-bundle/doc-bundle.component';
import { VerifyDocComponent } from 'src/app/pages/verify-doc/verify-doc.component';
import { EditProfileComponent } from './pages/profile/components/edit-profile/edit-profile.component';
import { DocumentsComponent } from './pages/profile/components/documents/documents.component';
import { SecurityComponent } from './pages/profile/components/security/security.component';

import { EditProfileSuccessChangeComponent } from './pages/profile/dialog/edit-profile-success-change/edit-profile-success-change.component';
import { DocumentPreviewComponent } from './pages/profile/dialog/document-preview/document-preview.component';
import { DocumentDeleteDocumentComponent } from './pages/profile/dialog/document-delete-document/document-delete-document.component';
import { DocumentUploadPhotoComponent } from './pages/profile/dialog/document-upload-photo/document-upload-photo.component';
import { DocumentUploadErrorComponent } from './pages/profile/dialog/document-upload-error/document-upload-error.component';
import { MfaEnrollComponent } from './pages/profile/dialog/mfa-enroll/mfa-enroll.component';
import { MfaUnenrollComponent } from './pages/profile/dialog/mfa-unenroll/mfa-unenroll.component';
import { QrCodeComponent } from './pages/profile/dialog/mfa-enroll/qr-code/qr-code.component';
import { CodeVerifyComponent } from './pages/profile/dialog/mfa-enroll/code-verify/code-verify.component';
import { SuccessOrErrorComponent } from './pages/profile/dialog/mfa-enroll/success-or-error/success-or-error.component';
import { PreInitComponent } from './pages/profile/dialog/mfa-enroll/pre-init/pre-init.component';
import { FormV2ComponentsModule } from 'src/app/shared/forms-v2/form-v2.module';
import { NewMessageComponent } from './pages/claim/new-message/new-message.component';
import { CommunicationsComponent } from './pages/communications/communications.component';
import { NewCommunicationComponent } from './pages/communications/new-communication/new-communication.component';
import { DetailsCommunicationComponent } from './pages/communications/details-communication/details-communication.component';
// import { CommunicationChatComponent } from './pages/communications/details-communication/components/communication-chat/communication-chat.component';
// import { CommunicationCallNotesComponent } from './pages/communications/details-communication/components/communication-call-notes/communication-call-notes.component';
// import { CommunicationEmailComponent } from './pages/communications/details-communication/components/communication-email/communication-email.component';
import { NotificationPreferenciesComponent } from './pages/profile/components/notification-preferencies/notification-preferencies.component';
import { PrivacyNoticeModalComponent } from './shared/smart/legal-information/privacy-notice-modal/privacy-notice-modal.component';
import { TermsOfUseModalComponent } from './shared/smart/legal-information/terms-of-use-modal/terms-of-use-modal.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { QuestionWorldAddressComponent } from 'src/app/shared/components/smart/question-world-address/question-world-address.component';
import { TranslateModule } from '@ngx-translate/core';
import { OaKycComponent } from './shared/smart/outstanding-actions/components/oa-kyc/oa-kyc.component';
import { OaQuestionnaireComponent } from './shared/smart/outstanding-actions/components/oa-questionnaire/oa-questionnaire.component';
import { OaSignatureComponent } from './shared/smart/outstanding-actions/components/oa-signature/oa-signature.component';
import { CardNewsComponent } from './shared/simple/card-news/card-news.component';
import { NewsDetailsComponent } from './pages/news/components/news-details/news-details.component';
import { MoreNewsComponent } from './pages/news/components/more-news/more-news.component';

register();

@NgModule({
  declarations: [
    ClientComponent,
    ProfileComponent,
    ProfileEditComponent,
    HomeComponent,
    MyClaimsComponent,
    NewsComponent,
    FaqComponent,
    ElectronicSignatureComponent,
    EletronicSignatureModalComponent,
    SignaturePadElectronicAssignatureComponent,
    PdfViewerContainerElectronicAssignatureComponent,
    PresentationCardComponent,
    BlockClaimComponent,
    CaseDescriptionComponent,
    CaseComponent,
    AvaiableListCaseComponent,
    CasesComponent,
    YourListClaimComponent,
    BlockCaseComponent,
    ClaimComponent,
    ClaimInformationComponent,
    ClaimStatusComponent,
    QuestionnaireAnswerClientComponent,
    SideNavComponent,
    NavigationComponent,
    BottomNavComponent,
    CaseInformationComponent,
    OsActionsLstComponent,
    OsActionsCaseComponent,
    OsActionItemComponent,
    CaseTeaserListComponent,
    CaseTeaserCardComponent,
    NewsTeaserCardComponent,
    ClaimMessagelistComponent,
    ThreadComponent,
    WelcomePopupComponent,
    PendingSignatureComponent,
    DownloadDocumentComponent,
    LegalInformationComponent,
    OutstandingActionsComponent,

    DocBundleComponent,
    VerifyDocComponent,
    EditProfileComponent,
    DocumentsComponent,
    SecurityComponent,

    EditProfileSuccessChangeComponent,
    DocumentPreviewComponent,
    DocumentDeleteDocumentComponent,
    DocumentUploadPhotoComponent,
    DocumentUploadErrorComponent,
    MfaEnrollComponent,
    MfaUnenrollComponent,
    QrCodeComponent,
    CodeVerifyComponent,
    SuccessOrErrorComponent,
    PreInitComponent,
    NotificationPreferenciesComponent,

    NewMessageComponent,

    OaKycComponent,
    OaQuestionnaireComponent,
    OaSignatureComponent,

    CommunicationsComponent,
    NewCommunicationComponent,
    DetailsCommunicationComponent,

    // CommunicationChatComponent,
    // CommunicationCallNotesComponent,
    // CommunicationEmailComponent,
    PrivacyNoticeModalComponent,
    TermsOfUseModalComponent,

    CardNewsComponent,
    NewsDetailsComponent,
    MoreNewsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule.forRoot(),
    ClientRoutingModule,
    MaterialModule,
    SimpleModule,
    PdfViewerModule,
    AngularSignaturePadModule,
    SmartModule,
    MarkdownModule,
    FormV2ComponentsModule,
    NgxMatIntlTelInputComponent,
    QuestionWorldAddressComponent,
    TranslateModule,
  ],
  exports: [
    CaseTeaserCardComponent,
    NewsTeaserCardComponent,
    OaKycComponent,
    OaQuestionnaireComponent,
    OaSignatureComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClientModule {}
