<mat-toolbar>
  <span class="example-spacer"></span>

  <!-- <div class="searchbar">
    <input type="search" placeholder="Search"  />
    <mat-icon>search</mat-icon>
  </div> -->

  <button mat-icon-button="" class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon color="primary" i18n="@@notifications">notifications</mat-icon>
  </button>
  <span routerLink="/dashboard/profile" class="profile-btn" aria-label="Example icon-button with share icon">
    <img src="https://picsum.photos/150" alt="" />
  </span>

  <button (click)="logout()" mat-icon-button="" class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon color="primary" i18n="@@logout">logout</mat-icon>
  </button>
</mat-toolbar>
