import { Component, type OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { EventService } from 'src/app/core/services/event.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.scss'],
})
export class AgentLoginComponent implements OnInit {
  form!: FormGroup;
  isLoading: boolean = false;

  email: string = '';
  password: string = '';
  supervisorPassword: string = '';
  supervisorEmail: string = '';
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
    private es: EventService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      // TODO: remove when finish
      email: ['', [Validators.required, Validators.email]],
      supervisorEmail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      supervisorPassword: ['', [Validators.required]],
    });
  }

  async onSubmit() {
    this.isLoading = true;
    try {
      if (!this.form.valid) {
        throw Error('Invalid Email and password');
      }

      const email = this.form.get('email')?.value;
      this.email = email;
      const password = this.form.get('password')?.value;
      this.password = password;

      this.supervisorEmail = this.form.get('supervisorEmail')?.value;
      this.supervisorPassword = this.form.get('supervisorPassword')?.value;

      const ret = await this.userService.authAgent(
        this.email,
        this.password,
        this.supervisorEmail,
        this.supervisorPassword
      );

      console.log('[Agent Login]', ret);

      const usr = await this.userService.getCurrentUser();
      if (usr?.primary_role == 'AGENT') {

        this.router.navigateByUrl('/firm/agent-dashboard');
      } else {
        this.router.navigateByUrl('/firm/cocounsel/claims');

      }

      // console.log('AuthError', error?.message);

      // const { session } = data;
      // // Check if this user needed mfa code
      // console.log(session);
      // console.log(session?.user?.factors);

      // if (error) {
      //   throw Error(error.message);
      // }
    } catch (error: any) {
      console.log("ERROR",error)
      this.toastService.error(error.error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
