<h6>test-storage</h6>
<div class="card" style="width: 500px; margin: 30px">
  <div class="card-header">
    <span i18n="@@Criarbucket">Criar bucket</span>
  </div>
  <div class="card-body">
    <input
      style="width: 200px; height: 25px"
      placeholder="Infome o nome do bucket"
      #inputBucket=""
    />
    <button
      type="button"
      style="
        width: 210px;
        height: 30px;
        border-radius: 5px;
        margin: 10px 0;
        background-color: #0086f6;
        color: var(--color-white);
        border: none;
      "
      (click)="createBucket(inputBucket.value)"
    >
      Criar
    </button>
    <div>
      <span style="margin-top: 20px" i18n="@@BucketList">Bucket List</span>
      <div>
        <ul *ngFor="let item of bucketList">
          <li>{{ item.id }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="card" style="width: 500px; margin: 30px">
  <div class="card-header">
    <span>Salvar documento</span>
  </div>
  <div class="card-body">
    <input
      style="width: 200px; height: 25px"
      placeholder="Infome o nome do bucket"
      #bucketName=""
    />
    <input
      style="width: 200px; height: 25px; margin-top: 10px"
      placeholder="Infome o folder com nome e extensão"
      #bucketPath=""
    />
    <input
      type="file"
      #fileInput=""
      (change)="onFileSelected($event)"
      style="display: none; width: 200px"
    />
    <button (click)="fileInput.click()" style="width: 200px; margin-top: 20px">
      Selecione um arquivo
    </button>

    <button
      type="button"
      style="
        width: 210px;
        height: 30px;
        border-radius: 5px;
        margin: 10px 0;
        background-color: #0086f6;
        color: var(--color-white);
        border: none;
      "
      (click)="uploadBucket(bucketName.value, bucketPath.value)"
    >
      Salvar documento
    </button>
  </div>
</div>

<div class="card" style="width: 500px; margin: 30px">
  <div class="card-header">
    <span>Criar url assinada</span>
  </div>
  <div class="card-body">
    <input
      style="width: 200px; margin-bottom: 20px; height: 25px"
      placeholder="Infome o nome do bucket"
      #bucketName1=""
    />
    <input
      style="width: 200px; margin-bottom: 20px; height: 25px"
      placeholder="Infome o nome do folder"
      #bucketFolder=""
    />

    <button
      type="button"
      style="
        width: 210px;
        height: 30px;
        border-radius: 5px;
        margin: 10px 0;
        background-color: #0086f6;
        color: var(--color-white);
        border: none;
      "
      (click)="signedUrl(bucketName1.value, bucketFolder.value)"
    >
      Gerar url assinada
    </button>

    <p>{{ url.data.signedUrl }}</p>
  </div>
</div>
