<div id="error" class="container">
  <div class="header-title">
    <mat-icon>sentiment_dissatisfied</mat-icon>
    <h6 class="title-large" i18n="@@Somethingwentwrong">
      Something went wrong
    </h6>
    <app-button-extra [type]="'icon'">
      <i [mat-dialog-close]="false" class="ic-x-sm ic-cancel"></i>
    </app-button-extra>
  </div>
  <div class="content">
    <p i18n="@@Systemerror,pleasetryagaininafewminutes">
      System error, please try again in a few minutes
    </p>
  </div>
  <div class="actions">
    <app-button-default size="lg" type="dark" i18n="@@Close"
      >Close</app-button-default
    >
  </div>
</div>
