<section class="home gap-container">
  <app-display-large >{{'Outstandingactions' | translate}}</app-display-large>

  <div class="main" [class.multi]="multiClaims">
    <section class="outstanding">
      <app-os-actions-lst (multiClaims)="handleMultipleClaims($event)"></app-os-actions-lst>
    </section>
    <section class="case-teaser">
      <app-case-teaser-list [multiClaims]="multiClaims"></app-case-teaser-list>
    </section>
  </div>
</section>
