<button
  mat-icon-button=""
  [matMenuTriggerFor]="menu"
  aria-label="Example icon-button with a menu"
>
  <mat-icon>add_circle</mat-icon>
</button>
<mat-menu #menu="matMenu" class="menu" xPosition="before">
  <button mat-menu-item="">
    <span i18n="@@Download">Download</span>
  </button>
  <button mat-menu-item="">
    <span (click)="openDocumentPreview()" i18n="@@Preview">Preview</span>
  </button>
  <mat-icon id="exit" fontSet="material-icons-outlined"
    >do_not_disturb_on</mat-icon
  >
</mat-menu>
