<ng-container>
  <mat-form-field class="example-full-width">
    <mat-label i18n="@@Telephone">Telephone</mat-label>
    <span matPrefix>+55 &nbsp;</span>
    <input
      [(ngModel)]="value"
      type="text"
      matInput
      placeholder="99999-9999"
    />
  </mat-form-field>
</ng-container>
