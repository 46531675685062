<section id="forgot">
  <div class="left-image">
    <img src="/assets/img/mdr-Logo.svg" alt="" class="logo-left" />
    <img src="/assets/img/login-left-img.svg" alt="" class="img-left" />
  </div>
  <div class="login-container">
    <div class="login-form">
      <img src="/assets/img/mdr-Logo.svg" alt="" class="logo" />
      <button
        mat-icon-button=""
        class="step-back"
        (click)="stepBack()"
        *ngIf="step !== 'success' && step !== 'password'"
      >
        <mat-icon svgIcon="curved-arrow-back"></mat-icon>
      </button>

      <ng-container [ngSwitch]="step">
        <ng-container *ngSwitchCase="'email'">
          <app-recovery-email
            (onSubmit)="emailSended($event)"
          ></app-recovery-email>
        </ng-container>
        <ng-container *ngSwitchCase="'code'">
          <app-recovery-code
            [email]="email"
            (onSubmit)="codeVerified($event.user, $event.session)"
          ></app-recovery-code>
        </ng-container>
        <ng-container *ngSwitchCase="'password'">
          <app-recovery-new-password
            (onSubmit)="passwordChanged()"
          ></app-recovery-new-password>
        </ng-container>
        <ng-container *ngSwitchCase="'success'">
          <app-recovery-success></app-recovery-success>
        </ng-container>

        <ng-container *ngSwitchDefault="">output2</ng-container>
      </ng-container>
    </div>
  </div>
</section>
