<section class="gap-container" *ngIf="news">
  <app-breadcrumb
    [items]="[
      { label: 'News', url: '/client/news' },
      { label: news.metadata.title }
    ]"
  ></app-breadcrumb>
  <div class="flex">
    <div class="main-news">
      <div class="img-container">
        <img
          class="img"
          *ngIf="news.metadata.picture"
          [src]="news.metadata.picture"
          alt=""
        />
      </div>
      <div class="text-container">
        <div class="news-bullet">
          {{ news.metadata.author || news.metadata.bullet }}
        </div>
        <h1 class="title">{{ news.metadata.title }}</h1>
        <p class="news-date">
          <mat-icon>schedule</mat-icon>
          {{
            news.valid_from || news.created_at
              | date : "d MMM, y" : "" : currentLang
          }}
        </p>

        <div class="news-text">
          <markdown>
            {{ news.content }}
          </markdown>
        </div>
      </div>
    </div>
    <div class="more-news">
      <app-more-news [otherNews]="otherNews"></app-more-news>
    </div>
  </div>
</section>
