<div class="actions-case-card">
  <ng-container *ngIf="showImage">
    <a routerLink="/client/claim/{{ claim!.id }}">
      <div class="case-image">
        <img [src]="claim?.cp_case?.configuration.images?.high_res" />
      </div>
    </a>
  </ng-container>

  <div class="card-top">
    <div class="row status">
      <div class="chip">
        <p>
          {{ claim?.cp_case?.status! | caseStatus | translate }}
        </p>
      </div>

      <div class="outstanding-message">
        <span
          [class.green]="!pendingNumber || pendingNumber === 0"
          [class.red]="pendingNumber && pendingNumber > 0"
          class="circle"
        ></span>

        <span class="label" *ngIf="pendingNumber > 0"
          >{{ pendingNumber }} pending
          {{ pendingNumber === 1 ? "action" : "actions" }}</span
        >

        <span
          class="label"
          *ngIf="!pendingNumber || pendingNumber === 0"
          
          >{{'Nopendingactions' | translate}}</span
        >
      </div>
    </div>
    <div class="row">
      <h4>
        {{ claim?.cp_case?.summary }}
      </h4>
    </div>
  </div>

  <p class="actions-required" >{{'Actionsrequired' | translate}}</p>

  <app-outstanding-actions
    *ngIf="claim"
    [claimId]="claim.id"
    (outstandingActions)="changeActions($event)"
  ></app-outstanding-actions>

  <button
    routerLink="/client/claim/{{ claim!.id }}"
    class="def-button view-details"
    *ngIf="showDetails"
  >
    {{'View details'|translate}}
  </button>
</div>
