<div class="modal">
  <div class="modal-header">
    <h6 >{{'Downloaddocument' | translate}}
</h6>
  </div>
  <div class="modal-body">
    <h6 >{{'Yourdocumentisreadytobedownloaded.' | translate}}
</h6>
  </div>
  <div class="modal-footer">
    <button (click)="download()" [mat-dialog-close]="true" class="btn-md">
      {{'DownloadNow' | translate}}

    </button>
  </div>
</div>
