<div class="container">
  <div class="title">
    <h4 >{{'FirmContract' | translate}}
</h4>
  </div>
  <div class="content" *ngIf="docURL">
    <pdf-viewer
      [src]="docURL"
      [original-size]="true"
      [render-text]="true"
      [rotation]="0"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="zoom"
      [zoom-scale]="'page-width'"
      [stick-to-page]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      class="pdf-viewer"
    ></pdf-viewer>
    <div class="zoom-buttons">
      <mat-icon (click)="incrementZoom(0.1)">zoom_in</mat-icon>
      <mat-icon (click)="incrementZoom(-0.1)">zoom_out</mat-icon>
    </div>
  </div>
</div>
