<div class="container">
  <div class="login-card">
    <h1 class="ll-logo" i18n="@@Lawyer&Law">Lawyer & Law</h1>
    <h2 i18n="@@Resetyourpassword">Reset your password</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="password" i18n="@@Password">Password</label>
        <input formControlName="password" id="password" type="password" name="password" placeholder="Password" />
      </div>
      <div class="form-group">
        <label for="password" i18n="@@ConfirmPassword">Confirm Password</label>
        <input formControlName="confirmPassword" id="confirmPassword" type="password" name="confirmPassword" placeholder="Confirm Password" />
      </div>

      <button color="primary" type="submit" mat-raised-button="" [disabled]="isLoading">
        <mat-icon *ngIf="isLoading"><mat-spinner diameter="20"> </mat-spinner></mat-icon>
        Recover
      </button>
    </form>
    <p>
      Already have an account?
      <a [routerLink]="['/auth/login']" i18n="@@SignIn">Sign In</a>
    </p>
    <a href="https://somos.us/en-US/privacy" target="_blank" i18n="@@PrivacyNotice">Privacy Notice</a>
  </div>
</div>
