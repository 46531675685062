<div *ngIf="case">
  <div class="card-preview-case">
    <img *ngIf="
      case.configuration &&
      case.configuration.images &&
      case.configuration.images.high_res
    " class="img-rectangle-lg" [src]="case.configuration.images.high_res" alt="" />
    <img *ngIf="
      !(
        case.configuration &&
        case.configuration.images &&
        case.configuration.images.high_res
      )
    " class="img-rectangle-lg" src="http://via.placeholder.com/640x360" alt="" />

    <p class="category">
      <span></span>
      {{ getCaseTypeName(case?.case_type) }}
    </p>

    <app-title-large>{{ case.summary ? case.summary : "Title" }}</app-title-large>

    <p class="desc" *ngIf="case.description_clients; else defaultText">
      {{ removeHtmlTags(case.description_clients).substring(0, 400) }}...
    </p>
    <ng-template #defaultText="">
      <p class="desc" >{{'Description' | translate}}</p>
    </ng-template>
  </div>
</div>
