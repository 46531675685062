<div class="actions">
  <app-title-large
    color="var(--color-neutral-40)"
    [paddingMobile]="true"
    
    >{{'MyClaims' | translate}}</app-title-large
  >

  <div [class.multi]="claims && claims.length === 2">
    <ng-container *ngIf="claims?.length === 1">
      <ng-container *ngFor="let claim of claims">
        <app-os-actions-case
          [claim]="claim"
          [showDetails]="true"
        ></app-os-actions-case>
        <br />
      </ng-container>
    </ng-container>

    <ng-container *ngIf="claims?.length === 2">
      <ng-container *ngFor="let claim of claims">
        <app-os-actions-case
          [claim]="claim"
          [showDetails]="true"
        ></app-os-actions-case>
        <br />
      </ng-container>
    </ng-container>

    <ng-container *ngIf="claims && claims.length > 2">
      <!-- [autoHeight]="true" -->
      <swiper-container
        class="swiper"
        [loop]="true"
        [allowTouchMove]="false"
        [pagination]="{ clickable: true }"
        slidesPerView="3"
        style="--swiper-pagination-color: #e14313"
      >
        <ng-container *ngFor="let claim of claims">
          <swiper-slide>
            <app-os-actions-case
              [claim]="claim"
              [showDetails]="true"
            ></app-os-actions-case>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ng-container>

    <ng-container *ngIf="!claims">
      <div class="spin-container">
        <mat-spinner [mode]="'indeterminate'" diameter="60"></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
