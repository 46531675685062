<div class="signature-popup">
  <ng-container *ngIf="contract">
    <ng-container *ngIf="template == 'explanation'">
      <div class="doc-bundle">
        <h6 class="title-large">
          <img src="../../../../../../../../assets/icons/sign.svg" alt="" />
          {{ contract.name }}
        </h6>

        <markdown>
          {{ contract.configuration.explanation_text }}
        </markdown>

        <div class="btns">
          <button (click)="changeTemplate('pdf')" id="blue">Next</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="template == 'pdf'">
      <div class="doc-bundle">
        <h6 class="title-large">
          <img src="../../../../../../../../assets/icons/sign.svg" alt="" />
          Sign Mishcon de Reya LLP's onboarding pack
        </h6>

        <p
          class="body-medium"
        >
          {{'Toproceed,downloadeachofthedocumentsbelow:' | translate}}
        </p>

        <app-doc-bundle
          [contract]="contract"
          (valid)="docBundleValid($event)"
        ></app-doc-bundle>

        <p
          class="next-desc"
          i18n="
            @@Youcanonlycompletethisstepifyoudownloadandacceptallthedocuments."
        >
          {{'Youcanonlycompletethisstepifyoudownloadandacceptallthedocuments.' | translate}}

        </p>

        <div class="btns">
          <button
            (click)="changeTemplate('assignature')"
            id="blue"
            [disabled]="!canProceedToSign"
          >
            {{'ProceedToSignature' | translate}}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="template == 'assignature'">
      <app-signature-pad-electronic-assignature
        id="signature-component"
        style="display: flex; justify-content: center"
        (backEventEmitter)="changeTemplate('pdf')"
        (signEventEmitter)="sendDraw($event)"
        (termsEventEmiiter)="changeTemplate('terms')"
      ></app-signature-pad-electronic-assignature>
    </ng-container>

    <ng-container *ngIf="template == 'terms'">
      <ng-template [ngTemplateOutlet]="termsofservice"></ng-template>
    </ng-container>

    <ng-container *ngIf="template == 'sucess'">
      <ng-template [ngTemplateOutlet]="sucess"></ng-template>
    </ng-container>

    <ng-container *ngIf="template == 'error'">
      <ng-template [ngTemplateOutlet]="error"></ng-template>
    </ng-container>

    <ng-template #termsofservice="">
      <div id="terms" class="container">
        <mat-icon
          (click)="template = 'assignature'"
          class="material-icons-outlined"
          id="close"
          >close</mat-icon
        >
        <div class="content">
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
            ullamco est sit aliqua dolor do amet sint. Velit officia consequat
            duis enim velit mollit. Exercitation veniam consequat sunt nostrud
            amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do
            amet sint. Velit officia consequat duis enim velit mollit.
            Exercitation veniam consequat sunt nostrud amet. Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia Amet minim mollit non deserunt ullamco est sit aliqua
            dolor do amet sint. Velit officia consequat duis enim velit mollit.
            Exercitation veniam consequat sunt nostrud amet. Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia Amet minim mollit non deserunt ullamco est sit aliqua
            dolor do amet sint. Velit officia consequat duis enim velit mollit.
            Exercitation veniam consequat sunt nostrud amet. Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.
          </p>
        </div>
      </div>
    </ng-template>

    <ng-template #sucess="">
      <div id="sucess" class="container">
        <div class="header-title">
          <mat-icon fontSet="material-icons-outlined">celebration</mat-icon>
          <h6 class="title-large" >{{'Success' | translate}}</h6>
        </div>

        <div class="subtitle">
          <span class="message" 
            >{{'Documentsignedsuccessfully.' | translate}}
</span
          >
        </div>

        <div class="content">
          <div class="information">
            <ul>
              <li>
                <mat-icon fontSet="material-icons-outlined">person</mat-icon
                ><span>{{ user?.display_name }}</span>
              </li>
              <li>
                <mat-icon fontSet="material-icons-outlined"
                  >desktop_windows</mat-icon
                ><span >IP:187.111.29.54</span>
              </li>
              <!-- <li>
                <mat-icon fontSet="material-icons-outlined"
                  >location_on</mat-icon
                ><span>Castelo, Belo Horizonte, 31330-200</span>
              </li> -->
              <li>
                <span class="no-icon"
                  >Signed on: {{ date | date : "medium" }}</span
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="actions" mat-dialog-close="">
          <app-button-default
            [disabled]="loading"
            size="lg"
            type="dark"

            >{{'Close' | translate}}</app-button-default
          >
        </div>
      </div>
    </ng-template>

    <ng-template #error="">
      <app-error-modal></app-error-modal>
    </ng-template>
  </ng-container>
</div>
