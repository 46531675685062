<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>

<div class="outstanding-items" *ngFor="let action of actions">
  <div [ngSwitch]="action.action_type">
    <p *ngSwitchCase="'KYC'">
      <app-oa-kyc [action]="action" (changed)="onChange()"></app-oa-kyc>
    </p>
    <p *ngSwitchCase="'QUESTIONNAIRE'">
      <app-oa-questionnaire
        [action]="action"
        (changed)="onChange()"
      ></app-oa-questionnaire>
    </p>
    <p *ngSwitchCase="'SIGNATURE'">
      <app-oa-signature
        [action]="action"
        (changed)="onChange()"
      ></app-oa-signature>
    </p>

    <div *ngSwitchDefault="" class="item">
      <button class="label" disabled="">
        <img
          class="icon"
          src="assets/icons/sign.png"
          alt="{{ action.action_name }}"
        />
        <span>{{ action.action_name }}</span>
      </button>
    </div>
  </div>
</div>
