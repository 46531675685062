<div class="item-container">
  <ng-container *ngFor="let doc of contract?.configuration?.bundle">
    <div class="item">
      <label class="body-medium">{{ doc.name }}</label>
      <div class="status">
        <button mat-icon-button="" (click)="download(doc)">
          <img src="../../../../../../../assets/icons/icon-download.svg" alt="" />
        </button>
        <img *ngIf="state[doc.name]" src="../../../../../../../assets/icons/green-check.svg" alt="" />
        <img *ngIf="!state[doc.name]" src="../../../../../../../assets/icons/grey-check.svg" alt="" />
      </div>
    </div>
  </ng-container>
</div>
