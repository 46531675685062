<div class="legal-information-modal">
  <header>
    <app-title-large >{{'PrivacyNotice' | translate}}</app-title-large>
    <mat-icon (click)="close()">close</mat-icon>
  </header>

  <div mat-dialog-content="">
    <article>
      <h2 >
        {{'OneCoinClaimWebsitePrivacyNotice' | translate}}
      </h2>
      <p >
        {{'Werespectyourrightstodataprotectionandprivacy' | translate}}
      </p>
      <p
       
      >
        {{'Thisnoticeappliestotheprocessingofpersonaldatadirectlyprovidedbyyouorcollectedasaresultofyourvisitingthiswebsiteand/orasaresultofyouregisteringyourinterestinagroupactionbeinglaunchedonbehalfofinvestorsinOneCoinwhosufferedlossesasaresultofwrongdoingassociatedwiththeirinvestment(werefertothisinthisnoticeasthe&quot;OneCoinClaim&quot;).Ifweprocessyourpersonaldatainadifferentcontextorcircumstances(forinstanceifyouinstructusandbecomeourclient)aseparatenoticemayapply.' | translate}}
      </p>

      <h2 >{{'Whoarewe?' | translate}}</h2>
      <p
       
      >
        {{'ThiswebsiteismaintainedandoperatedbyMG-GestõesEServiçosAdministrativosLtda(&quot;Somos&quot;).Dataenteredviathe‘Register’pagewillbestoredbySomos.SomosoperatesthewebsiteonbehalfofMishcondeReyaLLP(&quot;Mishcon&quot;)inaproposedgroupactionbroughtonbehalfofOneCoininvestors.MishconisthecontrollerforthepurposesofthepersonaldataprocessedinthecontextofthiswebsiteandSomosistheprocessor(thismeansthatMishcondecidesinthemainhowandwhythedatashouldbeprocessedandSomosactsonMishcon\'sinstructionwhenprocessingit).InthisNoticeweusetheterms&quot;us&quot;,&quot;our&quot;and&quot;we&quot;torefertoMishconandSomoscollectivelyorseparatelyasthecontextrequires.Wherewereferto&quot;dataprotectionlaw&quot;weprimarilymeantheUKGDPR,theEUGDPR,andtheDataProtectionAct2018asappropriate.' | translate}}
      </p>
      <p
       
      >
        {{'MishconisalimitedliabilitypartnershipincorporatedinEngland(numberOC399969)whoseregisteredofficeisatAfricaHouse,70Kingsway,LondonWC2B6AH.ItisauthorisedandregulatedbytheSolicitorsRegulationAuthorityunderSRAnumber624547.Itisregisteredasafee-payerwiththeInformationCommissionerunderregistrationnumberZA144945.' | translate}}
      </p>
      <p
       
      >
        {{'Somosis[details]incorporatedinBrazilwhoseregisteredofficeisatRuaGomesdeCarvalhoNo.1.629VilaOlímpia04547-006[andisregisteredasafee-payerwiththeInformationCommissionerunderregistrationnumber[number]].' | translate}}
      </p>

      <h2 >
        {{'Whatpersonaldatadowecollect?' | translate}}
      </h2>
      <p
        
      >
        {{'Asaresultofyourvisitingthiswebsitewemaycollectorotherwiseprocessyourpersonaldatainthefollowingways:"' | translate}}
      </p>
      <ul>
        <li
        
        >
          {{'WhenyouprovideyourpersonaldatathroughthecontactformexpressinginterestintheOneCoinClaim.' | translate}}
        </li>
        <li
        
        >
          {{'Intheformofinformationprovidedbyyourwebbrowserthroughtheuseofcookiesorsimilarsoftware(fordetailsaboutouruseofsuchsoftwarepleaseseeourCookiespage).' | translate}}
        </li>
      </ul>

      <h2 >
        {{'Whatisourbasisforprocessingyourpersonaldata?' | translate}}
      </h2>
      <p
        
      >
        {{'AnypersonaldatathatyougivetouswillberetainedbyussothatMishconcanassesstheinformation.ItwillalsobeusedtocontactyouabouttheOneCoinClaim.YoudonothavetogiveusanyofyourpersonaldatabutifyoudonotMishconwillbeunabletoassesswhetheryoumayhaveavalidclaim.' | translate}}
      </p>
      <p
        
      >
        {{'Ingeneral,ifyouwouldbemakingaclaimasanindividualMishcon\'slegalbasisforprocessingyourpersonaldataisthatitisnecessaryinordertotakestepsattherequestofyou-thedatasubject-priortoenteringintoacontract.IfyouwouldberepresentingacompanyorotherorganisationwhichwouldbemakingaclaimMishcon\'slegalbasisforprocessingyourpersonaldatawillbethatitisnecessaryforitslegitimateinterests.Mishconmayalsorelyonitslegitimateinterests(andindeedyours)fortheprocessingalthoughMishconwouldrefrainfromdoingsoifthoselegitimateinterestswereoverriddenbyyourinterestsorfundamentalrightsandfreedoms.Mishconhasaninterestinoperatingitsbusinessinthemostcustomer-focusedandprofessionalwayanditsprocessingofyourpersonaldataisdoneinaccordancewiththis."' | translate}}
      </p>
      <p
        
      >
        {{'IfMishconsubsequentlyonboardsyouasaclientaseparateengagementletterandtermsofbusinesswillapply.' | translate}}
      </p>

      <h2 >
        {{'Whomightaccessorreceiveyourpersonaldata?' | translate}}
      </h2>
      <p
        
      >
        {{'RecipientsofpersonaldataprovidedasaresultofyourvisitingthiswebsitewillgenerallyonlyconsistofSomosstaff,Mishconpartnersorstaff,andotherthirdpartiesinstructedbyMishcon.IfyouengageMishconasyourclientwewillinviteyoutoprovidecertaininformationforverificationandcompliancepurposestoourprocessorThirdfort.Incertaincircumstances,wemaybecompelledtoprovideinformationweholdtothirdpartiessuchasregulatoryorlawenforcementbodies.Wewouldonlydosoincompliancewiththelawandwherestrictlynecessary.' | translate}}
      </p>

      <h2 >{{'General' | translate}}</h2>
      <p
        
      >
        {{'ForthepurposesoftheprocessingofpersonaldatainthecontextoftheofferingofgoodsorservicestothoseintheEuropeanUnionunderArticle3(2)ofGDPR,MishconhasdesignatedinlinewithitsobligationsunderArticle27oftheGDPRarepresentativeintheUnion.ThisisMishcondeReyaIPB.V.ofPrinsenkade9D4811VBBredaTheNetherlands(emailaddress:gdpreu@mishcon.com).' | translate}}
      </p>
      <p
        
      >
        {{'WedonotintendtotransferyourpersonaldataprovidedorcollectedasaresultofvisitingourwebsiteoutsideoftheUKbutifwemustwewillonlydosoinlinewithobligationsunderChapterVoftheUKGDPRorChapterVoftheGDPR(asappropriate).WherethetransferistoarecipientinacountrywhichdoesnothaveanadequacydecisionunderArticle45oftheUKGDPRorArticle45oftheGDPR(asappropriate)wewillrelyonstandardcontractualclauses(withsupplementarymeasureswhereappropriate)underArticle46(2)(c)oftheUKGDPRorArticle46(2)(c)oftheGDPR(asappropriate)oranappropriatederogationunderArticle49oftheUKGDPRorArticle49oftheGDPR(asappropriate).' | translate}}
      </p>
      <p
        
      >
        {{'WedonotmakeautomateddecisionsasreferredtoinArticle22oftheUKGDPRandArticle22oftheGDPR.' | translate}}
      </p>

      <h2 >{{'Yourrights' | translate}}</h2>
      <p
        
      >
        {{'IfyouwishtomakeasubjectaccessrequestatanytimepleaseemailusonDSAR@mishcon.comandyourrequestwillbedealtwithexpeditiously.' | translate}}
      </p>
      <p >
        {{'Youalsohavetherightincertaincircumstances:' | translate}}
      </p>
      <ul>
        <li >
          {{'torequestrectificationorerasureofpersonaldata;' | translate}}
        </li>
        <li >
          {{'torequestrestrictionofprocessingconcerningyou;' | translate}}
        </li>
        <li >
          {{'toobjecttoprocessingofthedata;' | translate}}
        </li>
        <li >{{'todataportability;and' | translate}}</li>
        <li
          
        >
          {{'tocomplaintotheInformationCommissioner\'sOffice(orinsomecircumstancesasupervisoryauthorityinanEUmemberstate)aboutthemannerinwhichyourpersonaldataisprocessed.' | translate}}
        </li>
      </ul>

      <h2 >
        {{'Contactingusaboutyourinformation' | translate}}
      </h2>
      <p
        
      >
        {{'Ifyouhaveanyquestionsorconcernsregardingyourpersonaldataorhowitisusedpleasecontactus.' | translate}}
      </p>
      <p
        
      >
        {{'Tohelpuskeepyourpersonaldataup-to-dateyoushouldletusknowshouldanyofyourcontactdetailschangeorifyounoticeanyinaccuraciesinthem.Youcandothisdirectlybyemailatonecoinvictims@mishcon.com.Fromtimetotimewemaycontactyoutoconfirmthatthedataweholdforyouiscorrect.' | translate}}
      </p>
      <p
        
      >
        {{'Explanatorynote:thisPrivacyNoticeisinplacetoinformyouinlinewithourgeneralobligationsandinparticularinlinewithArticle13oftheUKGDPRoftheprocessingbyusofyourpersonaldata.Itisn\'tacontractualdocumentsowedon\'taskyouto&quot;agree&quot;toitorclaimthatbyreadingityouaretakentohaveagreedtoit.' | translate}}
      </p>
      <p
        
      >
        {{'PleasenotethatwhereweareofferinggoodsorservicestoindividualsintheEuropeanUnionwewillalsobesubjecttotheGDPRitself(notjusttheUKGDPR).InthosecasesyouwouldalsohavetherighttomakeacomplaintunderArticle77toanotherGDPRsupervisoryauthority.AlsointhosecaseswehavedesignatedarepresentativeunderArticle27(1)oftheGDPR.ThisisMishcondeReyaIPB.V.ofPrinsenkade9D4811VBBredaTheNetherlandswhocanbecontactedatgdpreu@mishcon.com.' | translate}}
      </p>
    </article>
  </div>
  <div mat-dialog-actions="">
    <button class="btn-close" (click)="close()">{{'Close' | translate}}</button>
  </div>
</div>
