<div class="item" *ngIf="requirement?.requirement?.type === 'QUESTIONNAIRE'" routerLink="/client/claim/{{ requirement?.claimId }}/q/{{
    requirement?.requirement?.config.slug
  }}">
  <img *ngIf="requirement?.status != 0" class="icon" src="../../../../../../../../assets/icons/questionnaire.svg" alt="" />
  <img *ngIf="requirement?.status == 0" class="icon success" src="../../../../../../../../assets/icons/icon-confirm.svg" alt="" />

  <span>{{ requirement?.requirement?.headline }} </span>
</div>

<div class="item" *ngIf="requirement?.requirement?.type === 'KYC'" (click)="uploadDialog()">
  <img *ngIf="requirement?.status != 0" class="icon" src="../../../../../../../../assets/icons/upload.svg" alt="" />

  <img *ngIf="requirement?.status == 0" class="icon success" src="../../../../../../../../assets/icons/icon-confirm.svg" alt="" />

  <span>{{ requirement?.requirement?.headline }} </span>
</div>

<div class="item" *ngIf="requirement?.requirement?.type === 'SIGNATURE'" (click)="signatureDialog()">
  <img *ngIf="requirement?.status != 0" class="icon" src="../../../../../../../../assets/icons/sign.svg" alt="" />

  <img *ngIf="requirement?.status == 0" class="icon success" src="../../../../../../../../assets/icons/icon-confirm.svg" alt="" />

  <span>{{ requirement?.requirement?.headline }} </span>
</div>

<div class="item" *ngIf="requirement?.requirement?.type === 'MESSAGE'" routerLink="/client/claim/{{ requirement?.claimId }}">
  <div class="iconblock">
    <img *ngIf="requirement?.status != 0" class="icon" src="../../../../../../../../assets/icons/mail.svg" alt="" />

    <img *ngIf="requirement?.status == 0" class="icon success" src="../../../../../../../../assets/icons/icon-confirm.svg" alt="" />

    <span class="circle-topic">{{ requirement?.requirement?.config.count }}
    </span>
  </div>

  <span>{{ requirement?.requirement?.headline }} </span>
</div>
