<mat-form-field class="currency-selector">
  <mat-select (change)="changeCurrency()" [(ngModel)]="currency" placeholder="Select...">
    <!-- <mat-option [value]="'$'">USD</mat-option>
    <mat-option [value]="'R$'">R$</mat-option>
    <mat-option [value]="'€'">€</mat-option> -->

    <mat-option *ngFor="let cur of curs | keyvalue" [value]="cur.key">{{ cur.value.name }} ({{ cur.value.symbol_native }})</mat-option>
    <mat-select-trigger>
      {{ curs[currency!].symbol_native }}
    </mat-select-trigger>
  </mat-select>
</mat-form-field>

<mat-form-field [formGroup]="currencyForm" style="max-width: 299px; width: 100%">
  <input formControlName="cValue" (change)="change()" [thousandSeparator]="currency == 'R$' ? '.' : ','" [placeholder]="currency == 'R$' ? '0,00' : '0.00'" [validation]="true" matInput="" mask="separator.2" required="" />

  <mat-error style="font-size: 12px; color: #da2128" i18n="@@Pleasecompletethisfield.">
    Please complete this field.
  </mat-error>
</mat-form-field>
