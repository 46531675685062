<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let item of items; let last = last">
      <ng-container *ngIf="!last">
        <a *ngIf="item.url; else noLink" [routerLink]="item.url">{{ item.label }}</a>
        <ng-template #noLink="">{{ item.label }}</ng-template>
      </ng-container>
      <ng-container *ngIf="last">
        <span>{{ item.label }}</span>
      </ng-container>
    </li>
  </ol>
</nav>
