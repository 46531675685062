<ng-container *ngIf="question">
  <div class="q">
    <p class="title" *ngIf="question.cp_question.response_type !== 'CONCAT'">
      <markdown>{{ question.cp_question.statement }}</markdown>
    </p>
    <span [ngSwitch]="question.cp_question.response_type">
      <p *ngSwitchCase="'YESNO'">
        <app-question-yes-no
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-yes-no>
      </p>
      <p *ngSwitchCase="'COUNTRY'">
        <app-question-country
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-country>
      </p>
      <p *ngSwitchCase="'LINK'">
        <app-question-link
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-link>
      </p>
      <p *ngSwitchCase="'SELECT'">
        <app-question-select
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-select>
      </p>
      <p *ngSwitchCase="'MULTI_SELECT'">
        <app-question-multi-select
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-multi-select>
      </p>
      <p *ngSwitchCase="'CHECKBOX'">
        <app-question-checkbox
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-checkbox>
      </p>
      <p *ngSwitchCase="'PHONE'">
        <app-question-phone
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-phone>
      </p>
      <p *ngSwitchCase="'DATE'">
        <app-question-date
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-date>
      </p>
      <p *ngSwitchCase="'DATETIME'">
        <app-question-date-time
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-date-time>
      </p>
      <p *ngSwitchCase="'TIME'">
        <app-question-time
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-time>
      </p>
      <p *ngSwitchCase="'MONTHYEAR'">
        <app-question-month-year
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-month-year>
      </p>
      <p *ngSwitchCase="'EXPLANATION'">
        <app-question-explanation
          [question]="question.cp_question"
        ></app-question-explanation>
      </p>
      <p *ngSwitchCase="'NUMBER'">
        <app-question-number
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-number>
      </p>
      <p *ngSwitchCase="'EMAIL'">
        <app-question-email
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-email>
      </p>
      <p *ngSwitchCase="'CURRENCY'">
        <app-question-currency
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-currency>
      </p>
      <p *ngSwitchCase="'WORLD_ADDRESS'">
        <app-question-world-address
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-world-address>
      </p>
      <p *ngSwitchCase="'UK_ADDRESS'">
        <app-question-uk-address
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-uk-address>
      </p>
      <p *ngSwitchCase="'TABLE'">
        <app-question-table
          [context]="context"
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-table>
      </p>
      <p *ngSwitchCase="'MULTI_FILE'">
        <app-question-multi-file
          [context]="context"
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-multi-file>
      </p>
      <p *ngSwitchCase="'CONCAT'">
        <app-question-concat
          [questionnaireAnswer]="qa$"
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-concat>
      </p>

      <!-- SHORT_TEXT AND LONG_TEXT WILL BE HERE AND HANDLED BY THE QUESTION_TEXT COMPONENT -->
      <p *ngSwitchDefault="">
        <app-question-text
          [question]="question.cp_question"
          (changeAnswer)="change($event)"
        ></app-question-text>
      </p>
    </span>
  </div>
</ng-container>

<ng-container *ngIf="!question">Question not loaded.</ng-container>
