<mat-accordion>
  <mat-expansion-panel class="multi" hideToggle="" multi="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="flex align-itens-center">
          <h6>{{ title }}</h6>
        </div>
        <i id="icon" class="ic-sm ic-arrow-left"></i>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent="">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of items" class="item" hideToggle="" multi="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="flex align-itens-center">
                <h6>{{ item.title }}</h6>
              </div>
              <i id="icon" class="ic-sm ic-arrow-left"></i>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ item.content }}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
