<div class="tabs">
  <div class="tabs-header">
    <div
      class="tab"
      *ngFor="let tab of _tabs"
      [class.active]="tab.active"
      [class.disabled]="tab.disabled"
      (click)="selectTab(tab)"
    >
      {{ tab.name }}
    </div>
  </div>
  <div class="tabs-container">
    <ng-content></ng-content>
  </div>
</div>
