import { CommonModule } from '@angular/common';
import { Component, Inject, type OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { first } from 'rxjs';
import { UploadReturn } from 'src/app/core/services/base/document.service';
import { ClaimDocumentService } from 'src/app/core/services/claim_document.service';
import {
  DocumentType,
  KycProvider,
  KycService,
} from 'src/app/core/services/kyc.service';
import { PersonalDocumentService } from 'src/app/core/services/personal_document.service';
import { UserService } from 'src/app/core/services/user.service';
import { BaseKycProviderComponent } from './base-kyc-provider.component';

export enum DocumentTypeEnum {
  PASSPORT = 'passport',
  NATIONAL_IDENTITY_CARD = 'national_identity_card',
  RESIDENCE_PERMIT = 'residence_permit',
  OTHER_IDENTITY_DOCUMENT = 'other_identity_document',
  DRIVER_LICENSE = 'driving_licence',
}
export enum ProcessingStatus {
  PROCESSING = 'PROCESSING',
  END_PROCESSING = 'END_PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface KycCheckBody {
  provider: KycProvider;
  documentType: DocumentType;
  user_id: string;
  front_id: string;
  back_id?: string;
}

@Component({
  selector: 'app-document-kyc-upload-modal',
  templateUrl: './document-kyc-upload-modal.component.html',
  styleUrls: ['./document-kyc-upload-modal.component.scss'],
})
export class DocumentKycUploadModalComponent
  extends BaseKycProviderComponent
  implements OnInit
{
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    dialog: MatDialog,
    pds: PersonalDocumentService,
    cds: ClaimDocumentService,
    ks: KycService,
    us: UserService,
    dialogRef: MatDialogRef<DocumentKycUploadModalComponent>
  ) {
    super(data, dialog, pds, cds, ks, us, dialogRef);
    if (!data.provider) data.provider = 'thirdfort';
  }

  ngOnInit() {}
}
