<div class="bottom-nav">
  <button mat-button="">
    <div class="flex-center-collumn">
      <mat-icon>dashboard</mat-icon>
      <span >{{'Dashboard' | translate}}</span>
    </div>
  </button>
  <button (click)="toggleSideNav()" mat-button="">
    <mat-icon id="add">add_circle</mat-icon>
  </button>
  <button mat-button="">
    <div class="flex-center-collumn">
      <mat-icon fontSet="material-icons-outlined"
        >circle_notifications</mat-icon
      >
      <span >{{'Notifications' | translate}}
</span>
    </div>
  </button>
</div>
