import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'somos-client-portal';
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.matIconRegistry.addSvgIcon(
      'picture-as-png',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/png.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'picture-as-jpg',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/jpg.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'picture-as-pdf',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/pdf.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom_chat',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/chat-outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom_filter_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-filter-outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom_filter_filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-filter-filled.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom_filter_clear',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/icon-filter-clear-2.svg'
      )
    );

    translate.setDefaultLang('en'); // Set the default language
    translate.use('en'); // Use English by default
  }

  changeLanguage(lang: string) {
    this.translate.use(lang); // Switch language dynamically
  }
}
