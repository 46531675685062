<ng-container [ngSwitch]="type">
  <ng-template [ngSwitchCase]="'back'"
    ><button mat-button="" class="btn-direction btn-extra">
      <i class="ic ic-arrow-left"></i>
      <span i18n="@@back">back</span>
    </button>
  </ng-template>
  <ng-template [ngSwitchCase]="'next'">
    <button id="" mat-button="" class="btn-direction btn-extra">
      <span i18n="@@next">next</span>
      <i class="ic ic-arrow-right"></i>
    </button>
  </ng-template>
  <ng-template [ngSwitchCase]="'right'">
    <button mat-button="" class="btn-direction">
      <i class="ic ic-arrow-right"></i>
    </button>
  </ng-template>
  <ng-template [ngSwitchCase]="'left'">
    <button mat-button="" class="btn-direction">
      <i class="ic ic-arrow-left"></i>
    </button>
  </ng-template>
  <ng-template [ngSwitchCase]="'down'">
    <button mat-button="" class="btn-direction">
      <i class="ic ic-arrow-down"></i>
    </button>
  </ng-template>
  <ng-template [ngSwitchCase]="'up'">
    <button mat-button="" class="btn-direction">
      <i class="ic ic-arrow-up"></i>
    </button>
  </ng-template>
</ng-container>

<ng-template #contentTpl=""><ng-content></ng-content></ng-template>
