<div id="assignature" class="container">
  <div class="header flex justify-content-space-between">
    <div class="header-title">
      <img src="../../../../../../../../../assets/icons/sign.svg" alt="" />
      <h6 class="title-large" >
        {{'Drawyoursignature' | translate}}
      </h6>
    </div>
    <div (click)="drawClear()" class="delete-mobile">
      <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
      <span >{{'Deletesignature' | translate}}
</span>
    </div>
  </div>
  <div id="content-signature" class="content">
    <div class="signature-x">
      <mat-icon fontSet="material-icons-outlined">close</mat-icon>
    </div>
    <div class="signature-line">
      <hr />
    </div>
    <signature-pad
      #signature=""
      #canvas=""
      [options]="signaturePadOptions"
      (drawStart)="drawStart($event)"
      (drawEnd)="drawComplete($event)"
    ></signature-pad>
  </div>
  <div class="footer">
    <div class="flex justify-content-space-between">
      <div class="checkbox-wrapper"></div>
      <div (click)="drawClear()" class="delete">
        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
        <span >{{'Deletesignature' | translate}}
</span>
      </div>
    </div>
    <div class="action-buttons">
      <app-button-outline
        type="dark"
        size="lg"
        (click)="backEventEmitter.emit()"
        mat-button=""
      >
        {{'GoBack' | translate}}

      </app-button-outline>
      <app-button-default
        type="dark"
        size="lg"
        (click)="doSignDocument()"
        [disabled]="!data || loading"
      >
        {{'Sign Document' | translate}}
        <span *ngIf="loading"><mat-spinner class="spn"> </mat-spinner></span>
      </app-button-default>
    </div>
  </div>
</div>
