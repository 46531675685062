<div class="page" *ngIf="page && !loading">
  <ng-container *ngFor="let item of page.cp_question_in_page">
    <app-question
      *ngIf="questionsMap[item.cp_question.slug]"
      [class.hide]="hideMap[item.cp_question.slug]"
      [question]="questionsMap[item.cp_question.slug]"
      (changeAnswer)="onChangeAnswer($event)"
      [context]="{ claim_id: questionnaireAnswer!.claim_id }"
      [questionnaireAnswer]="questionnaireAnswer$"
    ></app-question>
  </ng-container>
  <!-- <app-question
          [class.hide]="hideMap[questionInPage.id]"
          [question]="questions[questionInPage.question_id]"
          (changeAnswer)="changeAnswer($event)"
        ></app-question> -->
</div>
<ng-container *ngIf="!(page && !loading)">
  <div class="loading-page">
    <mat-spinner diameter="40"></mat-spinner>
    <p i18n="@@Loading...">Loading...</p>
  </div>
</ng-container>
