<div class="container">
  <mat-icon [mat-dialog-close]="false" id="close">close</mat-icon>
  <div class="title">
    <span i18n="@@DocumentPreview">Document Preview</span>
  </div>
  <ng-container *ngIf="extension == 'pdf'">
    <div class="content">
      <pdf-viewer
        [src]="'assets/teste.pdf'"
        [original-size]="true"
        [render-text]="true"
        [rotation]="0"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="0.5"
        [zoom-scale]="'page-width'"
        [stick-to-page]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        style="margin-top: 10px"
        class="pdf-viewer"
      ></pdf-viewer>
    </div>
  </ng-container>
  <ng-container *ngIf="extension != 'pdf'">
    <div class="content">
      <div>
        <img [src]="URL" alt="" />
      </div>
    </div>
  </ng-container>
  <button [mat-dialog-close]="false" mat-button="">CLOSE WINDOW</button>
</div>
