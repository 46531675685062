<div class="container">
  <h5 class="title" >{{'ReadtheQRCode'| translate}}</h5>
  <p class="desc">
    {{
      "TocontinueenablingMulti-FactorAuthentication,openyourauthenticatorappandscantheQRcodedisplayedbelow."
        | translate
    }}
  </p>
  <ng-container *ngIf="targetId; else noTarget">
    <img *ngIf="qrcode" [src]="qrcode" alt="" />
    <p
      class="secret-explain"
      
    >
    {{'Ifyourauthenticatorappcan\'treadQRcode,usethesecretbelowtomanuallyregister.' | translate}}
      
    </p>
    <div class="secret">
      <p class="secret-text">
        {{ showSecret ? secret : "****************" }}
      </p>
      <div class="act-secret-btns">
        <button mat-icon-button="" (click)="copySecret()" *ngIf="showSecret">
          <mat-icon>content_copy</mat-icon>
        </button>
        <button mat-icon-button="" (click)="toggleSecret()">
          <mat-icon>{{
            showSecret ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #noTarget="">
    <div class="no-image">
      <!-- <p>Loading...</p> -->
      <mat-spinner diameter="60"></mat-spinner>
    </div>
  </ng-template>

  <button
    class="btn-close"
    [disabled]="!targetId"
    (click)="go$.emit({ targetId: targetId! })"
  >
    {{'Next' | translate}}
  </button>
</div>
