import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { InputTextComponent } from './input-text/input-text.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputPhoneCountryComponent } from './input-phone-country/input-phone-country.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../modules/material/material-module.module';
import { SimpleModule } from '../components/simple/simple.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SmartModule } from '../components/smart/smart.module';
import { MarkdownModule } from 'ngx-markdown';
import { QuillModule } from 'ngx-quill';
import { InputCodeChallengerComponent } from './input-code-challenger/input-code-challenger.component';
import { InputHtmlComponent } from './input-html/input-html.component';
import { InputUploadFileComponent } from './input-upload-file/input-upload-file.component';
import { InputTextSelectComponent } from './input-text-select/input-text-select.component';
import { TranslateModule } from '@ngx-translate/core';

const allComponents = [
  InputTextComponent,
  InputDateComponent,
  InputPhoneCountryComponent,
  InputCodeChallengerComponent,
  InputHtmlComponent,
  InputUploadFileComponent,
  InputTextSelectComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule.forRoot(),
    MaterialModule,
    PdfViewerModule,
    MarkdownModule,
    TranslateModule
  ],
  exports: [...allComponents],
  declarations: [...allComponents],
  providers: [],
  schemas: [],
})
export class FormV2ComponentsModule {}
