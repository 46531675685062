<!-- <ng-container *ngIf="loading">
  <mat-spinner [value]="'indeterminate'"> </mat-spinner>
</ng-container>

<ng-container *ngIf="!loading">
  {{ value }}
</ng-container> -->
<ng-container *ngIf="version === 1">
  <div class="big-number">
    <p class="big-number-value">
      <ng-container *ngIf="loading || !value">
        <mat-spinner [value]="'indeterminate'"> </mat-spinner>
      </ng-container>

      <ng-container *ngIf="!(loading || !value)">
        {{ value.value }}
      </ng-container>
    </p>
    <ng-container *ngIf="value && value.settings">
      <div class="big-number-desc" *ngIf="value.settings.content">
        <div
          class="big-number-desc-icon"
          [style.backgroundColor]="
            value.settings.content.color || 'var(--color-secondary-40)'
          "
        >
          <mat-icon inline="">{{ value.settings.content.icon }}</mat-icon>
        </div>
        <span class="big-number-desc-text">{{
          value.settings.content.title
        }}</span>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="version === 2 && values.length > 0">
  <div class="big-numbers-list">
    <div class="big-number" *ngFor="let item of values">
      <div class="big-number-content">
        <p class="big-number-value">
          <ng-container *ngIf="loading">
            <mat-spinner [value]="'indeterminate'"> </mat-spinner>
          </ng-container>

          <ng-container *ngIf="!loading">
            {{ item.value }}
          </ng-container>
        </p>
        <div class="big-number-desc" *ngIf="item.settings.content">
          <div
            class="big-number-desc-icon"
            [style.backgroundColor]="
              item.settings.content.color || 'var(--color-secondary-40)'
            "
          >
            <mat-icon inline="">{{ item.settings.content.icon }}</mat-icon>
          </div>
          <span class="big-number-desc-text">{{
            item.settings.content.title
          }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
