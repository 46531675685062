<div class="dialog">
  <div class="title-container">
    <mat-icon class="icon-title" *ngIf="data.titleIcon">
      {{ data.titleIcon }}
    </mat-icon>
    <h3 class="title">{{ data.title || "Are you sure?" }}</h3>
  </div>
  <div class="desc">
    {{ data.message || "Are you sure you want to do this?" }}
  </div>
  <div class="actions">
    <button class="def-button cancel-btn" (click)="onConfirm()">
      {{ data.confirmText || "Ok" }}
    </button>
  </div>
</div>
