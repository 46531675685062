<div class="email" *ngFor="let item of thread.cp_message">
  <p class="header-item date">
    Date:
    <span class="value">{{
      item.created_at | date : "dd/MM/yyyy HH:mm:ss"
    }}</span>
  </p>
  <p class="header-item subject">
    Subject:
    <span class="value"> {{ thread.subject }} </span>
  </p>
  <p class="header-item from">
    From:
    <span class="value"> {{ item.cp_user?.primary_email || "System" }} </span>
  </p>

  <div class="content">
    <markdown>
      {{ item.value }}
    </markdown>
  </div>
</div>
<div
  class="advanced-chat-input"
  *ngIf="sendField === 'advanced' && thread.open_for_replies"
>
  <app-input-html
    [(ngModel)]="message"
    label=""
    placeholder="Type a message"
    name="message"
    type="text"
  ></app-input-html>

  <div class="action-btns">
    <button class="action-btn close" (click)="closeThread()">
      {{"Close thread" | translate}}
    </button>
    <button
      class="action-btn send"
      [disabled]="loadingSendMessage"
      (click)="sendMessage()"
    >
      {{"Send message" | translate}}
    </button>
  </div>
</div>
