<a *ngIf="news && news.metadata?.link" [href]="news.metadata.link">
  <div class="news-card" *ngIf="news" [class.multi]="multiClaims" [class.highlight]="news.metadata.highlight_priority >= 10">
    <div class="news-img" routerLink="{{ news.metadata.link }}">
      <img *ngIf="news.metadata.picture" class="img-rectangle-lg" src="{{ news.metadata.picture }}" alt="" />
    </div>
    <div class="news-main">
      <div class="outstanding-message">
        <span class="circle"></span>
        <p class="label">{{ news.metadata.bullet }}</p>
      </div>

      <div class="news-title">
        <a target="_blank">
          {{ news.metadata.title }}
        </a>
      </div>
    </div>
  </div>
</a>
