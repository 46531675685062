<div class="block-rectangle">
  <div class="wrapper-img">
    <img
      [src]="claim.cp_case.configuration.images.high_res"
      alt="{{ claim.cp_case.summary }}"
    />
  </div>
  <div class="category">
    <span class="circle" *ngIf="claim.cp_case.case_type"></span
    >{{ claim.cp_case.case_type }}
  </div>

  <!-- <ng-container *ngIf="claim.notification">
    <div class="block-notification">
      <mat-icon>error_outline</mat-icon>
      <span class="sub-2 font-helvetica" id="title_image_1">{{
        claim.notification.content
      }}</span>
    </div>
  </ng-container> -->

  <h4>{{ claim.cp_case.summary }}</h4>
  <!-- <h5>Claim up to <strong>{{ 10000 | currency : "GBP" }}</strong></h5> -->
  <p class="text-container">
    {{ claim.cp_case.description_clients }}
  </p>

  <ng-container [ngSwitch]="claim.status">
    <a
      [routerLink]="['/client/claim/' + claim.id]"
      *ngSwitchCase="'pending'"
      role="button"
    >
      Complete your registration
      <mat-icon>arrow_right</mat-icon>
    </a>

    <a
      [routerLink]="['/client/claim/' + claim.id]"
      *ngSwitchCase="'active'"
      role="button"
    >
      View your claim
      <mat-icon>arrow_right</mat-icon>
    </a>

    <!-- <a
      [routerLink]="['/client/claim/' + claim.uuid]"
      *ngSwitchCase="'inactive'"
      role="button">
      Active your claim
      <mat-icon>arrow_right</mat-icon>
    </a> -->
  </ng-container>
</div>
