<mat-form-field appearance="fill">
  <mat-label>MM/YYYY</mat-label>
  <input
    matInput=""
    [matDatepicker]="picker"
    [formControl]="date"
    [min]="min"
    [max]="max"
    (change)="this.handleChangeOnType()"
  />
  <mat-datepicker-toggle matIconSuffix="" [for]="picker">
    <mat-icon matDatepickerToggleIcon="">keyboard_arrow_down</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker
    #picker=""
    startView="multi-year"
    panelClass="example-month-picker"
    (monthSelected)="setMonthAndYear($event, picker)"
  >
  </mat-datepicker>
  <mat-error i18n="@@Checkdateinformed">Check date informed</mat-error>
</mat-form-field>
