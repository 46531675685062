import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../modules/material/material-module.module';
import { SimpleModule } from '../simple/simple.module';
import { BigNumberComponent } from './big-number/big-number.component';
import { QuestionnaireAnswerComponent } from './questionnaire-answer/questionnaire-answer.component';
import { FilterTextComponent } from './table/components/filter-text/filter-text.component';
import { FilterComponent } from './table/components/filter/filter.component';
import { AccordeonHostDirective } from './table/directives/accordeon-host.directive';
import { TableComponent } from './table/table.component';
import { CustomTabComponent } from './custom-tab/custom-tab.component';
import { FilterBooleanComponent } from './table/components/filter-boolean/filter-boolean.component';
import { FilterDateComponent } from './table/components/filter-date/filter-date.component';
import { FilterNumberComponent } from './table/components/filter-number/filter-number.component';
import { FilterOptionsComponent } from './table/components/filter-options/filter-options.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { QuestionnaireAnswerPageComponent } from './questionnaire-answer/questionnaire-answer-page/questionnaire-answer-page.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    TableComponent,
    AccordeonHostDirective,
    BigNumberComponent,
    FilterComponent,
    FilterTextComponent,
    FilterBooleanComponent,
    FilterDateComponent,
    FilterNumberComponent,
    FilterOptionsComponent,
    QuestionnaireAnswerComponent,
    QuestionnaireAnswerPageComponent,
    CustomTabComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    RouterModule,
    SimpleModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
  ],
  exports: [
    TableComponent,

    BigNumberComponent,
    QuestionnaireAnswerComponent,
    CustomTabComponent,
  ],
})
export class SmartModule {}
