<div class="gap-container">
  <div class="cases">
    <mat-tab-group [selectedIndex]="selectedIndex">
      <mat-tab
        label="{{'Other cases'| translate}}"
        [routerLink]="['/client/cases']"
        [queryParams]="{ open: false }"
      >
        <app-avaiable-list-case
          *ngIf="cases"
          [cases]="cases"
        ></app-avaiable-list-case>
      </mat-tab>
      <mat-tab
        label="{{'Your claims'| translate}}"
        [routerLink]="['/client/cases']"
        [queryParams]="{ open: true }"
      >
        <app-your-list-claim></app-your-list-claim>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
