<div class="preferencies" *ngIf="u && !!u.attributes.comm_preferences">
  <h1 class="title" >{{'EmailPreferences' | translate}}</h1>
  <p class="subtitle" >
    {{'Manageyouremailpreferencesbelow.Selectthetypesofemailsyouwouldliketoreceivefromus:' | translate}}
  </p>

  <div class="group">
    <mat-checkbox disabled="true" class="checkbox" [(ngModel)]="importantNotifications" >{{'EssentialUpdates(Required)' | translate}}</mat-checkbox>
    <p class="desc" >
      {{'Beconnectedtotheplatformandstayinformedaboutthestatusofyourclaimsandimportantupdatesregardingyouraccount.' | translate}}
    </p>
  </div>

  <div class="group">
    <mat-checkbox class="checkbox" [(ngModel)]="u.attributes.comm_preferences.reminders" >{{'Reminders' | translate}}</mat-checkbox>
    <p class="desc" >
      {{'Receivereminderstocompleteanypendingprocessesoractions.' | translate}}
    </p>
  </div>

  <div class="group">
    <mat-checkbox class="checkbox" [(ngModel)]="u.attributes.comm_preferences.news_announcements">{{'NewsandAnnouncements' | translate}}
</mat-checkbox>
    <p class="desc" >
      {{'Stayupdatedwithourlatestnewsandannouncements.' | translate}}</p>
  </div>

  <button class="save-btn" [disabled]="!importantNotifications" (click)="save()">
    {{'Save' | translate}}
  </button>
</div>
