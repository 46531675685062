<div class="condition">
  <div class="conditions-header">
    <span class="title" i18n="@@Text"> Text </span>
    <div class="select" (click)="$event.stopPropagation()">
      <mat-select
        appearance="outline"
        placeholder="Operator"
        [(ngModel)]="operator"
        name="item"
        class="operator"
      >
        <mat-option *ngFor="let item of operators" [value]="item.value">
          <span [innerHTML]="item.label"></span>
        </mat-option>
      </mat-select>

      <!-- <mat-form-field appearance="outline">
          <mat-label>Cars</mat-label>
          <select matNativeControl [(ngModel)]="operator">
            <mat-option value="eq">is</mat-option>
            <mat-option value="neq">is not</mat-option>
            <mat-option value="contains">contains</mat-option>
            <mat-option value="ncontains">does not contain</mat-option>
            <mat-option value="startswith">starts with</mat-option>
            <mat-option value="endswith">ends with</mat-option>
            <mat-option value="empty">is empty</mat-option>
            <mat-option value="nempty">is not empty</mat-option>
          </select>
        </mat-form-field> -->
    </div>
  </div>
  <div class="conditions-clear" (click)="$event.stopPropagation()">
    <mat-icon
      (click)="clearFilter()"
      class="clear"
      svgIcon="custom_filter_clear"
      >clear</mat-icon
    >
  </div>
</div>

<div
  class="form-field"
  (click)="$event.stopPropagation()"
  *ngIf="operator === 'gt' || operator === 'bt'"
>
  <label for="" i18n="@@From(After)">From (After)</label>
  <div class="input-content">
    <input type="text" [(ngModel)]="from" [matDatepicker]="pickerFrom" />

    <mat-datepicker-toggle
      class="btn-date-toogle"
      matIconSuffix=""
      [for]="pickerFrom"
    ></mat-datepicker-toggle>
    <mat-datepicker #pickerFrom=""></mat-datepicker>
  </div>
</div>
<div
  class="form-field"
  (click)="$event.stopPropagation()"
  *ngIf="operator === 'lt' || operator === 'bt'"
>
  <label for="" i18n="@@To(Before)">To (Before)</label>

  <div class="input-content">
    <input type="text" [(ngModel)]="to" [matDatepicker]="pickerTo" />
    <mat-datepicker-toggle
      class="btn-date-toogle"
      matIconSuffix=""
      [for]="pickerTo"
    ></mat-datepicker-toggle>
    <mat-datepicker #pickerTo=""></mat-datepicker>
  </div>
</div>

<div class="apply" (click)="$event.stopPropagation()">
  <button class="apply-btn" (click)="addFilter()">Apply</button>
</div>
<div class="filters" (click)="$event.stopPropagation()">
  <div class="filter-item" *ngFor="let item of filters; let i = index">
    <span [innerHTML]="getOperatorLabel(item.operator)"></span>
    {{ getFormattedData(item.value) }}
    <mat-icon (click)="removeFilter(i)">close</mat-icon>
  </div>
</div>
