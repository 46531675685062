<div class="item">
  <button
    class="label"
    (click)="signatureDialog()"
    [disabled]="checkStatus() !== 'OPEN'"
  >
    <img class="icon" src="../../../../../../assets/icons/sign.png" alt="" />

    <span class="oa-title"
      >{{ action.action_name }}
      <img
        *ngIf="checkStatus() == 'OPEN'"
        src="../../../../../../../../assets/icons/Tag_to_do.svg"
        alt=""
      />
    </span>
  </button>

  <div
    *ngIf="checkStatus() !== 'OPEN' && checkStatus() !== 'DONE'"
    class="actions"
  >
    <mat-icon
      class="info"
      [matTooltip]="
        checkStatus() === 'PENDING'
          ? infoPendingMessage
          : checkStatus() === 'DONE'
          ? action.description!
          : ''
      "
      >info</mat-icon
    >
  </div>
  <div
    *ngIf="!checkDependsOn() && action.ac_status === 'SIGNED'"
    class="actions"
  >
    <mat-icon class="success">done</mat-icon>

    <button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="menu" xPosition="before">
      <button mat-menu-item="">
        <span >{{'DownloadDocument' | translate}}</span>
      </button>
      <button mat-menu-item="">
        <span >{{'RetractSignature' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
