<mat-form-field>
  <input
    matInput=""
    [ngxMatDatetimePicker]="picker"
    placeholder="{{'Choose a date' | translate}}"
    [(ngModel)]="date"
    required=""
    [min]="min"
    [max]="max"
    (change)="change()"
  />
  <mat-datepicker-toggle
    matSuffix=""
    [for]="$any(picker)"
  ></mat-datepicker-toggle>
  <ngx-mat-datetime-picker
    #picker=""
    [showSeconds]="false"
    [color]="'primary'"
    (closed)="change()"
  >
  </ngx-mat-datetime-picker>

  <mat-error i18n="@@Checkdateinformed">Check date informed</mat-error>
</mat-form-field>
