<div class="form-field">
  <label [for]="id">{{ label }}</label>
  <div class="input-content">
    <input
      [class.invalid]="!!error"
      [id]="id"
      [name]="id"
      [type]="!passwordInput ? type : visibleValue ? 'text' : 'password'"
      [placeholder]="placeholder ?? ''"
      [(ngModel)]="value"
      (ngModelChange)="emitChange($event)"
      [ngModelOptions]="{ standalone: true }"
      (focus)="emitFocus()"
      (focusout)="emitFocusOut()"
      [readOnly]="readonly"
    />
    <button
      *ngIf="passwordInput"
      mat-icon-button
      class="password-visibility"
      (click)="visibleValue = !visibleValue"
    >
      <mat-icon>{{ visibleValue ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  </div>
  <p class="hint-error" *ngIf="error">{{ error }}</p>
</div>
