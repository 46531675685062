<div class="row">
  <h4 i18n="@@Images">Images</h4>
</div>
<div class="row">
  <div class="col-2">
    <h6 i18n="@@Square">Square</h6>
    <img height="300px" src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" alt="" class="img-square" />
  </div>
  <div class="col-2">
    <h6 i18n="@@Circle">Circle</h6>
    <img height="300px" src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" alt="" class="img-circle" />
  </div>
  <div class="col-2">
    <h6 i18n="@@Rectanglesm">Rectangle sm</h6>
    <img height="300px" src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" alt="" class="img-rectangle-sm" />
  </div>
  <div class="col-2">
    <h6 i18n="@@Rectanglelg">Rectangle lg</h6>
    <img height="300px" src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Brumadinho%2C_Minas_Gerais_%2847021723582%29.jpg" alt="" class="img-rectangle-lg" />
  </div>
</div>
